import React from "react";
import { routesPath } from "../../constants/routesPath";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../assets/images/logo.png";
import facebook from "../../assets/images/facebook-icon.svg";
import twitter from "../../assets/images/twitter-icon.svg";
import linkedin from "../../assets/images/linkedin-icon.svg";
import { NavLink } from "react-router-dom";

const toHideFooter = [routesPath.dashboard];

export const Footer = () => {
  const location = useLocation();
  let find = toHideFooter.find((el) => {
    if (location.pathname.includes(el)) {
      return true;
    }
  });
  if (find) return null;

  return (
    <div className="botics-footer">
      <footer className="article-padd">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="footer-info-wrap">
                <NavLink className="footer-logo" to={routesPath.home}>
                  <img src={logo} />
                </NavLink>

                <p>
                  A platform that unlocks the power of connected products by
                  assigning unique digital IDs to everyday items, providing
                  unparalleled end-to-end transparency.
                </p>
                <div className="footer-social-links">
                  <a href="">
                    <img
                      src={facebook}
                      className="img-fluid"
                      alt="facebook icon"
                    />
                  </a>
                  <a href="">
                    <img
                      src={twitter}
                      className="img-fluid"
                      alt="twitter-icon"
                    />
                  </a>
                  <a href="">
                    <img
                      src={linkedin}
                      className="img-fluid"
                      alt="linkedin icon"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-links">
                <div className="footer-links-head">
                  <h4>Quick Links</h4>
                </div>
                <div className="footer-links-list">
                  <ul>
                    <li>
                      <NavLink to={routesPath.marketPlace}>Marketplace</NavLink>
                    </li>
                    <li>
                      <NavLink to={routesPath.ecommerce}>Ecommerce</NavLink>
                    </li>
                    {/* <li>
                      <a href="pricing.html">Pricing</a>
                    </li> */}
                    <li>
                      <NavLink to={routesPath.configure}>Configurator</NavLink>
                    </li>
                    <li>
                      <NavLink to={routesPath.aboutUs}>Company</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-links">
                <div className="footer-links-head">
                  <h4>Products</h4>
                </div>
                <div className="footer-links-list">
                  <ul>
                    <li>
                      <a href="">Industrial Furniture</a>
                    </li>
                    <li>
                      <a href="">Robotics</a>
                    </li>
                    <li>
                      <a href="">Palletizing</a>
                    </li>
                    <li>
                      <a href="">Material Processing</a>
                    </li>
                    <li>
                      <a href="">Machine Tending</a>
                    </li>
                    <li>
                      <a href="">Inspection & Testing</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-links">
                <div className="footer-links-head">
                  <h4>Company</h4>
                </div>
                <div className="footer-links-list">
                  <ul>
                    <li>
                      <NavLink to={routesPath.aboutUs}>About</NavLink>
                    </li>
                    {/* <li>
                      <NavLink to={}>Careers</NavLink>
                    </li> */}
                    <li>
                      <NavLink to={routesPath.contactUs}>Contact us</NavLink>
                    </li>
                    {/* <li>
                      <a href="pricing.html">Pricing</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <section className="copyright">
        <p>© Botics, Copyright 2021</p>
      </section>
    </div>
  );
};
