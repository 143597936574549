import { useEffect } from "react";
import { useDispatch } from "react-redux";

//use debounce to implment boucning on every stroke so that app should wait for sometime .
//setup for optimization

export const useDebounce = (handler, watchedValue, delay) => {
  let dispatch = useDispatch();
  useEffect(() => {
    if (watchedValue.trim("").length) {
      const timeoutHandler = setTimeout(() => {
        dispatch(handler(watchedValue));
      }, delay);
      return () => {
        clearTimeout(timeoutHandler); // clearing interval incase user press key before timout otherwise api will fetch data
      };
    }
  }, [watchedValue, delay, dispatch, handler]);
};
