// status on basis of quote transition
export default {
  NEW: { name: "NEW", label: "PENDING" },
  SENT_TO_CUSTOMER: { name: "SENT_TO_CUSTOMER", label: "RECIEVED" },
  PURCHASED: { name: "PURCHASED", label: "PURCHASED" },
  CLOSED: { name: "CLOSED", label: "CLOSED" },
  VENDOR_TO_CUSTOMER: {
    name: "VENDOR_TO_CUSTOMER",
    label: "RECIEVED",
  },
  VENDOR_APPROVED: { name: "VENDOR_APPROVED", label: "RECIEVED" },
  hideQuotes: ["CLOSED", "PURCHASED"],
};
