import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

const TextInput = (props) => {
  const { name, label, type, required, placeHolder, min } = props;
  const {
    register,
    formState: { errors, touchedFields },
    unregister,
  } = useFormContext();

  const originalValue = useWatch({ name: name });

  return (
    <div className="form-group" style={{ clear: "both" }}>
      <div className="input-group">
        <input
          type={type}
          value={originalValue}
          {...register(name)}
          className={
            errors[name]?.message
              ? " form-control border-danger"
              : "form-control"
          }
          placeholder={placeHolder}
          min={min}
        />
      </div>
      <small className="text-danger float-start mb-3" style={{ clear: "both" }}>
        {errors[name]?.message}
      </small>
    </div>
  );
};

export default TextInput;
