export const GET_CART_PRODUCTS = "GET_CART_PRODUCTS";
export const GET_CART_PRODUCTS_SUCCESS = "GET_CART_PRODUCTS_SUCCESS";
export const GET_CART_PRODUCTS_ERROR = "GET_CART_PRODUCTS_ERROR";

export const ADD_CART_PRODUCTS = "ADD_CART_PRODUCTS";
export const ADD_CART_PRODUCTS_SUCCESS = "ADD_CART_PRODUCTS_SUCCESS";
export const ADD_CART_PRODUCTS_ERROR = "ADD_CART_PRODUCTS_ERROR";

export const DELETE_CART_PRODUCTS = "DELETE_CART_PRODUCTS";
export const DELETE_CART_PRODUCTS_SUCCESS = "DELETE_CART_PRODUCTS_SUCCESS";
export const DELETE_CART_PRODUCTS_ERROR = "DELETE_CART_PRODUCTS_ERROR";

export const PATCH_CART_ITEM_QUANTITY = "PATCH_CART_ITEM_QUANTITY ";
export const PATCH_CART_ITEM_QUANTITY_SUCCESS =
  "PATCH_CART_ITEM_QUANTITY_SUCCESS ";
export const PATCH_CART_ITEM_QUANTITY_ERROR = "PATCH_CART_ITEM_QUANTITY_ERROR ";

export const EMPTY_CART = "EMPTY_CART";
export const EMPTY_CART_SUCCESS = "EMPTY_CART_SUCCESS";
export const EMPTY_CART_ERROR = "EMPTY_CART_ERROR";

export const QUANTITY_HANDLER = "QUANTITY_HANDLER";

export const RESET_CART = "RESET_CART";
