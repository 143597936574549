import * as actions from "./actionTypes";
const initialState = {
  response: null,
  error: null,
  loading: false,
  quotes: null,
  orders: null,
  isChat: false,
  cartToQuoteSuccess: false,
  QuoteById: null,
  orderById: null,
  chargePaymentResponse: null,
  chargePaymentError: null,
  chargePaymentLoading: false,
};

export const quotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CART_TO_QUOTE:
      return {
        ...state,
        response: null,
        error: null,
        loading: true,
        cartToQuoteSuccess: false,
      };
    case actions.CART_TO_QUOTE_SUCCESS:
      return {
        ...state,
        response: action.payload.data,
        error: null,
        loading: false,
        cartToQuoteSuccess: true,
      };
    case actions.CART_TO_QUOTE_ERROR:
      return {
        ...state,
        response: null,
        error: action.payload,
        loading: false,
        cartToQuoteSuccess: false,
      };

    case actions.GET_QUOTES:
      return {
        ...state,
        response: null,
        error: null,
        loading: true,
        quotes: null,
      };
    case actions.GET_QUOTES_SUCCESS:
      return {
        ...state,
        quotes: action.payload.data,
        response: action.payload.data,
        error: null,
        loading: false,
      };
    case actions.GET_QUOTES_ERROR:
      return {
        ...state,
        response: null,
        error: action.payload,
        loading: false,
      };
    case actions.QUOTE_STATUS:
      return {
        ...state,
        response: null,
        error: null,
        loading: false,
      };
    case actions.QUOTE_STATUS_SUCCESS: {
      let tempQuoteStatus = [...state.quotes];
      tempQuoteStatus[action.payload.id] = action.payload.response.data;
      return {
        ...state,
        response: action.payload.response.data,
        error: null,
        loading: false,
        quotes: tempQuoteStatus,
      };
    }
    case actions.QUOTE_STATUS_ERROR:
      return {
        ...state,
        response: null,
        error: action.payload,
        loading: false,
      };
    case actions.QUOTE_TO_ORDER:
      return {
        ...state,
        response: null,
        error: null,
        loading: false,
      };
    case actions.QUOTE_TO_ORDER_SUCCESS: {
      let tempQuoteStatus = [...state.quotes];
      tempQuoteStatus[action.payload.id] = action.payload.response.data.quote;
      return {
        ...state,
        response: action.payload.response.data,
        error: null,
        loading: false,
        quotes: tempQuoteStatus,
      };
    }
    case actions.QUOTE_TO_ORDER_ERROR:
      return {
        ...state,
        response: null,
        error: action.payload,
        loading: false,
      };
    case actions.GET_ORDERS:
      return {
        ...state,
        response: null,
        error: null,
        loading: true,
        orders: null,
      };
    case actions.GET_ORDERS_SUCCESS:
      return {
        ...state,
        response: null,
        error: null,
        orders: action.payload.data,
        loading: false,
      };
    case actions.GET_ORDERS_ERROR:
      return {
        ...state,
        response: null,
        error: action.payload,
        orders: null,
        loading: false,
      };
    case actions.CLOSE_QUOTE:
      return {
        ...state,
        response: null,
        error: null,
        loading: false,
      };
    case actions.CLOSE_QUOTE_SUCCESS: {
      let tempQuoteStatus = [...state.quotes];
      tempQuoteStatus[action.payload.id] = action.payload.response.data;

      return {
        ...state,
        response: tempQuoteStatus,
        error: null,
        loading: false,
        quotes: tempQuoteStatus,
      };
    }
    case actions.CLOSE_QUOTE_ERROR:
      return {
        ...state,
        response: null,
        error: action.payload,
        loading: false,
      };
    case actions.RESET_QUOTATIONS:
      return {
        ...state,
        response: null,
        error: null,
        orders: null,
        loading: false,
      };
    case actions.GET_QUOTE_BY_ID:
      return {
        ...state,
        QuoteById: null,
        error: null,
        loading: true,
      };
    case actions.GET_QUOTE_BY_ID_SUCCESS:
      return {
        ...state,
        QuoteById: action.payload.data,
        error: null,
        loading: false,
      };
    case actions.GET_QUOTE_BY_ID_ERROR:
      return {
        ...state,
        QuoteById: null,
        error: action.payload,
        loading: false,
      };
    case actions.GET_ORDER_BY_ID:
      return {
        ...state,
        orderById: null,
        QuoteById: null,
        error: null,
        loading: true,
      };
    case actions.GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        orderById: action.payload.data,
        QuoteById: null,
        error: null,
        loading: false,
      };
    case actions.GET_ORDER_BY_ID_ERROR:
      return {
        ...state,
        orderById: null,
        QuoteById: null,
        error: action.payload,
        loading: false,
      };
    case actions.CHARGE_PAYMENT:
      return {
        ...state,
        chargePaymentResponse: null,
        chargePaymentError: null,
        chargePaymentLoading: true,
      };
    case actions.CHARGE_PAYMENT_SUCCESS:
      return {
        ...state,
        chargePaymentResponse: action.payload.data,
        chargePaymentError: null,
        chargePaymentLoading: false,
      };
    case actions.CHARGE_PAYMENT_ERROR:
      return {
        ...state,
        chargePaymentResponse: null,
        chargePaymentError: action.payload,
        chargePaymentLoading: false,
      };
    case actions.RESET_CHARGE_PAYMENT:
      return {
        ...state,
        chargePaymentResponse: null,
        chargePaymentError: null,
        chargePaymentLoading: false,
      };
    case actions.DOWN_PAYMENT_CONFIRMED:
      let tempOrders = [...state.orders];
      tempOrders.map((order, id) => {
        if (order.id == action.payload) {
          order.paid_amount = parseFloat(
            order.pending_amount + order.paid_amount
          ).toFixed(2);
          order.pending_amount = 0;
        }
      });
      return {
        ...state,
        orders: tempOrders,
      };

    case actions.RESET_CART_TO_QUOTE:
      return {
        ...state,
        cartToQuoteSuccess: false,
      };

    default:
      return state;
  }
};
