import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./contactusValidationSchema";
import TextInput from "../form/textInput";
import TextArea from "../form/textArea";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as productActionsCreator from "../../redux/actionsCreator";
import { TailLoader } from "../ui/loader";

export const ContactForm = () => {
  const dispatch = useDispatch();
  const contactUsResponse = useSelector(
    (state) => state.apiReducer.contactUsResponse
  );
  const contactUsError = useSelector(
    (state) => state.apiReducer.contactUsError
  );
  const contactUsLoading = useSelector(
    (state) => state.apiReducer.contactUsLoading
  );

  const [initialValues] = useState(() => {
    return {};
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
    defaultValues: initialValues,
  });

  const onSubmit = (values) => {
    dispatch(productActionsCreator.contactUs(values));
  };

  useEffect(() => {
    if (contactUsError) {
      toast.error("Failed To Send Email!");
    } else if (contactUsResponse) {
      toast.success("Email Sent!");
    }
  }, [contactUsResponse, contactUsError]);

  useEffect(() => {
    return () => {
      dispatch(productActionsCreator.resetContactUs());
    };
  }, []);

  return (
    <div>
      <section className="contact-wrap-outer article-padd">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-head">
                <p className="sec-title">
                  Have an inquiry or some feedback for us?
                </p>
                <h2>Please fill in the form below</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="contact-form-wrap">
                <FormProvider {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)} className="row">
                    <div className="col-12">
                      <TextInput
                        name="name"
                        label="Name*"
                        type="text"
                        required={true}
                        placeHolder={"Name"}
                      />
                    </div>
                    <div className="col-12">
                      <TextInput
                        name="company"
                        type="text"
                        required={true}
                        placeHolder={"Company/Organization Name*"}
                      />
                    </div>
                    <div className="col-12">
                      <TextInput
                        name="email_address"
                        type="text"
                        required={true}
                        placeHolder={"Email Address*"}
                      />
                    </div>
                    <div className="col-12">
                      <TextInput
                        name="phone_number"
                        type="text"
                        required={true}
                        placeHolder={"Phone*"}
                      />
                    </div>
                    <div className="col-12">
                      <TextArea
                        name="message"
                        type="text"
                        rows="6"
                        required={true}
                        placeHolder={"Please enter the message here*"}
                      />
                    </div>
                    <div className="col-12 form-btn-wrap">
                      <button
                        disabled={contactUsLoading}
                        type="submit"
                        className="green-btn mt-3"
                        onClick={form.handleSubmit(onSubmit)}
                      >
                        {contactUsLoading && (
                          <TailLoader height="20" width="20" />
                        )}
                        Send
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
