import React from "react";
// import Sticky from "react-scroll";
import Scrollspy from "react-scrollspy";
import automationInner from "../../assets/images/automation-inner.png";
import managmentImage from "../../assets/images/management-img.svg";
import chip from "../../assets/images/chip.svg";
import tracking from "../../assets/images/tracking.svg";
import configuratorScreen from "../../assets/images/configurator-screen.png";
import quote from "../../assets/images/quote.svg";
import inventory from "../../assets/images/inventory.svg";
import augmented from "../../assets/images/augmented.svg";

export const Flexibility = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row mx-0">
          <div
            className="col-4 d-none d-md-block"
            style={{ position: "relative" }}
          >
            <div
              className="card ecom-menu-card"
              style={{ position: "sticky", top: "100px" }}
            >
              <Scrollspy
                items={["flexibility", "automation", "management", "analytics"]}
                currentClassName="spy-active"
                offset={100}
              >
                <li>
                  <a href="#flexibility">Flexibility and Compliance</a>
                </li>
                <li>
                  <a href="#automation">Automation</a>
                </li>
                <li>
                  <a href="#management">Management</a>
                </li>
                <li>
                  <a href="#analytics">Analytics & Insight</a>
                </li>
              </Scrollspy>
            </div>
          </div>

          <div className="col-12 col-md-8 mx-0">
            <div className="" id="flexibility">
              <div className="process-text-wrap txt first">
                <h1>Flexibility and Compliance</h1>
                <p>
                  Lorem ipsum develop fast, scalable web applications using
                  global platform for serverless and edge technologies.
                </p>
                <ul>
                  <li>Customer specific privacy for viewing products</li>
                  <li>Customer specific pricing</li>
                  <li>Invoicing with flexible payment terms for each order</li>
                  <li>
                    Flexible payment types using credit cards, paypal, and bank
                    connections
                  </li>
                  <li>
                    Green Blockchain for smart contracts and token used for
                    pricing agreements, and product privacy.
                  </li>
                </ul>
              </div>
            </div>
            <div id="automation">
              <div className="process-text-wrap txt">
                <div className="automation-inner-img">
                  <img src={automationInner} className="img-fluid" />
                  <h1>Automation</h1>
                </div>
                <div className="automation-inner-content">
                  <div className="automation-left-content">
                    <div className="inner-most-automation automation-txt">
                      <h2>Automatic Calculation</h2>
                      <p>
                        Automated freight and parcel shipping cost calculation
                        for any size of product. Less than 50,000lbs
                      </p>
                      <h2>Improved Experience</h2>
                      <p>
                        Automated Collecting and Remitting of Taxes with
                        embedded Avalara
                      </p>
                      <h2>Better Performance</h2>
                      <p>
                        Progressive Web Application to enable use on any device
                        without downloading an app.
                      </p>
                    </div>
                  </div>
                  <div className="automation-right-content">
                    <ul>
                      <li>Lead Generation</li>
                      <li>Automated marketing</li>
                      <li>3D Product Pages</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id="management">
              <div className="process-text-wrap txt">
                <h1>Management</h1>
                <div className="management-inner">
                  <p>
                    Quoting tools with enhanced review options and approvals for
                    custom orders. Orders are processed as a quote.
                  </p>
                  <p>Chat module for reviewing quotes with customers</p>
                </div>
                <img src={managmentImage} className="img-fluid" />
              </div>
            </div>
            <div id="analytics">
              <div className="process-text-wrap txt">
                <h1>Analytics / Insight</h1>
                <p>
                  Lorem ipsum develop fast, scalable web applications using
                  global platform for serverless and edge technologies.
                </p>
                <div className="analytics-des">
                  <div className="analytics-inr">
                    <img src={chip} className="img-fluid" />
                    <p>
                      AI engine to Autoconfigure Equipment using NLP text
                      extraction from an RFQ
                    </p>
                  </div>
                  <div className="analytics-inr">
                    <img src={tracking} className="img-fluid" />
                    <p>
                      AI engine to Autoconfigure Equipment using NLP text
                      extraction from an RFQ
                    </p>
                  </div>
                  <div className="analytics-inr">
                    <img src={inventory} className="img-fluid" />
                    <p>
                      AI engine to Autoconfigure Equipment using NLP text
                      extraction from an RFQ
                    </p>
                  </div>
                  <div className="analytics-inr">
                    <img src={quote} className="img-fluid" />
                    <p>
                      AI engine to Autoconfigure Equipment using NLP text
                      extraction from an RFQ
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="configutaor-sec mt-5 pt-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="process-text-wrap txt config">
                <h1>3D Configurator</h1>
                <p>
                  Lorem ipsum develop fast, scalable web applications using
                  global platform for serverless and edge technologies.
                </p>
                <ul>
                  <li>
                    Showcase how the Botics configurator can create any type of
                    configuration need for the selected product.
                  </li>
                  <li>
                    Build with existing assets - All 3D formats supported.
                  </li>
                  <li>Easily configure any type of equipment.</li>
                  <li>Automatic CAD File Format Conversion.</li>
                  <li>Use on any browser.</li>
                  <li>Compatible on any device.</li>
                  <li>Add motion and animations.</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="config-img">
                <img src={configuratorScreen} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="augmented">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="augmented-img">
                <img src={augmented} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="augmented-txt process-text-wrap txt">
                <h1>Augmented Reality</h1>
                <p>
                  Lorem ipsum develop fast, scalable web applications using
                  global platform for serverless and edge technologies.
                </p>
                <p>
                  1: Show someone casting a robot or machine In Front of them
                  using a cell phone.
                </p>
                <p>2: Connected Apps</p>
                <ul>
                  <li>Show how Botics can connect with thousands of apps.</li>
                  <li>
                    Maybe a spinning circle of apps and Botics in the middle.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
