import {
  Chat,
  MessageInput,
  MessageList,
  TypingIndicator,
} from "@pubnub/react-chat-components";
import { usePubNub } from "pubnub-react";
import RemoveIcon from "@material-ui/icons/Remove";
import React from "react";
import "./chat.scss";

export const PubnubChat = (props) => {
  //chat module component for using reuseable component created from pubnum provider

  /*
@return <Component/>

@params

string: channelname

  */
  const pubnub = usePubNub();
  React.useEffect(() => {
    pubnub.subscribe(props.currentChannel);
  }, [pubnub, props.currentChannel]);

  return (
    <div>
      {props.setOpenChat && (
        <div className={`app-simple light`}>
          <Chat theme="light" currentChannel={props.currentChannel}>
            <div className="chat">
              <div style={{ cursor: "pointer" }} className="chatinfo">
                <h6>Botics Chat</h6>
                <RemoveIcon
                  className=" fa fa-remove remove-icon"
                  onClick={() => props.setOpenChat(false)}
                />
              </div>
              <MessageList fetchMessages={10}>
                <TypingIndicator showAsMessage />
              </MessageList>
              <MessageInput typingIndicator sendButton="Send" />
            </div>
          </Chat>
        </div>
      )}
    </div>
  );
};
