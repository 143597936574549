//list of end points
export const apiUrls = {
  getModels: (queryString = "") => `/api/market/model/read?${queryString}`,

  getComponents: (queryString = "") =>
    `/api/market/component/read?${queryString}`,
  getApplication: () => `/api/market/application/`,
  getEquipment: () => `/api/market/equipment/`,
  getCategory: () => `/api/market/component-category/`,
  getCart: () => `/api/shop/cart/read/`,
  getSubCategory: (queryString = "") =>
    `/api/market/component-subcategory?${queryString}`,
  getProducts: () => `/products`,
  registerUser: () => `/api/register/`,
  loginUser: () => `/api/token/`,
  verifyUserToken: () => `/api/token/verify/`,
  resetPassword: () => `/auth/password-reset/`,
  resetPasswordConfirm: () => `/auth/password-reset/confirm/`,
  signOut: () => `customer/logout`,
  getProductDetail: (data) => `/api/market/product/search/${data}/`,
  addProductsToCart: () => `/api/shop/cart/write/`,
  editCartQuantity: (id) => `/api/shop/cart/write/${id}/`,
  emptyCart: () => `/api/shop/empty-cart/`,
  deleteCartProducts: (product) => `/api/shop/cart/write/${product}/`,
  addCartToQuote: () => `/api/shop/cart-to-quote/`,
  getQuotes: () => `/api/shop/quote/`,
  changeQuoteStatus: (id) => `/api/shop/quote/${id}/`,
  quoteToOrder: () => `/api/shop/quote-to-order/`,
  getOrders: () => `/api/shop/order/`,
  searchProducts: (productName) =>
    `/api/market/product/search/?search=${productName}`,
  getProfile: () => `/api/me/`,
  postConfiguratorBuilds: () => `/api/market/user-build/write/`,
  getConfiguratorBuilds: () => `/api/market/user-build/read/`,
  updateConfiguratorBuilds: (id) => `/api/market/user-build/write/${id}/`,
  deleteConfiguratorBuilds: (id) => `/api/market/user-build/write/${id}/`,
  signUpVendor: () => `/api/market/vendor/write/`,
  calculateShipmentAmount: () => `/api/shipment/calculate-shipping/`,
  createPaymentIntent: () => `/api/shop/create-payment-intent/`,
  closeQuote: (id) => `/api/shop/quote/${id}/closed/`,
  quoteById: (id) => `/api/shop/quote/${id}`,
  orderById: (id) => `/api/shop/order/${id}`,
  chargePayment: () => `/api/payment/charge-payment/`,
  contactUs: () => `/api/contact/contact-us/`,
  resendEmailVerification: () => `/api/resend_email_verification/`,
  emailVerify: (token) => `/api/email-verify/?token=${token}`,
  resendOTP: () => `/api/resend_otp/`,
  loginOTP: () => `api/otp_login/`,
};
