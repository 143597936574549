let tokenKey = "token";
let userEmailKey = "email";

// handling local stiorage to store user  information
const clearJWTToken = () => {
  localStorage.removeItem(tokenKey);
};

const setJWTToken = (token) => {
  localStorage.setItem(tokenKey, token);
};

const clearStorage = () => {
  localStorage.clear();
};

const getJWTToken = () => {
  return localStorage.getItem(tokenKey);
};

const getUserEmail = () => {
  return localStorage.getItem(userEmailKey);
};

export default {
  clearStorage,
  setJWTToken,
  clearJWTToken,
  getJWTToken,
  getUserEmail,
};
