import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Orders } from "../ordersContainer/OrdersContainer";
import { routesPath } from "../../constants/routesPath";
import { Quotation } from "../quotationContainer/QuotationContainer";
import { UserInfo } from "../../components/userInfo/userInfo";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { QuotesIcon } from "../../components/ui/quotesIcon";
import { OrdersIcon } from "../../components/ui/OrdersIcon";
import { SubscriptionIcon } from "../../components/ui/subscriptionIcon";
import { PaymentIcon } from "../../components/ui/paymentIcon";
import { useDispatch, useSelector } from "react-redux";
import { PaymentMethods } from "../../components/paymentMethods/paymentMethods";
import { Subscription } from "../../components/subscription/subscription";
import * as actionsCreator from "../../redux/actionsCreator";
import { QuoteDetail } from "../../components/quoteDetail/quoteDetail";
import { OrderDetail } from "components/orderDetail/orderDetail";
import { ProfileContainer } from "../profileContainer/ProfileContainer";
import PersonIcon from "@material-ui/icons/Person";

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export const DashboardContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const drawerOpen = useSelector((state) => state.apiReducer.drawerOpen);
  const { path, url } = useRouteMatch();
  const [activeListItem, setActiveListItem] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    history.location.pathname.includes("/userprofle") && setActiveListItem(0);
    history.location.pathname.includes("/quotations") && setActiveListItem(1);
    history.location.pathname.includes("/orders") && setActiveListItem(2);
    history.location.pathname.includes("/paymentMethods") &&
      setActiveListItem(3);
    history.location.pathname.includes("/subscription") && setActiveListItem(4);
  }, []);

  return (
    <div className={"dashboard-drawer d-flex"}>
      <Drawer
        className={classes.drawer}
        variant={window.innerWidth >= 576 ? "persistent" : "temporary"}
        anchor="left"
        onClose={() => dispatch(actionsCreator.openDashBoardDrawer())}
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Divider />
        <List>
          <ListItem
            onClick={() => {
              setActiveListItem(0);
              history.push(`${url}${routesPath.userProfile}`);
            }}
            selected={activeListItem === 0 ? true : false}
            className="inactive-lists-item"
            style={{ paddingLeft: "26px" }}
            button
          >
            <PersonIcon className="person-icon-profile" />
            <span className="p-3 pb-1 pt-1">Profile</span>
          </ListItem>
          <ListItem
            onClick={() => {
              setActiveListItem(1);
              history.push(`${url}${routesPath.quotes}`);
              window.innerWidth <= 500 &&
                dispatch(actionsCreator.openDashBoardDrawer());
            }}
            selected={activeListItem === 1 ? true : false}
            className="inactive-lists-item"
            style={{ paddingLeft: "26px" }}
            button
          >
            <QuotesIcon />
            <span className="p-3 pb-1 pt-1">Quotes</span>
          </ListItem>
          <ListItem
            selected={activeListItem === 2 ? true : false}
            onClick={() => {
              setActiveListItem(2);
              history.push(`${url}${routesPath.orders}`);
              window.innerWidth <= 500 &&
                dispatch(actionsCreator.openDashBoardDrawer());
            }}
            className="inactive-lists-item"
            style={{ paddingLeft: "26px" }}
            button
          >
            <OrdersIcon />
            <span className="p-3 pb-1 pt-1">Orders</span>
          </ListItem>
          <ListItem
            selected={activeListItem === 3 ? true : false}
            onClick={() => {
              setActiveListItem(3);
              history.push(`${url}${routesPath.paymentMethods}`);
              window.innerWidth <= 500 &&
                dispatch(actionsCreator.openDashBoardDrawer());
            }}
            className="inactive-lists-item"
            style={{ paddingLeft: "26px" }}
            button
          >
            <PaymentIcon />
            <span className="p-3 pb-1 pt-1">Payment Methods</span>
          </ListItem>
          <ListItem
            selected={activeListItem === 4 ? true : false}
            onClick={() => {
              setActiveListItem(4);
              history.push(`${url}${routesPath.subscription}`);
              window.innerWidth <= 500 &&
                dispatch(actionsCreator.openDashBoardDrawer());
            }}
            className="inactive-lists-item"
            style={{ paddingLeft: "26px" }}
            button
          >
            <SubscriptionIcon />
            <span className="p-3 pb-1 pt-1">Subsciption</span>
          </ListItem>
        </List>
      </Drawer>
      <main
        style={{ overflow: "scroll", paddingTop: "48px" }}
        className={
          window.innerWidth >= 576
            ? clsx(classes.content, {
                [classes.contentShift]: drawerOpen,
              })
            : ""
        }
      >
        <Switch>
          <Route
            path={`${path}${routesPath.userProfile}`}
            render={() => <ProfileContainer />}
          />
          <Route
            path={`${path}${routesPath.quotes}`}
            render={() => <Quotation />}
          />
          <Route
            path={`${path}${routesPath.quoteDetailPage(":id")}`}
            render={() => <QuoteDetail />}
          />
          <Route
            path={`${path}${routesPath.orderDetailPage(":id")}`}
            render={() => <OrderDetail />}
          />
          <Route
            path={`${path}${routesPath.orders}`}
            render={() => <Orders />}
          />
          <Route
            exact
            path={`${path}${routesPath.paymentMethods}`}
            render={() => <PaymentMethods />}
          />
          <Route
            exact
            path={`${path}${routesPath.subscription}`}
            render={() => <Subscription />}
          />
        </Switch>
      </main>
    </div>
  );
};
