import combinedReducers from "./reducers";
import { mainMiddleware } from "./middlewares"; // list of middlewaresd
import { composeWithDevTools } from "redux-devtools-extension"; //redux extension
import { createStore, compose, applyMiddleware } from "redux";

const devTools =
  process.env.DEBUG === "true"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    : null; // active extension
//and provide app data oin development mode
var enhancer;
if (process.env.DEBUG === "true") {
  enhancer = compose(
    applyMiddleware(mainMiddleware), // apply middle ware i.e redux thunk for asynchronous actions
    devTools
  );
} else {
  enhancer = composeWithDevTools(applyMiddleware(mainMiddleware));
}

export default createStore(combinedReducers, enhancer); //export store for reducer to pub lish states
