//paths for every screen for routing
export const routesPath = {
  marketPlace: "/marketplace",
  ecommerce: "/ecommerce",
  home: "/",
  aboutUs: "/aboutus",
  contactUs: "/contactus",
  login: "/login",
  emailVerification: "/emailVerification",
  verifyEmail: "/verifyEmail",
  reset: "/resetPassword",
  orders: "/orders",
  productPage: (id) => `/product/${id}`,
  register: "/register",
  search: "/search",
  configure: "/configure",
  quotes: "/quotations",
  userProfile: "/userprofile",
  checkoutShipping: `/checkoutshipping`,
  checkoutOrder: `/checkoutorder`,
  paymentMethods: `/paymentMethods`,
  subscription: `/subscription`,
  quoteDetailPage: (id) => `/quote/${id}`,
  orderDetailPage: (id) => `/order/${id}`,
  dashboard: `/dashboard`,
  notFound: `*`,
};
