import React, { useEffect } from "react";

export const ProductCanvas = (props) => {
  //main showcase in condifurator module

  /* 
number:id
string: part name

  */
  useEffect(() => {
    const message = {
      message_type: "Robot_Data",
      model_id: "52",
      component_ids: props.components,
      addedd_component_ids: props.components,
      removed_component_ids: props.removedComponent,
    };
    if (iFrame.current) iFrame.current.contentWindow.postMessage(message, "*"); //initializing iframe refrence
  }, [props.components]);

  const iFrame = React.useRef(); // creating iframe refrence

  const iframeLoaded = () => {
    window.addEventListener("message", eventListner);
  };

  const eventListner = (event) => {
    if (
      event.data.message_type &&
      event.data.message_type === "Playcanvas_Ready"
    ) {
      const message = {
        message_type: "Robot_Data",
        model_id: "52",
        component_ids: props.components,
        addedd_component_ids: props.components,
      };
      if (iFrame.current)
        iFrame.current.contentWindow.postMessage(message, "*");
    }
  };

  return (
    <iframe
      src="https://playcanv.as/e/p/0vfHKDOv/"
      style={{ width: "100%", minHeight: "668px", borderRadius: "10px" }}
      frameBorder="0"
      id="playcanvasIframe"
      onLoad={iframeLoaded}
      ref={iFrame}
      scrolling="no"
      allow="camera;microphone;gyroscope;accelerometer;"
      title="Botics"
    ></iframe>
  );
};
