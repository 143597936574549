import React from "react";
import socIcon from "../../assets/images/soc-icon.png";
import GDPRIcon from "../../assets/images/gdpr-icon.png";
import greenTick from "../../assets/images/green-tick.svg";

export const GlobalPlatformSection = () => {
  return (
    <div style={{ background: "#FFFFFF" }}>
      <section className="global-home-wrap article-padd">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="global-home-icons-wrap">
                <h2>A global platform that is secure by design</h2>
                <div className="global-home-icons">
                  <div className="global-home-icon-single">
                    <img
                      src={socIcon}
                      className="img-fluid"
                      alt="global platform soc icon"
                    />
                    <p>SOC 2 Type 2</p>
                  </div>
                  <div className="global-home-icon-single">
                    <img
                      src={GDPRIcon}
                      className="img-fluid"
                      alt="global platform gdpr icon"
                    />
                    <p>GDPR</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="global-home-text-wrap">
                <div className="global-home-text-single">
                  <div className="global-home-text-single-icon">
                    <img src={greenTick} alt="green tick mark" />
                  </div>
                  <div className="global-home-text-single-text">
                    <h3>Reduced attack surface</h3>
                    <p>
                      Across our global Edge, content deployed to the edge nodes
                      is fully prerendered and static, offering no active
                      processes or surface area for attack.
                    </p>
                  </div>
                </div>
                <div className="global-home-text-single">
                  <div className="global-home-text-single-icon">
                    <img src={greenTick} alt="green tick mark" />
                  </div>
                  <div className="global-home-text-single-text">
                    <h3>SOC 2 and PCI compliant</h3>
                    <p>
                      Botics undergoes a SOC 2 type 2 audit annually, performed
                      by an independent auditor. (Our enterprise customers can
                      request a full audit report.) Botics is PCI compliant for
                      all SAQ-A requirements to safely process credit card
                      transactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
