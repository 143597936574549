import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddressForm } from "../../components/billingAdressFrom/billingAdressForm";
import { CheckoutProducts } from "../../components/checkoutProducts/checkoutProducts";
import { billingAddressValidationSchema } from "../../validationSchemas/billingAddressValidationSchema";
import * as checkoutActionsCreator from "../../redux/checkout/actionsCreator";
import { decryptedPrice } from "../../utils/decode";
import "./CheckoutContainer.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { routesPath } from "../../constants/routesPath";
import Label from "reactstrap/lib/Label";
import { TailLoader } from "../../components/ui/loader";

export const CheckoutContainer = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const response = useSelector((state) => state.checkoutReducer.response);
  const shipmentError = useSelector((state) => state.checkoutReducer.error);
  const loading = useSelector((state) => state.checkoutReducer.loading);
  const [adressInfoSubmitted, setAddressInfoSubmitted] = useState(false);
  const [sameAddress, setSameAddress] = useState(false);
  const data = {
    billing_streetAddress: "",
    billing_postalCode: "",
    billing_country: "",
    billing_city: "",
    billing_region: "",
    shipping_streetAddress: "",
    shipping_postalCode: "",
    shipping_region: "",
    shipping_country: "",
    shipping_city: "",
  };

  useEffect(() => {
    if (response) {
      setAddressInfoSubmitted(true);
    }
  }, [response]);

  useEffect(() => {
    if (shipmentError && !adressInfoSubmitted) {
      toast.error("Failed to calculate shipment!");
    }
  }, [shipmentError]);

  const isValid =
    new Date(props.location.state.selectedQuote.expires_at).getTime() >
    new Date().getTime();

  const calculateShipmentAmount = (values, setTouched) => {
    let calculateShipmentData = {
      quote_id: props.location.state.selectedQuote.id,
      address: values.shipping_streetAddress,
      postal_code: values.shipping_postalCode,
      state: values.shipping_region,
      country_code: values.shipping_country,
      city: values.shipping_city,
    };
    dispatch(
      checkoutActionsCreator.calculateShipmentAmount(calculateShipmentData)
    );
    setTouched(false);
  };

  function round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  }

  const calculatePrice = () => {
    let sum = 0;
    props.location.state.selectedQuote.subquotes.map((subQuote, index) =>
      subQuote.quote_items.map(
        (item) => (sum += decryptedPrice(item.price) * parseInt(item.quantity))
      )
    );
    return sum;
  };

  const calculateDownpayment = () => {
    return (
      (calculatePrice() * props.location.state.selectedQuote.down_payment) / 100
    );
  };

  const calculateTotalPrice = () => {
    return calculateDownpayment() + response;
  };

  return (
    <>
      <main>
        <section className="checkout-page">
          <div className="container-fluid">
            <div className="row">
              <div>
                <CheckoutProducts
                  products={props.location.state.selectedQuote}
                  shipmentAmount={response}
                  loading={loading}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div className="px-5 py-3 contact-and-billing-section">
                  <Formik
                    enableReinitialize={true}
                    // validateOnChange={true}
                    initialValues={data}
                    validationSchema={billingAddressValidationSchema}
                    onSubmit={(values, setTouched, resetForm) => {
                      calculateShipmentAmount(values, setTouched);

                      resetForm();
                    }}
                  >
                    {(formikProps) => {
                      return (
                        <form
                          onChange={() => setAddressInfoSubmitted(false)}
                          onSubmit={(e) => {
                            formikProps.handleSubmit();
                            e.preventDefault();
                          }}
                          className="row"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="shipping-block col-lg-6">
                            <div
                              className="shipping-address"
                              style={{ paddingTop: "2px" }}
                            >
                              <h1>Billing address</h1>
                            </div>

                            <AddressForm
                              {...formikProps}
                              type="billing"
                              sameAddress={sameAddress}
                              shipmentAmount={response}
                            />
                          </div>
                          <div className="billing-block col-lg-6">
                            <div className="shipping-address mb-4 ">
                              <h1 className="d-inline">Shipping Address</h1>
                              <Label
                                check
                                className="d-inline"
                                style={{ float: "right" }}
                              >
                                <input
                                  value={sameAddress}
                                  type="checkbox"
                                  className="same-as-bill me-2"
                                  onChange={() => setSameAddress(!sameAddress)}
                                />
                                same as billing address
                              </Label>
                            </div>

                            <AddressForm
                              {...formikProps}
                              type="shipping"
                              sameAddress={sameAddress}
                              shipmentAmount={response}
                            />
                          </div>
                          <div className="row pb-5">
                            <div className="col-12 col-lg-6 checkout-links">
                              <div className="pt-1">
                                <button
                                  disabled={!formikProps.isValid || loading}
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {" "}
                                  {loading && (
                                    <div className="d-inline px-3">
                                      <TailLoader height={20} width={20} />
                                    </div>
                                  )}
                                  Calculate Shipping
                                </button>
                              </div>
                              <div>
                                {response && adressInfoSubmitted && (
                                  <div
                                    onClick={() =>
                                      history.push({
                                        pathname: routesPath.checkoutOrder,
                                        state: {
                                          quote: location.state.selectedQuote,
                                          shipmentAmount: response,
                                          netAmount: `$${calculatePrice()}`,
                                          downPayment: `$${round(
                                            calculateDownpayment()
                                          )}`,
                                          totalAmount: `$${round(
                                            calculateTotalPrice()
                                          )}`,
                                          adressDetails: formikProps.values,
                                        },
                                      })
                                    }
                                    aria-disabled={
                                      !formikProps.isValid || isValid
                                    }
                                    className="place-order"
                                    style={{
                                      paddingLeft: "183px",
                                      paddingTop: "2px",
                                    }}
                                  >
                                    <button>
                                      {isValid
                                        ? "Place Order"
                                        : "Quote Expired"}
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-lg-6 text-end pt-3 pt-lg-4">
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  paddingBottom: "4px",
                                  color: "#4f4e4e",
                                }}
                              >
                                Net Amount:&nbsp;
                                <span
                                  style={{
                                    fontSize: "16px",
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`$${calculatePrice()}`}
                                </span>
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  paddingBottom: "4px",
                                  color: "#4f4e4e",
                                }}
                              >
                                Down Payment:&nbsp;
                                <span
                                  style={{
                                    fontSize: "16px",
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`$${round(calculateDownpayment())}`}
                                </span>
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  paddingBottom: "4px",
                                  color: "#4f4e4e",
                                }}
                              >
                                Shipment Amount:&nbsp;
                                <span
                                  style={{
                                    fontSize: "16px",
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {response === null ? "-" : `$${response}`}
                                </span>
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  paddingBottom: "4px",
                                  color: "#4f4e4e",
                                }}
                              >
                                Total Amount:&nbsp;
                                <span
                                  style={{
                                    fontSize: "16px",
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {response === null
                                    ? "-"
                                    : `$${round(calculateTotalPrice())}`}
                                </span>
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
