import React from "react";
import cultureIcon from "../../assets/images/culture-icon.svg";
import distributionButton from "../../assets/images/distribution-icon.svg";
export const OurCulture = () => {
  return (
    <div>
      <section className="our-culture-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="our-culture-single-wrap left">
                <div className="our-culture-single">
                  <img src={cultureIcon} alt="our culture icon" />
                  <h3>Culture</h3>
                  <p>
                    If a company is as good as the team of people who work
                    there, then a team is as good as its culture. We aim to be
                    as intentional as we can in how we scale both our
                    organization and culture.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="our-culture-single-wrap right">
                <div className="our-culture-single">
                  <img src={distributionButton} alt="our distribution icon" />
                  <h3>Distribution</h3>
                  <p>
                    {" "}
                    Botics is a partially distributed company. Half of us work
                    remotely and the rest at our headquarters in San Francisco.
                    We’ve structured Botics to support this and we pride
                    ourselves on having a distributed culture.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
