import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import * as quoteActionsCreator from "../../redux/quotation/actionsCreator";
import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { TailLoader } from "../ui/loader";
import { toast } from "react-toastify";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      marginBottom: "3px",
      marginTop: "3px",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const DownPaymentModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const [clientSecret, setClientSecret] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const chargePaymentResponse = useSelector(
    (state) => state.quotationReducer.chargePaymentResponse
  );
  const chargePaymentError = useSelector(
    (state) => state.quotationReducer.chargePaymentError
  );
  const chargePaymentLoading = useSelector(
    (state) => state.quotationReducer.chargePaymentLoading
  );

  useEffect(() => {
    if (chargePaymentResponse?.client_secret) {
      setClientSecret(chargePaymentResponse.client_secret);
    }
  }, [chargePaymentResponse]);

  useEffect(() => {
    dispatch(quoteActionsCreator.chargePayment({ order_id: props.order.id }));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPaymentLoader(true);
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      setPaymentLoader(false);
      setPaymentSuccess(false);
      toast.error(result.error);
    } else {
      setPaymentLoader(false);
      dispatch(quoteActionsCreator.downPaymentConfirmed(props.order.id));
      setPaymentSuccess(true);

      toast.success("Amount paid successfully!");
      props.setPaymentOrderQuote(null);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.order}
        onClose={() => props.setPaymentOrderQuote(null)}
        aria-labelledby="responsive-dialog-title"
        className="charge-payment-modal"
      >
        <DialogTitle
          className="charge-payment-modal-title"
          id="responsive-dialog-title"
        >
          {"Down Payment"}
        </DialogTitle>
        <hr />
        <DialogContent className="charge-payment-modal-content">
          <DialogContentText className="charge-payment-modal-text pt-3">
            {chargePaymentLoading && (
              <div className="text-center pt-3">
                <TailLoader height={100} width={100} />
              </div>
            )}
            {chargePaymentResponse ? (
              <>
                <div className="row">
                  <div className="col-6">
                    <div className="charge-payment-modal-headings">
                      <div className="charge-payment-modal-amounts">
                        Total Amount
                      </div>
                      <div className="charge-payment-modal-amounts">
                        shippment Amount
                      </div>
                      <div className="charge-payment-modal-amounts">
                        Amount Paid
                      </div>
                      <div className="charge-payment-modal-amounts">
                        Amount Pending
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="charge-payment-modal-amounts">
                      ${props.order.total_amount}
                    </div>
                    <div className="charge-payment-modal-amounts">
                      ${props.order.shipment_amount}
                    </div>
                    <div className="charge-payment-modal-amounts">
                      ${props.order.paid_amount}
                    </div>
                    <div className="charge-payment-modal-amounts">
                      ${props.order.pending_amount}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {paymentLoader && (
                      <div className="text-center my-3 pt-5">
                        <TailLoader height={50} width={50} />
                      </div>
                    )}
                    {!paymentSuccess ? (
                      <form
                        onSubmit={handleSubmit}
                        className={paymentLoader ? "d-none" : " d-block mt-5"}
                      >
                        <div className="col d-flex flex-column ">
                          <label>
                            <div className="text-muted">
                              <h3 className="my-3">
                                <strong>CARD DETAILS</strong>
                              </h3>
                            </div>
                            <CardElement options={CARD_ELEMENT_OPTIONS} />
                          </label>
                          <button
                            type="submit"
                            className="btn btn-primary mt-3"
                            disabled={!stripe}
                            style={{ width: "100px" }}
                          >
                            Pay
                          </button>
                        </div>
                      </form>
                    ) : (
                      paymentSuccess &&
                      !paymentLoader && (
                        <div className="success-payment text-center mt-5 pt-5">
                          <h2 style={{ color: "#222F8E" }}>
                            Order Created Successfully!
                          </h2>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </>
            ) : (
              chargePaymentError && (
                <div className="text-center">
                  <h6>Failed to Initiate down payment</h6>
                </div>
              )
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div
            disabled={chargePaymentLoading}
            className="quotes-action d-inline"
            onClick={(e) => {
              // e.stopPropagation();
              // setPaymentOrderQuote(quote);
              // dispatch(
              //   quoteActionsCreator.chargePayment({ order_id: quote.id })
              // );
            }}
          ></div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
