import React from "react";
import secureSecImage from "../../assets/images/secure-sec-img.svg";
import whiteTickWithGreen from "../../assets/images/white-tick-with-green-bg.svg";

export const SecureCheckSection = () => {
  return (
    <div>
      <section className="secure-check-wrap dark-bg-color article-padd">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="secure-check-head">
                <p className="sec-title">SECURED AND TRUSTED</p>
                <h2>Checked. And double-checked.</h2>
              </div>
            </div>
          </div>
          <div className="secure-check-inner">
            <div className="row">
              <div className="col-lg-3">
                <div className="secure-check-img">
                  <img
                    src={secureSecImage}
                    className="img-fluid"
                    alt="security icon"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="secure-check-text-wrap">
                  <div className="secure-check-text-single">
                    <div className="secure-check-text-single-icon">
                      <img src={whiteTickWithGreen} alt="tick icon" />
                    </div>
                    <div className="secure-check-text-single-text">
                      <h3>Active DDoS mitigation</h3>
                      <p>
                        Botics monitors for traffic pattern anomalies and
                        spikes, and effectively controls for them as needed.
                      </p>
                    </div>
                  </div>
                  <div className="secure-check-text-single">
                    <div className="secure-check-text-single-icon">
                      <img src={whiteTickWithGreen} alt="tick icon" />
                    </div>
                    <div className="secure-check-text-single-text">
                      <h3>Encryption</h3>
                      <p>
                        All traffic over our networks is TLS encrypted and all
                        sensitive information like access tokens are encrypted
                        at rest.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="secure-check-text-wrap">
                  <div className="secure-check-text-single">
                    <div className="secure-check-text-single-icon">
                      <img src={whiteTickWithGreen} alt="tick icon" />
                    </div>
                    <div className="secure-check-text-single-text">
                      <h3>Datacenter security</h3>
                      <p>
                        Botics leverages globally-distributed data center
                        partners that comply with leading security policies and
                        frameworks.
                      </p>
                    </div>
                  </div>
                  <div className="secure-check-text-single">
                    <div className="secure-check-text-single-icon">
                      <img src={whiteTickWithGreen} alt="tick icon" />
                    </div>
                    <div className="secure-check-text-single-text">
                      <h3>Penetration testing</h3>
                      <p>
                        Botics regularly performs third-party penetration tests
                        and engages the wider security community. We do not
                        offer testing as a service for the infrastructure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
