import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as actionsCreator from "../../redux/actionsCreator";
import * as cartActionCreators from "../../redux/cart/actionsCreator";
import Storage from "../../services/storageService";
import logo from "../../assets/images/logo.png";
import logo2x from "../../assets/images/logo@2x.png";
import cartIcon from "../../assets/images/cart-icon-svg.svg";
import "./nav.css";
import togglerIcon from "../../assets/images/toggler.svg";
import profileImage from "../../assets/images/profile-btn-img.jpg";
import userLogo from "../../assets/images/userLogo.png";
import dottedMenuButton from "../../assets/images/dotted-menu-btn.svg";
// reactstrap components
import { Collapse, Navbar, NavItem, Nav, Row, Col } from "reactstrap";
import Cart from "../cart/cart";
import { routesPath } from "../../constants/routesPath";
import NavbarBrand from "reactstrap/lib/NavbarBrand";
import { MainButton } from "../ui/Button";
import storageService from "../../services/storageService";
import { Menu, MenuItem } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { NavLink } from "react-router-dom";

import * as authActionsCreator from "../../redux/actionsCreator";

const NavbarDefault = React.memo(() => {
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const cartItems = useSelector((state) => state.cartReducer.cartItems);
  const loginResponse = useSelector((state) => state.authReducer.loginResponse);
  const loginError = useSelector((state) => state.authReducer.loginResponse);
  const [collapseOpen, toggleCollapseOpen] = React.useState(false);
  const [cart, setCart] = useState(false);
  const [cartItemQuantity, setCartItemQuantity] = useState([]);
  const [menu, setMenu] = useState(null);
  const [menuMb, setMenuMb] = useState(null);
  const prevMenu = useRef(menu);
  const prevMenuMb = useRef(menuMb);
  const prevMenuRef = useRef(null);
  const prevMenuMbRef = useRef(null);

  useEffect(() => {
    if (prevMenu.current === true && menu === false) {
      prevMenuRef.current.focus();
    }
    prevMenuRef.current = menu;
  }, [menu]);

  useEffect(() => {
    if (prevMenuMb.current === true && menuMb === false) {
      prevMenuMbRef.current.focus();
    }

    prevMenuMbRef.current = menuMb;
  }, [menuMb]);

  const authHandler = () => {
    Storage.clearStorage();
    dispatch(authActionsCreator.signout());
    history.push("/login");
  };

  const resettingShop = () => {
    dispatch(actionsCreator.handleProductType("model"));
    dispatch(actionsCreator.getModelsFilterProducts());
  };

  const toggle = () => setCart(!cart);

  const cartQuantityHandler = (e, cartId, productId, index) => {
    if (e.keyCode === 13)
      dispatch(
        cartActionCreators.patchCartProduct({
          quantity: parseInt(e.target.value),
          user: parseInt(localStorage.getItem("userId")),
          id: cartId,
          product: productId,
        })
      );
    else {
      let tempCart = [...cartItemQuantity];
      tempCart[index] = e.target.value;
      setCartItemQuantity(tempCart);
    }
  };

  const handleMenu = (e) => {
    if (menu) {
      setMenu(false);
    } else {
      setMenu(e.currentTarget);
    }
  };

  const handleMenuMb = (e) => {
    if (menuMb) {
      setMenuMb(false);
    } else {
      setMenuMb(e.currentTarget);
    }
  };

  return (
    <>
      <div
        style={{ position: "fixed", width: "100%", zIndex: "999", top: "0" }}
        className={storageService.getJWTToken() ? "wrapper-dash" : ""}
      >
        <div className="row" style={{ zIndex: "999" }}>
          <div className="col-lg-12  mx-0">
            <Navbar className={"logedin-nav"} expand="lg">
              <div
                className={
                  storageService.getJWTToken() ? "container-fluid" : "container"
                }
              >
                <NavbarBrand
                  className={
                    window.location.pathname.includes(routesPath.dashboard)
                      ? "profile-brand"
                      : ""
                  }
                >
                  <img
                    onClick={() => history.push(routesPath.home)}
                    src={logo}
                    srcSet={`${logo2x} 2x`}
                    className="img-fluid"
                  />
                </NavbarBrand>
                <button
                  className={
                    storageService.getJWTToken() &&
                    window.location.pathname.includes(routesPath.dashboard)
                      ? "navbar-toggler d-block config-login-toggler"
                      : "navbar-toggler d-none"
                  }
                  onClick={() => {
                    dispatch(actionsCreator.openDashBoardDrawer());
                  }}
                >
                  <img src={togglerIcon} className="config-login-toggler" />
                </button>

                <button
                  className="navbar-toggler order-last"
                  onClick={() => toggleCollapseOpen(!collapseOpen)}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <Collapse isOpen={collapseOpen} navbar className="m-auto ">
                  <Row className="d-none">
                    <Col className="collapse-close" xs="6">
                      <button
                        className="navbar-toggler"
                        onClick={() => toggleCollapseOpen(!collapseOpen)}
                      ></button>
                    </Col>
                  </Row>

                  <Nav
                    className="ml-lg-auto m-auto"
                    navbar
                    onClick={() => toggleCollapseOpen(false)}
                  >
                    <NavItem>
                      <NavLink
                        exact
                        activeClassName="nav-link-icon-active"
                        className="nav-link-icon"
                        to={routesPath.home}
                      >
                        <span className="nav-link-inner--text">Home</span>
                      </NavLink>
                    </NavItem>
                    {storageService.getJWTToken() && (
                      <NavItem>
                        <NavLink
                          activeClassName="nav-link-icon-active"
                          className="nav-link-icon"
                          to={routesPath.dashboard + routesPath.userProfile}
                        >
                          <span className="nav-link-inner--text">
                            Dashboard
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem onClick={resettingShop}>
                      <NavLink
                        activeClassName="nav-link-icon-active"
                        className="nav-link-icon"
                        to={routesPath.marketPlace}
                      >
                        <span className="nav-link-inner--text">
                          Marketplace
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        exact
                        activeClassName="nav-link-icon-active"
                        className="nav-link-icon"
                        to={routesPath.ecommerce}
                      >
                        <span className="nav-link-inner--text">Ecommerce</span>
                      </NavLink>
                    </NavItem>
                    {storageService.getJWTToken() && (
                      <NavItem>
                        <NavLink
                          activeClassName="nav-link-icon-active"
                          className="nav-link-icon"
                          tag={Link}
                          to="/configure"
                        >
                          <span className="nav-link-inner--text">
                            Configurator
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        activeClassName="nav-link-icon-active"
                        className="nav-link-icon"
                        tag={Link}
                        to={routesPath.aboutUs}
                      >
                        <span className="nav-link-inner--text">Company</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        activeClassName="nav-link-icon-active"
                        className="nav-link-icon"
                        tag={Link}
                        to={routesPath.contactUs}
                      >
                        <span className="nav-link-inner--text">Contact Us</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        activeClassName="nav-link-icon-active"
                        className="nav-link-icon"
                        tag={Link}
                        to={routesPath.search}
                      >
                        <span className="nav-link-inner--text">Search</span>
                      </NavLink>
                    </NavItem>
                    <div>
                      {cart && (
                        <Cart
                          toggle={toggle}
                          cart={cart}
                          cartQuantityHandler={cartQuantityHandler}
                          cartItemQuantity={cartItemQuantity}
                        />
                      )}
                    </div>
                    {Storage.getJWTToken() && (
                      <>
                        {/* <NavItem>
                    <NavLink className="nav-link-icon" tag={Link} to="/search">
                      <i className="fa fa-search text-white"></i>
                      <span className="nav-link-inner--text">Search</span>
                    </NavLink>
                  </NavItem> */}
                      </>
                    )}
                  </Nav>
                  {!storageService.getJWTToken() && (
                    <div className="order-last">
                      <button className="main-header-btn" onClick={authHandler}>
                        <span className="get-started">Get Started</span>
                      </button>
                    </div>
                  )}
                </Collapse>
                {Storage.getJWTToken() && (
                  <div
                    className="cart-btn-wrap mobile d-none d-md-block"
                    onClick={() => setCart(!cart)}
                  >
                    <a className="cart-btn">
                      <img src={cartIcon} alt="cart icon" />
                      {cartItems?.length > 0 && (
                        <span className="cartItems-count">
                          {cartItems && cartItems.length}
                        </span>
                      )}
                    </a>
                  </div>
                )}
                {storageService.getJWTToken() && (
                  <div>
                    <div
                      className="profile-btn-wrap d-none d-md-block"
                      aria-controls="profile-menu"
                      aria-haspopup="true"
                      onClick={handleMenu}
                    >
                      <a className="profile-btn">
                        <img src={userLogo} alt="profile icon" />
                      </a>
                    </div>
                    <Menu
                      onClose={() => {
                        setMenuMb(null);
                        setMenu(null);
                      }}
                      className={
                        window.innerWidth <= 768
                          ? "profile-select-menu-mb"
                          : "profile-select-menu"
                      }
                      id={"profile-menu"}
                      keepMounted
                      open={Boolean(menu)}
                      anchorEl={menu}
                    >
                      <MenuItem
                        onClick={() => {
                          setMenu(null);
                          setMenuMb(null);
                          history.push(routesPath.userProfile);
                        }}
                      >
                        Profile
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          authHandler();
                          setMenu(null);
                          setMenuMb(null);
                        }}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                )}

                {storageService.getJWTToken() && (
                  <div
                    className="profile-cart-dropdown d-block d-md-none order-last"
                    onClick={(e) => handleMenuMb(e)}
                    aria-controls="profile-menu-mb"
                    aria-haspopup="true"
                  >
                    <a
                      className="toggle-profile-cart"
                      href="javascript:void(0);"
                    >
                      <img
                        src={dottedMenuButton}
                        alt="show cart and profile button"
                      />
                    </a>
                  </div>
                )}
                <Menu
                  className="profile-select-menu-mb-top"
                  id="profile-menu-mb"
                  keepMounted
                  open={Boolean(menuMb)}
                  anchorEl={menuMb}
                  onClick={() => {
                    // setMenuMb(null);
                    // setMenu(null);
                  }}
                  onClose={() => {
                    setMenuMb(null);
                    setMenu(null);
                  }}
                >
                  <MenuItem>
                    <div
                      className="cart-btn-wrap mobile mx-3 mr-0  d-md-none"
                      onClick={() => {
                        setCart(!cart);
                        setMenuMb(null);
                        setMenu(null);
                      }}
                    >
                      <a className="cart-btn">
                        <img src={cartIcon} alt="cart icon" />
                        {cartItems?.length && (
                          <span className="cartItems-count">
                            {cartItems && cartItems.length}
                          </span>
                        )}
                      </a>
                    </div>
                  </MenuItem>
                  <MenuItem
                    aria-controls={"profile-menu"}
                    aria-haspopup="true"
                    onClick={(e) => {
                      // setMenuMb(null);
                      handleMenu(e);
                    }}
                  >
                    <div className="profile-btn-wrap">
                      <a className="profile-btn">
                        <img src={userLogo} alt="profile icon" />
                      </a>
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
});

export default NavbarDefault;
