import React from "react";

export const Subscription = () => {
  return (
    <>
      <div className="dashboard-content-wrap">
        <div className="dashboard-head-wrap">
          <div className="dashboard-head-title">
            <h1>Subscriptions</h1>
          </div>
        </div>
        <div className="main-content-inner">
          <div className="subscription-plan">
            <div className="subscription-txt">
              <h1>Choose Your Plan</h1>
              <hr />
              <p>We have several plans for small and large scale businesses.</p>
            </div>
            <div className="plan-option">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="option1"
                />
                <label className="form-check-label" for="inlineRadio1">
                  <span className="month">Monthly</span>{" "}
                  <span className="price">$12</span>
                  <span className="plan-des">(One bill, every month)</span>
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="option2"
                  checked
                />
                <label className="form-check-label" for="inlineRadio2">
                  <span className="month">Yearly</span>{" "}
                  <span className="price">$120</span>
                  <span className="plan-des">(Save 17%)</span>
                </label>
              </div>
            </div>
            <div className="upgrade-btn">
              <a href="confirmation.html">Upgrade Today</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
