import { Input } from "@material-ui/core";
import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { TailLoader } from "./loader";
import * as actionsCreator from "../../redux/actionsCreator";

export const SaveBuildModal = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actionsCreator.saveBuildReset());
    };
  }, []);
  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader>Save build</ModalHeader>
        {props.loading ? (
          <div className="text-center my-3">
            <TailLoader height={100} width={100} />
          </div>
        ) : (
          <ModalBody>
            <div className="row">
              <div className="col">
                <Input
                  value={props.buildName}
                  placeholder="Build title"
                  onChange={(e) => props.setBuildName(e.target.value)}
                />
              </div>
            </div>
          </ModalBody>
        )}
        <button
          style={
            props.loading || !props.buildName.trim("").length
              ? { opacity: "0.7", fontSize: "15px", width: "100%" }
              : { opacity: "1", fontSize: "15px", width: "100%" }
          }
          className="btn-green-bg"
          disabled={props.loading || !props.buildName.trim("").length}
          onClick={props.saveBuild}
        >
          save
        </button>
      </Modal>
    </div>
  );
};
