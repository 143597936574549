import * as actions from "../actionTypes";
import * as cartActions from "../cart/actionTypes";
import API from "../../services/httpService";
import * as quoteActions from "../quotation/actionTypes";
import * as checkoutActions from "../checkout/actionTypes";
import { apiUrls } from "../../constants/apiUrls";
import { authHeader } from "../../constants/authenticationHeader";
import Storage from "../../services/storageService";

//redux thunk middleware

/*

@params: string:actiontype,
@return : action:{
  dispatching , payload
} 

*/
export const mainMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === actions.CATEGORIES) {
      try {
        const data = action.payload;
        data.path = apiUrls.getCategory();
        const response = await API.fetch(data);
        dispatch({
          type: actions.CATEGORIES_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.CATEGORIES_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.SUB_CATEGORIES) {
      try {
        const data = action.payload;
        const { queryString } = data;
        data.path = apiUrls.getSubCategory(queryString);
        const response = await API.fetch(data);
        dispatch({
          type: actions.SUB_CATEGORIES_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.SUB_CATEGORIES_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.PRODUCTS) {
      try {
        const data = action.payload;
        data.path = apiUrls.getProducts();
        const response = await API.fetch(data);
        dispatch({
          type: actions.PRODUCTS_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.PRODUCTS_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.MODELS_FILTERS) {
      try {
        const data = action.payload;
        const { queryString } = data;
        data.path = apiUrls.getModels(queryString);
        let response = await API.fetch(data);

        dispatch({
          type: actions.MODELS_FILTERS_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.MODELS_FILTERS_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.COMPONENTS_FILTERS) {
      try {
        const data = action.payload;
        const { queryString } = data;
        data.path = apiUrls.getComponents(queryString);
        let response = await API.fetch(data);

        dispatch({
          type: actions.COMPONENTS_FILTERS_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.COMPONENTS_FILTERS_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.REGISTER_USER) {
      try {
        let data = {};
        data.data = action.payload;
        data.path = apiUrls.registerUser();
        data.headers = {
          accept: "application/json",
          "Content-Type": "application/json",
        };
        const response = await API.post(data);
        dispatch({
          type: actions.REGISTER_USER_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.REGISTER_USER_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.LOGIN_USER) {
      try {
        let data = {};
        data.data = action.payload;
        data.path = apiUrls.loginUser();
        const response = await API.post(data);
        dispatch({
          type: actions.LOGIN_USER_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.LOGIN_USER_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.RESEND_OTP) {
      try {
        let data = {};
        data.data = action.payload;
        data.path = apiUrls.resendOTP();
        const response = await API.post(data);
        dispatch({
          type: actions.RESEND_OTP_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.RESEND_OTP_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.LOGIN_OTP) {
      try {
        let data = {};
        data.data = action.payload;
        data.path = apiUrls.loginOTP();
        const response = await API.post(data);
        Storage.setJWTToken(response.data?.token?.access);
        localStorage.setItem("userId", response.data?.user?.id);
        localStorage.setItem("email", response?.data?.user?.email);
        dispatch({
          type: actions.LOGIN_OTP_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.LOGIN_OTP_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.RESEND_EMAIL_VERIFICATION) {
      try {
        let data = {};
        data.data = action.payload;
        data.path = apiUrls.resendEmailVerification();
        const response = await API.post(data);
        dispatch({
          type: actions.RESEND_EMAIL_VERIFICATION_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.RESEND_EMAIL_VERIFICATION_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.EMAIL_VERIFY) {
      try {
        let data = {};
        data.path = apiUrls.emailVerify(action.payload);
        const response = await API.post(data);
        dispatch({
          type: actions.EMAIL_VERIFY_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.EMAIL_VERIFY_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.VERIFY_TOKEN) {
      try {
        let data = {};
        data.data = action.payload;
        data.path = apiUrls.verifyUserToken();
        const response = await API.post(data);
        if (response.message) {
          Storage.clearJWTToken();
        }
        dispatch({
          type: actions.VERIFY_TOKEN_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.VERIFY_TOKEN_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.FORGOT_PASSWORD) {
      try {
        let data = {};
        data.data = action.payload;
        data.path = apiUrls.resetPassword();
        const response = await API.post(data);
        dispatch({
          type: actions.FORGOT_PASSWORD_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.FORGOT_PASSWORD_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.RESET_PASSWORD) {
      try {
        let data = {};
        data.data = action.payload;
        data.path = apiUrls.resetPasswordConfirm();
        const response = await API.post(data);
        dispatch({
          type: actions.RESET_PASSWORD_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.RESET_PASSWORD_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.APPLICATIONS) {
      try {
        let data = { path: apiUrls.getApplication() };
        let response = await API.fetch(data);
        dispatch({
          type: actions.APPLICATIONS_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.APPLICATIONS_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.EQUIPMENTS) {
      try {
        let data = { path: apiUrls.getEquipment() };
        let response = await API.fetch(data);
        dispatch({
          type: actions.EQUIPMENTS_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.EQUIPMENTS_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.MODELS) {
      try {
        let data = { path: apiUrls.getModels() };
        let response = await API.fetch(data);
        dispatch({
          type: actions.MODELS_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.MODELS_ERROR,
          payload: error,
        });
      } finally {
        dispatch({
          type: actions.RESET_STATE,
          payload: null,
        });
      }

      return action;
    }
    if (action.type === actions.SELECTED_PRODUCT) {
      try {
        let data = {};
        data.data = action.payload;
        data.path = apiUrls.getProductDetail(data.data);
        const response = await API.fetch(data);
        dispatch({
          type: actions.SELECTED_PRODUCT_RESPONSE,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.SELECTED_PRODUCT_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === cartActions.GET_CART_PRODUCTS) {
      try {
        let data = {};
        data.data = action.payload;
        data.path = apiUrls.getCart();
        data.csrf = authHeader();

        const response = await API.fetch(data);
        dispatch({
          type: cartActions.GET_CART_PRODUCTS_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: cartActions.GET_CART_PRODUCTS_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === cartActions.ADD_CART_PRODUCTS) {
      try {
        let data = {};
        data.data = action.payload;
        data.path = apiUrls.addProductsToCart();

        data.csrf = authHeader();
        const response = await API.post(data);
        dispatch({
          type: cartActions.ADD_CART_PRODUCTS_SUCCESS,
          payload: { response: response, productId: data.data.product },
        });
      } catch (error) {
        dispatch({
          type: cartActions.ADD_CART_PRODUCTS_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === cartActions.PATCH_CART_ITEM_QUANTITY) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.editCartQuantity(data.data.id);
        const response = await API.patch(data);
        dispatch({
          type: cartActions.PATCH_CART_ITEM_QUANTITY_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: cartActions.PATCH_CART_ITEM_QUANTITY_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === cartActions.EMPTY_CART) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.emptyCart();
        const response = await API.post(data);
        dispatch({
          type: cartActions.EMPTY_CART_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: cartActions.EMPTY_CART_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === cartActions.DELETE_CART_PRODUCTS) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.deleteCartProducts(data.data);
        const response = await API.dell(data);
        dispatch({
          type: cartActions.DELETE_CART_PRODUCTS_SUCCESS,
          payload: data.data,
          response: response,
        });
      } catch (error) {
        dispatch({
          type: cartActions.DELETE_CART_PRODUCTS_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === quoteActions.CART_TO_QUOTE) {
      try {
        let data = {};
        // data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.addCartToQuote();
        const response = await API.post(data);
        dispatch({
          type: quoteActions.CART_TO_QUOTE_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: quoteActions.CART_TO_QUOTE_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === quoteActions.GET_QUOTES) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.getQuotes();
        const response = await API.fetch(data);
        dispatch({
          type: quoteActions.GET_QUOTES_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: quoteActions.GET_QUOTES_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === quoteActions.QUOTE_STATUS) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.changeQuoteStatus(data.data.id);
        const response = await API.patch(data);
        dispatch({
          type: quoteActions.QUOTE_STATUS_SUCCESS,
          payload: { id: data.data.tableId, response: response },
        });
      } catch (error) {
        dispatch({
          type: quoteActions.QUOTE_STATUS_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === quoteActions.QUOTE_TO_ORDER) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.quoteToOrder();
        const response = await API.post(data);
        dispatch({
          type: quoteActions.QUOTE_TO_ORDER_SUCCESS,
          payload: { id: data.data.tableId, response: response },
        });
      } catch (error) {
        dispatch({
          type: quoteActions.QUOTE_TO_ORDER_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === quoteActions.GET_ORDERS) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.getOrders();
        const response = await API.fetch(data);
        dispatch({
          type: quoteActions.GET_ORDERS_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: quoteActions.GET_ORDERS_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === actions.SEARCH_PRODUCTS) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.searchProducts(data.data);
        const response = await API.fetch(data);
        dispatch({
          type: actions.SEARCH_PRODUCTS_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.SEARCH_PRODUCTS_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === actions.ME) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.getProfile();
        const response = await API.fetch(data);
        dispatch({
          type: actions.ME_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.ME_ERROR,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === actions.POST_USER_BUILD) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.postConfiguratorBuilds();
        const response = await API.post(data);
        dispatch({
          type: actions.POST_USER_BUILD_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.POST_USER_BUILD_FAIL,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === actions.GET_USER_BUILD) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.getConfiguratorBuilds();
        const response = await API.fetch(data);
        dispatch({
          type: actions.GET_USER_BUILD_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.GET_USER_BUILD_FAIL,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === actions.UPDATE_USER_BUILD) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.updateConfiguratorBuilds(data.data.id);
        const response = await API.patch(data);
        dispatch({
          type: actions.UPDATE_USER_BUILD_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.UPDATE_USER_BUILD_FAIL,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === actions.DELETE_USER_BUILD) {
      try {
        let data = {};
        data.csrf = authHeader();
        data.path = apiUrls.deleteConfiguratorBuilds(action.payload);
        const response = await API.dell(data);
        dispatch({
          type: actions.DELETE_USER_BUILD_SUCCESS,
          payload: { response: response, id: action.payload },
        });
      } catch (error) {
        dispatch({
          type: actions.DELETE_USER_BUILD_FAIL,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === actions.VENDOR_SIGNUP) {
      try {
        let data = {};
        data.data = action.payload;
        // data.csrf = authHeader();
        data.headers = {
          accept: "application/json",
          "Content-Type": "application/json",
        };
        data.path = apiUrls.signUpVendor();
        const response = await API.post(data);
        dispatch({
          type: actions.VENDOR_SIGNUP_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.VENDOR_SIGNUP_FAIL,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === checkoutActions.SHIPMENT_AMOUNT) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.calculateShipmentAmount();
        const response = await API.post(data);
        dispatch({
          type: checkoutActions.SHIPMENT_AMOUNT_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: checkoutActions.SHIPMENT_AMOUNT_FAIL,
          payload: error,
        });
      }
      return action;
    }
    if (action.type === checkoutActions.CREATE_PAYMENT_INTENT) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.createPaymentIntent();
        const response = await API.post(data);
        dispatch({
          type: checkoutActions.CREATE_PAYMENT_INTENT_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: checkoutActions.CREATE_PAYMENT_INTENT_FAIL,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === quoteActions.CLOSE_QUOTE) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.closeQuote(data.data.id);
        const response = await API.post(data);
        dispatch({
          type: quoteActions.CLOSE_QUOTE_SUCCESS,
          payload: { id: data.data.tableId, response: response },
        });
      } catch (error) {
        dispatch({
          type: quoteActions.CLOSE_QUOTE_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === quoteActions.GET_QUOTE_BY_ID) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.quoteById(data.data);
        const response = await API.fetch(data);
        dispatch({
          type: quoteActions.GET_QUOTE_BY_ID_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: quoteActions.GET_QUOTE_BY_ID_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === quoteActions.GET_ORDER_BY_ID) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.orderById(data.data);
        const response = await API.fetch(data);
        dispatch({
          type: quoteActions.GET_ORDER_BY_ID_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: quoteActions.GET_ORDER_BY_ID_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.USER_PROFILE) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.getProfile();
        const response = await API.fetch(data);
        dispatch({
          type: actions.USER_PROFILE_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.USER_PROFILE_FAIL,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === quoteActions.CHARGE_PAYMENT) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.chargePayment();
        const response = await API.post(data);
        dispatch({
          type: quoteActions.CHARGE_PAYMENT_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: quoteActions.CHARGE_PAYMENT_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.CONTACT_US) {
      try {
        let data = {};
        data.data = action.payload;
        data.csrf = authHeader();
        data.path = apiUrls.contactUs();
        const response = await API.post(data);
        dispatch({
          type: actions.CONTACT_US_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.CONTACT_US_ERROR,
          payload: error,
        });
      }
      return action;
    }

    if (action.type === actions.CHECK_STATUS) {
      try {
        let data = {};
        data.path = apiUrls.getApplication();
        const response = await API.fetch(data);
        dispatch({
          type: actions.CHECK_STATUS_SUCCESS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: actions.CHECK_STATUS_ERROR,
          payload: error,
        });
      }
      return action;
    }
  };
