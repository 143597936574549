import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import * as actionsCreator from "../../redux/actionsCreator";
import "./sideDrawer.css";
import { TailLoader } from "./loader";
import buildIcon from "../../assets/images/builds-icon.svg";
import editIcon from "../../assets/images/edit-icon.svg";
import deleteIcon from "../../assets/images/delete-icon.svg";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export const SideDrawer = (props) => {
  const { loading } = props;
  let dispatch = useDispatch();
  const classes = useStyles();

  const builds = useSelector((state) => state.apiReducer.loadedResponse);

  useEffect(() => {
    dispatch(actionsCreator.getConfiguratorBuilds());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Drawer
        className={`${classes.drawer} configurator-drawer`}
        anchor="left"
        open={props.openDrawer}
        onClose={() => props.setOpenDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <h6 className="build-title">Builds</h6>
        <i
          onClick={() => props.setOpenDrawer(false)}
          className="fa fa-times build-head-remove"
        ></i>

        <Divider />
        {loading ? (
          <div className="mt-5 pt-5 text-center">
            <TailLoader height={100} width={100} />
          </div>
        ) : (
          <List>
            {builds?.map((build, index) => (
              <ListItem
                button
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  props.loadBuild(build);
                }}
              >
                <ListItemIcon>
                  <img src={buildIcon} alt="build icon" />
                </ListItemIcon>

                <ListItemText
                  primary={build?.title}
                  className="config-drawer-text"
                />
                <img src={editIcon} alt="edit build icon" className="p-2" />
                <img
                  src={deleteIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.deleteBuild(build.id);
                  }}
                  alt="delete build icon"
                  className="p-2"
                />
              </ListItem>
            ))}
          </List>
        )}
        {props.loadedResponseError && (
          <div className="text-center mt-5 pt-5">
            <h5>Failed To Get Builds</h5>
          </div>
        )}
      </Drawer>
    </div>
  );
};
