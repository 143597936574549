import { combineReducers } from "redux";

import { apiReducer } from "./apiReducer";
import { authReducer } from "./authReducer";
import { cartReducer } from "../cart/reducer";
import { quotationReducer } from "../quotation/reducer";
import { checkoutReducer } from "../checkout/reducer";

export default combineReducers({
  apiReducer,
  authReducer,
  cartReducer,
  quotationReducer,
  checkoutReducer,
});
