import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../constants/baseUrl";
import Storage from "../services/storageService";
import store from "../redux/store";

const API = axios.create({
  baseURL: baseUrl,
});

//Creating axios instance for api calls

API.interceptors.response.use(
  // setup axios interceptor for handling generic error resoponses
  (res) => {
    return res;
  },
  (error) => {
    const expectedError =
      error.response?.status >= 400 && error.response?.status < 500;

    if (!expectedError) {
      if (error.response?.status === 502) {
        Storage.clearJWTToken();
        window.location = "/not-found";
      }
      toast.error("An Unexpected Error Occurrred!");
    }

    if (error.response?.status === 401) {
      Storage.clearJWTToken();
      window.location = "/login";
    }

    return Promise.reject(error);
  }
);

const fetch = async ({ path, csrf }) => {
  try {
    let headers = {};
    if (csrf) headers = { ...csrf };
    const res = await API.get(path, { headers });
    return res;
  } catch (ex) {
    throw ex;
  }
};

const post = async ({ path, data, csrf }) => {
  try {
    let headers = {};
    if (csrf) headers = { ...csrf };
    return await API.post(path, data, { headers });
  } catch (ex) {
    throw ex;
  }
};

const put = async ({ path, data, csrf }) => {
  try {
    let headers = {};
    if (csrf) headers = { ...csrf };
    return await API.put(path, data, { headers });
  } catch (ex) {
    throw ex;
  }
};

const dell = async ({ path, csrf }) => {
  try {
    let headers = {};
    if (csrf) headers = { ...csrf };
    return await API.delete(path, { headers });
  } catch (ex) {
    throw ex;
  }
};

const patch = async ({ path, data, csrf }) => {
  let headers = {};
  if (csrf) headers = { ...csrf };
  return await API.patch(path, data, { headers });
};

export default {
  post,
  dell,
  fetch,
  patch,
  put,
};
