import React from "react";
import visaCard from "../../assets/images/visa.svg";
import dropdownCard from "../../assets/images/drop-down_cards.svg";
import masterCard from "../../assets/images/master-card.svg";
import americanExpress from "../../assets/images/american-express.svg";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownItem from "reactstrap/lib/DropdownItem";

export const PaymentMethods = () => {
  return (
    <>
      <div className="dashboard-content-wrap">
        <div className="dashboard-head-wrap">
          <div className="dashboard-head-title">
            <h1>Payment Methods</h1>
          </div>
        </div>
        <div className="main-content-inner">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="card-bdy opt">
                <div className="visa">
                  <img src={visaCard} className="img-fluid" />

                  <div className="card-btn-wrap">
                    <a className="card-btn" href="javascript:void(0);">
                      <img src={dropdownCard} alt="icon" />
                    </a>
                  </div>
                </div>
                <hr />
                <div className="tag">
                  <p>DEAFAULT</p>
                </div>
                <div className="card-info">
                  <p>
                    <span>.... .... ....</span> 3269
                  </p>
                  <p className="date">4/2/2025</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card-bdy opt">
                <div className="visa">
                  <img src={masterCard} className="img-fluid" />

                  <div className="card-btn-wrap">
                    <a className="master" href="javascript:void(0);">
                      <img src={dropdownCard} alt="icon" />
                    </a>
                  </div>
                </div>

                <hr />
                <div className="card-info payment_methods">
                  <p>
                    <span>.... .... ....</span> 3269
                  </p>
                  <p className="date">4/2/2025</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card-bdy opt">
                <div className="visa">
                  <img src={americanExpress} className="img-fluid" />
                  <UncontrolledDropdown>
                    <DropdownToggle className="card-toggle-menu-btn">
                      <div className="card-btn-wrap">
                        <a className="express" href="javascript:void(0);">
                          <img src={dropdownCard} alt="icon" />
                        </a>
                      </div>
                    </DropdownToggle>

                    <DropdownMenu className="card-toggle-menu">
                      <DropdownItem>
                        <div className="card-dropdown-wrap">
                          <div className="card-dropdown">
                            <a href="">Delete</a>
                          </div>
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <hr />
                <div className="card-info payment_methods">
                  <p>
                    <span>.... .... ....</span> 3269
                  </p>
                  <p className="date">4/2/2025</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
