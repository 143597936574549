import React from "react";
import { AutomatedProcessing } from "../../components/automatedProcessing/automatedProcessing";
import { CapabilitiesSection } from "../../components/capabilitiesSection/capabilitiesSection";
import { ConfiguratorSection } from "../../components/configuratorSection/ConfiguratorSection";
import { DataAnalyticsSection } from "../../components/dataAnalyticsSecton/dataAnalyticsSection";
import { ExploreMoreSection } from "../../components/exploreMoreSection/exploreMoreSection";
import { MarketPlaceSection } from "../../components/marketPlaceSection/marketPlaceSection";
import { GlobalPlatformSection } from "../../components/globalPlatformSection/globalPlatformSection";
import { SasPlatForm } from "../../components/sasPlatform/sasPlatForm";
import { SecureCheckSection } from "../../components/secureCheckSection/secureCheckSection";

export const HomeContainer = () => {
  return (
    <div>
      <SasPlatForm />
      <MarketPlaceSection />
      <ConfiguratorSection />
      <CapabilitiesSection />
      <AutomatedProcessing />
      <DataAnalyticsSection />
      <ExploreMoreSection />
      <GlobalPlatformSection />
      <SecureCheckSection />
    </div>
  );
};
