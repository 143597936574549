import React from "react";

export const AboutCta = () => {
  return (
    <div>
      <section className="cta-wrap-outer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cta-wrap-inner">
                <h3>Ready to take your Business to the next level?</h3>
                <p>
                  Reduce design time and eliminate production errors with
                  Botics.
                </p>
                <div className="cta-btn">
                  <a href="login.html" className="green-btn">
                    Get Started for free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
