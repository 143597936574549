import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import { asset } from "../../constants/baseUrl";
import noImg from "../../assets/img/noImg.jpg";
import { routesPath } from "../../constants/routesPath";
import "../cart/cart.css";

export const DetailModal = (props) => {
  const history = useHistory();
  return (
    <div>
      <Modal
        isOpen={props.quoteDetails}
        toggle={props.toggle}
        centered
        scrollable
        className="cart-modal"
      >
        <div>
          <div>
            <div>
              <ModalHeader>
                <div className="border-bottom-0">
                  <h5>Your Quote</h5>
                </div>
              </ModalHeader>

              <div>
                <ModalBody>
                  <div style={{ height: "350px", overflowY: "scroll" }}>
                    <table className="table table-image">
                      <thead>
                        <tr>
                          <th scope="col">Image</th>
                          <th scope="col">Name</th>
                          <th scope="col">Type</th>
                          <th scope="col">Qty</th>
                          <th scope="col">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.selectedQuote?.subquotes.length
                          ? props.selectedQuote.subquotes.map(
                              (subQuote, index) =>
                                subQuote.quote_items.map((product, index) => (
                                  // <div>
                                  <tr key={index}>
                                    <td style={{ width: "25%" }}>
                                      <img
                                        src={
                                          (product.product.data?.images
                                            .length &&
                                            asset(
                                              product.product.data.images[0]
                                                .image
                                            )) ||
                                          noImg
                                        }
                                        className="img-fluid img-thumbnail"
                                        alt="Product"
                                      />
                                    </td>
                                    <td>{product.product.data.name}</td>
                                    <td>{product.product.data.type}</td>
                                    <td>{product.quantity}</td>
                                    <td>{"$" + product.price}</td>
                                  </tr>
                                ))
                            )
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </ModalBody>
                {!props.from &&
                  (props.selectedQuote.status === "SENT_TO_CUSTOMER" ||
                    props.selectedQuote.status === "VENDOR_TO_CUSTOMER") && (
                    <ModalFooter>
                      {(props.selectedQuote.status.includes(
                        "SENT_TO_CUSTOMER"
                      ) ||
                        props.selectedQuote.status.includes(
                          "VENDOR_TO_CUSTOMER"
                        )) && (
                        <div className="border-top-0 d-flex justify-content-between">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={(e) =>
                              // props.quoteToOrder(
                              //   props.selectedQuote.tableId,
                              //   props.selectedQuote.id
                              // )
                              history.push({
                                pathname: routesPath.checkoutShipping,
                                state: { selectedQuote: props.selectedQuote },
                              })
                            }
                          >
                            Proceed To Checkout
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={(e) =>
                              props.quoteStatusHandler(
                                props.selectedQuote.tableId,
                                props.selectedQuote,
                                "CLOSED"
                              )
                            }
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </ModalFooter>
                  )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
