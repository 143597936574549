import React, { useEffect, useState } from "react";
import { ProductCanvas } from "../productCanvas/productCanvas";
import noImg from "../../assets/img/noImg.jpg";
import { Modal } from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { asset } from "../../constants/baseUrl";
import { useDispatch, useSelector } from "react-redux";
import * as cartActionsCreator from "../../redux/cart/actionsCreator";
import { TailLoader } from "../ui/loader";
import { toast } from "react-toastify";

export const ProductModal = React.memo((props) => {
  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState(0);
  const [modalActiveTab, setModalActiveTab] = useState(0);
  const [selectedItemQuantity, setSelectedItemQuantity] = useState(1);
  const [cartProductId, setCartProductId] = useState(null);
  const [itemFoundInCart, setItemFoundInCart] = useState(false);
  const loading = useSelector((state) => state.cartReducer.loading);

  useEffect(() => {
    if (props.selectedProduct.quantity) {
      if (props.cartItems?.length) {
        props.cartItems.map((el) => {
          if (el.product.id === props.selectedProduct.id) {
            setCartProductId(el.id);
            setItemFoundInCart(el.quantity);
            return true;
          } else {
            return false;
          }
        });
      }
    }
  }, [props.selectedProduct.quantity]);

  const handleCarouselChange = (e) => {
    setSelectedItem(e);
  };

  const updateCartItems = (productId) => {
    dispatch(
      cartActionsCreator.patchCartProduct({
        quantity: itemFoundInCart + selectedItemQuantity,
        user: parseInt(localStorage.getItem("userId")),
        id: cartProductId,
        product: productId,
      })
    );
  };

  return (
    <div className="pop-model">
      <Modal
        open={true}
        tabIndex="-1"
        className="pop-model"
        style={{ overflowY: "scroll", outline: "1px solid transparent" }}
        onClose={() => {
          props.setSelectedProduct("");
        }}
        id="exampleModal"
        disableAutoFocus
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => {
                  props.setSelectedProduct("");
                }}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="product-detail-images-wrap">
                    {props.view === "2D" ? (
                      <div className="product-detail-images">
                        <div id="sync1" className="slider owl-carousel">
                          <Carousel
                            dynamicHeight
                            autoPlay
                            infiniteLoop
                            showArrows={false}
                            showStatus={false}
                            showThumbs={true}
                            thumbWidth={100}
                            showIndicators={false}
                            onChange={handleCarouselChange}
                            selectedItem={selectedItem}
                          >
                            {props.selectedProduct.images.map((image, key) => {
                              return (
                                <div key={key}>
                                  <div className="product-img-single">
                                    <img
                                      alt="..."
                                      className="d-block"
                                      height="500px"
                                      style={{ objectFit: "contain" }}
                                      src={image.image.replace(
                                        "image/upload/",
                                        ""
                                      )}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </Carousel>
                        </div>
                      </div>
                    ) : props.view === "3D" ? (
                      <ProductCanvas />
                    ) : (
                      <img src={noImg} className="img-fluid" alt="product" />
                    )}
                  </div>
                  <div className="pt-3">
                    <div className="product-thumb-images">
                      <div
                        id="sync2"
                        className="navigation-thumbs owl-carousel owl-loaded"
                      >
                        {props.view === "2D"
                          ? props.selectedProduct.images.map((image, key) => {
                              return (
                                <div
                                  onClick={() => setSelectedItem(key)}
                                  key={key}
                                  className={
                                    selectedItem === key
                                      ? "product-thumb-single  active-carousel-thumb"
                                      : "product-thumb-single "
                                  }
                                  style={{
                                    backgroundImage: `url(${image.image.replace(
                                      "image/upload/",
                                      ""
                                    )})`,
                                  }}
                                ></div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="product-details pd">
                    <div className="product-detail">
                      <h1>{props.selectedProduct.name}</h1>
                      <p>
                        Product by:{" "}
                        <a>{props.selectedProduct.vendor.username}</a>
                      </p>
                      <span className="border-sec">
                        <hr />
                      </span>
                      {!props.isNotCartFunctionality && (
                        <div className="cart-btn">
                          <div className="quantity-outer-wrap">
                            <label for="" className="form-label">
                              Select Quantity
                            </label>
                            <div className="qty-input">
                              <button
                                disabled={selectedItemQuantity <= 1}
                                className="qty-count qty-count--minus"
                                data-action="minus"
                                type="button"
                                onClick={() => {
                                  setSelectedItemQuantity(
                                    selectedItemQuantity > 0 &&
                                      selectedItemQuantity - 1
                                  );
                                }}
                              >
                                -
                              </button>
                              <input
                                className="product-qty"
                                type="number"
                                name="product-qty"
                                min="1"
                                max="50"
                                value={selectedItemQuantity}
                                onChange={(e) => {
                                  if (
                                    e.target.value >= 1 &&
                                    e.target.value <= 500
                                  ) {
                                    setSelectedItemQuantity(e.target.value);
                                  }
                                }}
                              />
                              <button
                                className="qty-count qty-count--add"
                                data-action="add"
                                type="button"
                                onClick={() => {
                                  setSelectedItemQuantity(
                                    selectedItemQuantity + 1
                                  );
                                }}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="links-btn">
                            <div
                              className="add-to-cart"
                              onClick={() => {
                                itemFoundInCart
                                  ? updateCartItems(props.selectedProduct.id)
                                  : props.addProductsToCart(
                                      props.selectedProduct.id,
                                      selectedItemQuantity
                                    );
                              }}
                            >
                              {loading ? (
                                <div className="text-center">
                                  <TailLoader height={50} width={50} />
                                </div>
                              ) : (
                                <button>Add to Cart</button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="customization">
                        <div className="col-12  text-center col-md-6 mb-3">
                          <div
                            className={
                              props.view === "2D" ? "activeViews" : "customize"
                            }
                          >
                            <a onClick={() => props.setView("2D")}>
                              View in 2D
                            </a>
                          </div>
                        </div>
                        <div className="col-12 px-3 text-center col-md-6 ">
                          <div
                            className={
                              props.view === "3D" ? "activeViews" : "get-help"
                            }
                          >
                            <a onClick={() => props.setView("3D")}>
                              View in 3D
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- tabs --> */}
                    <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                          className={
                            modalActiveTab === 0
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-home"
                          type="button"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                          onClick={() => setModalActiveTab(0)}
                        >
                          Description
                        </button>

                        <button
                          className={
                            modalActiveTab === 1
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-profile"
                          type="button"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                          onClick={() => setModalActiveTab(1)}
                        >
                          Specifications
                        </button>
                      </div>
                    </nav>

                    <div className="tab-content" id="nav-tabContent">
                      {modalActiveTab === 0 ? (
                        <div
                          className="tab-pane fade show active"
                          id="nav-home"
                          role="tabpanel"
                          aria-labelledby="nav-home-tab"
                        >
                          <div className="product_description">
                            <p>
                              {props.selectedProduct.description ||
                                "No description given"}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="tab-pane fade show active"
                          id="nav-profile"
                          role="tabpanel"
                          aria-labelledby="nav-profile-tab"
                        >
                          <div className="product_description">
                            <p>
                              This robotic hand unit is designed to be easily
                              combined with other units. Each unit is composed
                              of 2 claws (720 x 720mm)
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <!-- End --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});
