import * as actions from "./actionTypes";
const initialState = {
  response: null,
  error: null,
  loading: false,
  createPaymentIntentResponse: null,
  createPaymentIntentError: null,
};

/*


const initialState = {
  response: null,
  error: null,
  loading: false,
  createPaymentIntentResponse: null,
  createPaymentIntentError: null,
};


*/

export const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    // calculate shipment amount

    case actions.SHIPMENT_AMOUNT:
      return {
        ...state,
        response: null,
        error: null,
        loading: true,
      };
    case actions.SHIPMENT_AMOUNT_SUCCESS:
      return {
        ...state,
        response: action.payload.data,
        error: null,
        loading: false,
      };
    case actions.SHIPMENT_AMOUNT_FAIL:
      return {
        ...state,
        response: null,
        error: action.payload,
        loading: false,
      };

    //create payment intent

    case actions.CREATE_PAYMENT_INTENT:
      return {
        ...state,
        createPaymentIntentResponse: null,
        createPaymentIntentError: null,
        loading: true,
      };
    case actions.CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        createPaymentIntentResponse: action.payload.data,
        loading: false,
        createPaymentIntentError: null,
      };
    case actions.CREATE_PAYMENT_INTENT_FAIL:
      return {
        ...state,
        createPaymentIntentResponse: null,
        createPaymentIntentError: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
