import * as actions from "./actionTypes";

export const getCartProducts = (data) => ({
  type: actions.GET_CART_PRODUCTS,
  payload: data || {},
});

export const deleteCartProducts = (data) => ({
  type: actions.DELETE_CART_PRODUCTS,
  payload: data || {},
});

export const addProductsToCart = (data) => ({
  type: actions.ADD_CART_PRODUCTS,
  payload: data || {},
});

export const patchCartProduct = (data) => ({
  type: actions.PATCH_CART_ITEM_QUANTITY,
  payload: data || {},
});

export const emptyCart = (data) => ({
  type: actions.EMPTY_CART,
  payload: data || {},
});

export const quantityHandler = (data) => {
  return {
    type: actions.QUANTITY_HANDLER,
    payload: data,
  };
};

export const deleteCartItem = (data) => ({
  type: actions.DELETE_CART_PRODUCTS,
  payload: data,
});

export const resetCart = (data) => ({
  type: actions.RESET_CART,
  payload: data,
});
