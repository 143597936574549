import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "../../redux/actionsCreator";
import { FiltersBar } from "../../components/filtersBar/filtersBar";
import { ProductShowCase } from "../../components/productShowCase/productShowCase";
import { createSelector } from "reselect";
import * as cartActionsCreator from "../../redux/cart/actionsCreator";
import "./marketPlaceContainer.scss";
import filterIcon from "../../assets/images/filter-icon.svg";
import { Collapse } from "@material-ui/core";
import { ProductModal } from "../../components/productModal/productModal";
import { TailLoader } from "../../components/ui/loader";
import { toast } from "react-toastify";
import Pagination from "rc-pagination";
import _ from "lodash";
import "rc-pagination/assets/index.css";
import { en } from "../../constants/localeEng";

const products = (state) => state.apiReducer.response;
const error = (state) => state.apiReducer.error;
const loading = (state) => state.apiReducer.loading;
const productType = (state) => state.apiReducer.productType;

const demo = createSelector(
  products,
  loading,
  productType,
  error,
  (products, loading, productType, error) => ({
    products,
    loading,
    productType,
    error,
  })
);

const Ecommerce = () => {
  const dispatch = useDispatch();

  const products = useSelector(demo);
  const cartItems = useSelector((state) => state.cartReducer.cartItems);
  const [expanded, setExpanded] = useState(false);
  const [cartItemQuantity, setCartItemQuantity] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [view, setView] = useState("2D");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedProducts, setPaginatedProducts] = useState(null);

  const error = useSelector((state) => state.cartReducer.error);

  const countPerPage = 10;

  useEffect(() => {
    if (products?.products?.length) {
      setPaginatedProducts(_.cloneDeep(products?.products.slice(0, 10)));
    }
  }, [products]);

  const modelApis = useCallback(() => {
    dispatch(actionCreators.getModelsFilterProducts());
    dispatch(actionCreators.getApplication());
    dispatch(actionCreators.getEquipment());
  }, [dispatch]);
  const componentApis = useCallback(() => {
    dispatch(actionCreators.getComponentsFilterProducts());
    dispatch(actionCreators.getApplication());
    dispatch(actionCreators.getCategories());
  }, [dispatch]);

  useEffect(() => {
    if (products.productType === "model") modelApis();
    else componentApis();
  }, [dispatch, componentApis, modelApis, products.productType]);

  useEffect(() => {
    if (error) {
      toast.error("Out Of Stock!");
    }
  }, [error]);

  const handleProductType = (type) => {
    dispatch(actionCreators.filtreReset());
    dispatch(actionCreators.handleProductType(type));

    if (type === "model") modelApis();
    else componentApis();
  };

  const addProductsToCart = (id, quantity) => {
    let currentId = "";
    let currentQuantity = 0;
    let found = "";
    if (cartItems) {
      found = cartItems.some((el) => {
        if (el.product.id === id) {
          currentId = el.id;
          currentQuantity = quantity || el.quantity;
          return true;
        } else {
          return false;
        }
      });
    }
    if (found) {
      dispatch(
        cartActionsCreator.patchCartProduct({
          quantity: quantity,
          id: parseInt(currentId),
          product: id,
        })
      );
    } else {
      dispatch(
        cartActionsCreator.addProductsToCart({
          quantity: quantity,
          user: localStorage.getItem("userId"),
          product: parseInt(id),
        })
      );
    }
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const updateProduct = (page) => {
    setCurrentPage(page);
    const to = countPerPage * page;
    const from = to - countPerPage;
    setPaginatedProducts(_.cloneDeep(products?.products.slice(from, to)));
  };
  return (
    <div className="ecommerce-contain">
      {selectedProduct && (
        <ProductModal
          selectedProduct={selectedProduct}
          addProductsToCart={addProductsToCart}
          cartItems={cartItems}
          cartItemQuantity={cartItemQuantity}
          setCartItemQuantity={setCartItemQuantity}
          setSelectedProduct={setSelectedProduct}
          view={view}
          setView={setView}
        />
      )}

      <div className="container">
        <div className="main">
          <div className="section py-5">
            <Row>
              <Col lg="3">
                <div className="row d-flex">
                  <div className="col-9">
                    <div className="market-heading-inner">
                      <div className="heading_txt">
                        <h1>Marketplace</h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="filter-section">
                      <img src={filterIcon} onClick={handleChange} />
                    </div>
                  </div>
                </div>
                <Collapse in={window.innerWidth <= 768 ? expanded : true}>
                  <FiltersBar
                    handleProductType={handleProductType}
                    productType={products.productType}
                  />
                </Collapse>
              </Col>
              <Col md="9">
                {products.loading ? (
                  <div className="mt-5 pt-3 text-center">
                    <TailLoader height={100} width={100} />
                  </div>
                ) : products.products && products.products.length ? (
                  <div className="product-showcase-container">
                    <ProductShowCase
                      products={paginatedProducts}
                      setSelectedProduct={setSelectedProduct}
                      addProductsToCart={addProductsToCart}
                    />
                  </div>
                ) : (
                  products?.products?.length &&
                  !loading && (
                    <h4 className="mt-5 pt-5 text-center">
                      No Products Found!
                    </h4>
                  )
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="product-pagination">
        <nav aria-label="Page navigation example">
          <Pagination
            prevIcon={
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                </span>
              </a>
            }
            nextIcon={
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              </a>
            }
            pageSize={countPerPage}
            onChange={updateProduct}
            current={currentPage}
            total={products?.products?.length}
            className={"pagination my-5"}
            // showQuickJumper
            showPrevNextJumpers
            showQuickJumper
            locale={en}
          />
        </nav>
      </div>
    </div>
  );
};

export default Ecommerce;
