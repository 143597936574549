import * as Yup from "yup";

const userValidationSchema = Yup.object({
  username: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  first_name: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  business_name: Yup.string().required("Required"),
  phone_number: Yup.string()
    .matches(/^\+1\d{10}$/, "Enter a Valid US Number")
    .required("Required")
    .label("Phone Number"),
  tax_id: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(9, "Must be exactly 9 digits")
    .max(9, "Must be exactly 9 digits"),
  password: Yup.string().min(8).required("Required"),
  confirm_password: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export default userValidationSchema;
