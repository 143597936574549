import React from "react";
import Loader from "react-loader-spinner";
import { Button } from "reactstrap";

import noImg from "../../assets/img/noImg.jpg";
import { asset } from "../../constants/baseUrl";
import "./configuratorSidebar.css";
import { TailLoader } from "./loader";
import _ from "lodash";
import { Collapse } from "@material-ui/core";

export const ConfiguratorSidebar = (props) => {
  return (
    <div className="config-options-div">
      {props.loading ? (
        <div className="text-center mt-5 pt-3">
          <TailLoader height={100} width={100} />
        </div>
      ) : (
        <div>
          {props.activeStep !== 3 && props.products?.length ? (
            props.products?.map((product) => (
              <div
                className="mb-3 config-item"
                key={product.id}
                onClick={() =>
                  props.updateVerticalSteps({
                    type: "applicationId",
                    id: product.id,
                    name: product.name,
                  })
                }
              >
                {props.activeStep !== 3 && (
                  <div className="row">
                    <div className="col-4">
                      <div
                        className="config-image-container"
                        style={{
                          background: `url(${
                            (product?.images?.length &&
                              product.images[0].image.replace(
                                "image/upload/",
                                ""
                              )) ||
                            noImg
                          }) no-repeat center`,
                          backgroundSize: "contain",
                        }}
                      ></div>
                    </div>
                    <div className="col-8 my-auto">
                      <div className="row">
                        <div className="col-10 py-3">
                          <h6 className="card-category product-head">
                            {product.name}
                          </h6>
                          <div
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              color: "blue",
                              fontSize: "12px",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();

                              props.getProductDetail(product);
                            }}
                          >
                            See Details
                          </div>
                        </div>

                        <div className="col-2 my-auto">
                          <i className="ni ni-bold-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : !_.isEmpty(props.parsedData) && props.activeStep === 3 ? (
            Object.keys(props.parsedData).map((key, index) => {
              return (
                <div className="">
                  <Button
                    id={`toggler${key}`}
                    className="toggling-btn mt-4"
                    onClick={(e) => {
                      // e.stopPropagation();
                      props.handleCollapsedStates({
                        type: "category",
                        id: +key,
                      });
                    }}
                  >
                    {props.parsedData[key].name}
                    <i className="ni ni-bold-down float-right pt-2"></i>
                  </Button>
                  {props.parsedData[key]?.sub_categories?.length &&
                    props.parsedData[key]?.sub_categories.map((subCategory) => {
                      return (
                        <Collapse
                          toggler={`#toggler${subCategory.id}`}
                          className={
                            _.indexOf(props.collapsedState?.categories, +key) >=
                            0
                              ? "mx-2 my-2 "
                              : ""
                          }
                          in={
                            _.indexOf(props.collapsedState?.categories, +key) >=
                            0
                          }
                        >
                          <Button
                            onClick={(e) => {
                              // e.stopPropagation();
                              props.handleCollapsedStates({
                                type: "subCategory",
                                id: +subCategory.id,
                              });
                            }}
                            id={`toggler${subCategory.id}`}
                            className="toggling-sub-btn "
                          >
                            {subCategory.name}
                            <i className="ni ni-bold-down float-right pt-2"></i>
                          </Button>
                          {subCategory?.components?.length &&
                            subCategory.components.map((comp) => {
                              return (
                                <Collapse
                                  className={
                                    _.indexOf(
                                      props.collapsedState?.subCategories,
                                      +subCategory.id
                                    ) >= 0
                                      ? "mt-3"
                                      : ""
                                  }
                                  in={
                                    _.indexOf(
                                      props.collapsedState?.subCategories,
                                      +subCategory.id
                                    ) >= 0
                                  }
                                >
                                  <div
                                    key={comp.id}
                                    className={
                                      _.indexOf(
                                        props.attachments,
                                        comp.id.toString()
                                      ) >= 0
                                        ? "config-item-active"
                                        : "config-item"
                                    }
                                    data-image="img-raised"
                                  >
                                    <div className="row">
                                      <div className="col-4">
                                        <div
                                          className="config-image-container"
                                          style={{
                                            background: `url(${
                                              (comp.images?.length &&
                                                comp.images[0].image.replace(
                                                  "image/upload/",
                                                  ""
                                                )) ||
                                              noImg
                                            }) no-repeat center`,
                                            backgroundSize: "contain",
                                          }}
                                        ></div>
                                      </div>

                                      <div className="col-8 my-auto">
                                        <div className="row">
                                          <div className="col-10 py-3">
                                            <h6 className="card-category  product-head">
                                              {comp.name}
                                            </h6>

                                            <div
                                              style={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                                color: "green",
                                                fontSize: "12px",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                props.handleComponent(comp.id);
                                              }}
                                            >
                                              {_.indexOf(
                                                props.attachments,
                                                comp.id.toString()
                                              ) >= 0
                                                ? "Detach"
                                                : "Attach"}
                                            </div>
                                            <div
                                              style={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                                color: "blue",
                                                fontSize: "12px",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();

                                                props.getProductDetail(comp);
                                              }}
                                            >
                                              See Details
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Collapse>
                              );
                            })}
                        </Collapse>
                      );
                    })}
                </div>
              );
            })
          ) : (
            <div className="text-center mt-5 pt-5">
              <h5>No Products Found</h5>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
