import React from "react";
import configuratorHome from "../../assets/images/configurator-home-img.svg";
import Fade from "react-reveal/Fade";

export const ConfiguratorSection = () => {
  return (
    <div>
      <section className="configurator-home-wrap dark-bg-color article-padd">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Fade left>
                <div>
                  <div className="configurator-home-img-wrap animate__animated animate__fadeInLeft wow">
                    <img
                      src={configuratorHome}
                      className="img-fluid"
                      alt="configurator image"
                    />
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-lg-6">
              <div className="configurator-home-text-wrap">
                <div className="configurator-home-text">
                  <h2>Product Configurator and Augmented Reality</h2>
                  <p>
                    Our 3D product configurator makes it easy to customize
                    industrial robots and machines from companies you trust in
                    real-time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
