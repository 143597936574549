import React from "react";
import Loader from "react-loader-spinner";

export const TailLoader = (props) => (
  <Loader
    className="d-inline"
    type="TailSpin"
    height={props.height}
    width={props.width}
    color="#222F8E"
  />
);
