import * as actions from "../actionTypes";
import Storage from "../../services/storageService";

const initialState = {
  loading: false,
  accessToken: null,
  verifyToken: null,
  refreshToken: null,
  response: null,
  error: null,
  registerResponse: null,
  loginResponse: null,
  loginError: null,
  resendEmailVerificationResponse: null,
  resendEmailVerificationError: null,
  emailVerifyResponse: null,
  emailVerifyError: null,
  email: "",
  loadBuilds: [],
  getUserProfileResponse: null,
  getUserProfileError: null,
  resetEmailResponse: null,
  resetPasswordResponse: null,
  resetEmailError: null,
  resetPasswordError: null,
  resendOTPResponse: null,
  resendOTPError: null,
  loginOTPResponse: null,
  loginOTPError: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REGISTER_USER: {
      return {
        ...state,
        loading: true,
        registerResponse: null,
        error: null,
      };
    }
    case actions.REGISTER_USER_ERROR: {
      return {
        ...state,
        loading: false,
        registerResponse: null,
        error: action.payload,
      };
    }
    case actions.REGISTER_USER_RESPONSE: {
      return {
        ...state,
        loading: false,
        registerResponse: action.payload.data,
        error: null,
      };
    }
    case actions.REGISTERATION_RESET: {
      return {
        ...state,
        loading: false,
        registerResponse: null,
        error: null,
      };
    }

    case actions.RESET_PASSWORD: {
      return {
        ...state,
        loading: true,
        resetPasswordResponse: null,
        resetPasswordError: null,
      };
    }
    case actions.RESET_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        resetPasswordResponse: null,
        resetPasswordError: action.payload,
      };
    }
    case actions.RESET_PASSWORD_RESPONSE: {
      return {
        ...state,
        loading: false,
        resetPasswordResponse: action.payload,
        resetPasswordError: null,
      };
    }

    case actions.FORGOT_PASSWORD: {
      return {
        ...state,
        loading: true,
        resetEmailResponse: null,
        resetEmailError: null,
      };
    }
    case actions.FORGOT_PASSWORD_RESPONSE: {
      return {
        ...state,
        loading: false,
        resetEmailResponse: action.payload.data,
        resetEmailError: null,
      };
    }
    case actions.FORGOT_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        resetEmailResponse: null,
        resetEmailError: action.payload,
      };
    }

    case actions.LOGIN_USER: {
      return {
        ...state,
        loading: true,
        loginResponse: null,
        loginError: null,
      };
    }
    case actions.LOGIN_USER_ERROR: {
      return {
        ...state,
        loading: false,
        loginResponse: null,
        loginError: action.payload,
      };
    }
    case actions.LOGIN_USER_RESPONSE: {
      return {
        ...state,
        loading: false,
        loginResponse: action.payload,
        loginError: null,
      };
    }

    case actions.RESEND_OTP: {
      return {
        ...state,
        loading: true,
        resendOTPResponse: null,
        resendOTPError: null,
      };
    }
    case actions.RESEND_OTP_ERROR: {
      return {
        ...state,
        loading: false,
        resendOTPResponse: null,
        resendOTPError: action.payload,
      };
    }
    case actions.RESEND_OTP_RESPONSE: {
      return {
        ...state,
        loading: false,
        resendOTPResponse: action.payload,
        resendOTPError: null,
      };
    }

    case actions.LOGIN_OTP: {
      return {
        ...state,
        loading: true,
        loginOTPResponse: null,
        loginOTPError: null,
      };
    }
    case actions.LOGIN_OTP_ERROR: {
      return {
        ...state,
        loading: false,
        loginOTPResponse: null,
        loginOTPError: action.payload,
      };
    }
    case actions.LOGIN_OTP_RESPONSE: {
      return {
        ...state,
        loading: false,
        loginOTPResponse: action.payload,
        loginOTPError: null,
      };
    }

    case actions.RESEND_EMAIL_VERIFICATION: {
      return {
        ...state,
        loading: true,
        resendEmailVerificationResponse: null,
        resendEmailVerificationError: null,
      };
    }
    case actions.RESEND_EMAIL_VERIFICATION_ERROR: {
      return {
        ...state,
        loading: false,
        resendEmailVerificationResponse: null,
        resendEmailVerificationError: action.payload,
      };
    }
    case actions.RESEND_EMAIL_VERIFICATION_RESPONSE: {
      return {
        ...state,
        loading: false,
        resendEmailVerificationResponse: action.payload,
        resendEmailVerificationError: null,
      };
    }

    case actions.EMAIL_VERIFY: {
      return {
        ...state,
        emailVerifyResponse: null,
        emailVerifyError: null,
      };
    }

    case actions.EMAIL_VERIFY_ERROR: {
      return {
        ...state,
        emailVerifyResponse: null,
        emailVerifyError: action.payload,
      };
    }

    case actions.EMAIL_VERIFY_RESPONSE: {
      return {
        ...state,
        emailVerifyResponse: action.payload,
        emailVerifyError: null,
      };
    }

    case actions.SIGN_OUT: {
      return {
        ...state,
        loading: false,
        response: null,
        error: null,
        accessToken: null,
        verifyToken: null,
        refreshToken: null,
        response: null,
        error: null,
        loginResponse: null,
        loginError: null,
        loginOTPResponse: null,
        loginOTPError: null,
      };
    }

    case actions.REGISTERATION_RESET: {
      return {
        ...initialState,
        loading: false,
      };
    }
    case actions.VERIFY_TOKEN:
      return {
        ...state,
        loading: true,
        verifyToken: null,
      };
    case actions.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        verifyToken: action.payload,
      };
    case actions.VERIFY_TOKEN_ERROR:
      Storage.clearJWTToken();
      localStorage.removeItem("userId");
      localStorage.removeItem("email");
      return {
        ...state,
        loading: false,
        verifyToken: action.payload,
        accessToken: null,
        loginResponse: null,
      };

    case actions.ME_SUCCESS:
      localStorage.setItem("email", action.payload.data.email);
      return {
        ...state,
        email: action.payload.data.email,
      };

    case actions.VENDOR_SIGNUP:
      return {
        ...state,
        loading: true,
        registerResponse: null,
        error: null,
      };
    case actions.VENDOR_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        registerResponse: action.payload.data,
        error: null,
      };
    case actions.VENDOR_SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
        registerResponse: null,
        error: action.payload,
      };
    case actions.USER_PROFILE:
      return {
        ...state,
        loading: true,
        getUserProfileResponse: null,
        getUserProfileError: null,
      };
    case actions.USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        getUserProfileResponse: action.payload.data,
        getUserProfileError: null,
      };
    case actions.USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        getUserProfileError: action.payload,
      };

    default:
      return state;
  }
};
