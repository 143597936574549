import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PublicLayout } from "./layouts/publicLayout";
import { CoreLayout } from "./layouts/coreLayout";
import { routesPath } from "./constants/routesPath";
import NavbarDefault from "./components/navbars/navbar";
import { LoginContainer } from "./screens/auth/LoginContainer";
import { EmailVerificationFormContainer } from "./screens/auth/EmailVerificationFormContainer";
import { VerifyEmailContainer } from "screens/auth/VerifyEmailContainer";
import { SignupContainer } from "./screens/auth/SignUpContainer";
import MarketPlaceContainer from "./screens/marketPlace/marketPlaceContainer";
import { SearchProduct } from "./screens/marketPlace/SeacrhProductContainer";
import { Configurator } from "./screens/configuratorContainer/ConfiguratorContainer";
import { ProtectedRoute } from "./layouts/protectedRoutes";
import { DashboardContainer } from "./screens/dashboardContainer/DashboardContainer";
import { CheckoutContainer } from "./screens/checkoutContainer/checkoutContainer";
import Checkout from "./components/stripe/checkout";
import { EcommerceContainer } from "./screens/ecommerceContainer/EcommerceContainer";
import { AboutContainer } from "./screens/aboutContainer/AboutContainer";
import { ProfileContainer } from "./screens/profileContainer/ProfileContainer";
import { ResetPasswordContainer } from "./screens/resetPasswordContainer/ResetPasswordContainer";
import { HomeContainer } from "./screens/homeContainer/HomeContainer";
import { ContactUsContainer } from "./screens/contactUsContainer/ContactUsContainer";
import { Error404Container } from "./screens/Error404Container/Error404Container";
import ScrollTop from "./utils/scrollTop";

/*

Toast Container:   Used toast container for handling success warning and error toast messages to user

CoreLayout: Layout for authenticated user

Public layout : Layout for unauthincated user 

*/

export const Router = () => {
  return (
    <div>
      <ToastContainer
        position="bottom-left"
        hideProgressBar={true}
        style={{ opacity: "0.8" }}
      ></ToastContainer>
      <NavbarDefault></NavbarDefault>
      <PublicLayout>
        <Switch>
          <ScrollTop>
            <Route exact path={routesPath.home} component={HomeContainer} />
            <Route
              exact
              path={routesPath.ecommerce}
              component={EcommerceContainer}
            />
            <Route
              exact
              path={routesPath.marketPlace}
              component={MarketPlaceContainer}
            />
            <Route exact path={routesPath.aboutUs} component={AboutContainer} />

            <Route
              exact
              path={routesPath.login}
              render={(props) => <LoginContainer {...props} />}
            />
            <Route
              exact
              path={routesPath.emailVerification}
              render={(props) => <EmailVerificationFormContainer {...props} />}
            />
            <Route
              exact
              path={routesPath.verifyEmail}
              render={(props) => <VerifyEmailContainer {...props} />}
            />
            <Route
              exact
              path={routesPath.register}
              component={SignupContainer}
            />
            <Route
              exact
              path={routesPath.reset}
              component={ResetPasswordContainer}
            />
            <Route
              exact
              path={routesPath.contactUs}
              component={ContactUsContainer}
            />
          </ScrollTop>
        </Switch>
      </PublicLayout>
      <CoreLayout>
        <Switch>
          <ProtectedRoute
            path={routesPath.dashboard}
            component={DashboardContainer}
          />
          <ProtectedRoute
            exact
            path={routesPath.configure}
            component={Configurator}
          />
          <ProtectedRoute
            exact
            path={routesPath.checkoutShipping}
            component={CheckoutContainer}
          />
          <ProtectedRoute
            exact
            path={routesPath.checkoutOrder}
            component={Checkout}
          />
          <ProtectedRoute
            exact
            path={routesPath.userProfile}
            component={ProfileContainer}
          />
          <ProtectedRoute
            exact
            path={routesPath.search}
            component={SearchProduct}
          />
          <Route
            // exact
            path={routesPath.notFound}
            component={Error404Container}
          />
        </Switch>
      </CoreLayout>
    </div>
  );
};
