import React, { useState } from "react";
import { TailLoader } from "../ui/loader";

export const ResetPasswordPage = (props) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const verifyPasswordPattern = () => {
    if (password.trim("").length < 8 || confirmPassword !== password)
      return false;
    else return true;
  };

  const submitPassword = () => {
    if (verifyPasswordPattern()) {
      props.submitForgotPassword(password);
      setPassword("");
      setConfirmPassword("");
      setConfirmPasswordError("");
    } else {
      if (confirmPassword !== password && password.trim("").length >= 8) {
        setConfirmPasswordError("Password does not match");
      } else {
        setPasswordError("Password should contain minimum of 8 characters");
      }
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="needs-validation">
        <div className="heading_txt_login">
          <h1>Forgot Password</h1>
        </div>
        <div
          className={
            passwordError ? "form-outline form-outline-danger" : "form-outline"
          }
        >
          <input
            className="form-control"
            id="validationCustom01"
            placeholder="New Password"
            type="password"
            readOnly={props.loading}
            required
            onChange={(e) => {
              setPasswordError("");
              setPassword(e.target.value);
            }}
            value={password}
          />
          {passwordError && (
            <span className="text-danger ">{passwordError}</span>
          )}
        </div>
        <div
          className={
            confirmPasswordError
              ? "form-outline-danger form-outline"
              : "form-outline"
          }
        >
          <input
            className="form-control"
            id="validationCustom01"
            placeholder="Confirm New Password"
            type="password"
            readOnly={props.loading}
            required
            onChange={(e) => {
              setConfirmPasswordError("");
              setConfirmPassword(e.target.value);
            }}
            value={confirmPassword}
          />
          {confirmPasswordError && (
            <span className="text-danger">{confirmPasswordError}</span>
          )}
        </div>

        <button
          //   disabled={
          //     !password.trim("").lenght && !confirmPassword.trim("").lenght
          //   }
          onClick={submitPassword}
          className="btn btn-primary"
          type="submit"
        >
          {props.loading && (
            <div className="d-inline px-3">
              <TailLoader height={20} width={20} />
            </div>
          )}
          <span className="d-inline">Reset</span>
        </button>
        <hr />
      </div>
    </form>
  );
};
