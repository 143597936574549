import Storage from "../services/storageService";
/*
@params
return jwt header
*/
export const authHeader = () => {
  return {
    common: {
      Authorization: `JWT ${Storage.getJWTToken()}`,
    },
  };
};
