import React from "react";

export const InputFeild = (props) => {
  return (
    <div class="input-group">
      <input
        type="text"
        class="form-control border-success"
        placeholder="Search Products"
        onChange={(e) => props.changed(e)}
        value={props.value}
      />
      <div class="input-group-append">
        <button class="btn btn-success " type="button">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
  );
};
