import React, { useEffect, useState } from "react";
import { InputFeild } from "../ui/input";
import { useDebounce } from "../../utils/debounce";
import * as actionsCreator from "../../redux/actionsCreator";
import { ProductShowCase } from "../productShowCase/productShowCase";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import * as cartActionsCreator from "../../redux/cart/actionsCreator";
import { TailLoader } from "../ui/loader";
import { ProductModal } from "../productModal/productModal";
import { toast } from "react-toastify";
import Pagination from "rc-pagination";
import _ from "lodash";
import "rc-pagination/assets/index.css";
import { en } from "../../constants/localeEng";

export const Search = () => {
  const countPerPage = 10;
  const [inputValue, setInputValue] = useState("");
  let dispatch = useDispatch();
  let history = useHistory();

  const [selectedProduct, setSelectedProduct] = useState("");
  const [cartItemQuantity, setCartItemQuantity] = useState(1);
  const [view, setView] = useState("2D");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedProducts, setPaginatedProducts] = useState(null);

  const searchResult = useSelector(
    (state) => state.apiReducer.response,
    shallowEqual
  );
  const error = useSelector((state) => state.cartReducer.error);
  const loading = useSelector(
    (state) => state.apiReducer.loading,
    shallowEqual
  );

  const cartItems = useSelector((state) => state.cartReducer.cartItems);

  const valueChangedHandler = (e) => {
    setInputValue(e.target.value);
  };

  const viewSelectedProduct = (id) => {
    dispatch(actionsCreator.viewSelectedProduct(id));
    history.push("/product");
  };

  useDebounce(actionsCreator.searchProduct, inputValue, 2000);

  useEffect(() => {
    if (error) {
      toast.error("Out Of Stock!");
    }
  }, [error]);

  useEffect(() => {
    if (!inputValue.length) {
      dispatch(actionsCreator.searchProduct(""));
    }
  }, [inputValue]);

  useEffect(() => {
    if (searchResult?.length) {
      setPaginatedProducts(_.cloneDeep(searchResult.slice(0, 10)));
    }
  }, [searchResult]);

  const addProductsToCart = (id, quantity) => {
    let currentId = "";
    let currentQuantity = 0;
    let found = "";
    if (cartItems) {
      found = cartItems.some((el) => {
        if (el.product.id === id) {
          currentId = el.id;
          currentQuantity = quantity;
          return true;
        } else {
          return false;
        }
      });
    }
    if (found) {
      dispatch(
        cartActionsCreator.patchCartProduct({
          quantity: parseInt(currentQuantity) + 1,
          id: parseInt(currentId),
          product: id,
        })
      );
    } else {
      dispatch(
        cartActionsCreator.addProductsToCart({
          quantity: 1,
          user: localStorage.getItem("userId"),
          product: parseInt(id),
        })
      );
    }
  };

  const updateProduct = (page) => {
    setCurrentPage(page);
    const to = countPerPage * page;
    const from = to - countPerPage;
    setPaginatedProducts(_.cloneDeep(searchResult.slice(from, to)));
  };

  return (
    <>
      <div className="container">
        <div className="row pt-5">
          <div className="col-12">
            <InputFeild changed={valueChangedHandler} value={inputValue} />
          </div>
        </div>
        <div className="row">
          <div className="col pt-5 mt-5">
            {loading && (
              <div className="text-center">
                {" "}
                <TailLoader height={100} width={100} />{" "}
              </div>
            )}
            {searchResult && searchResult.length ? (
              <ProductShowCase
                products={paginatedProducts}
                loading={loading}
                from="search"
                viewSelectedProduct={viewSelectedProduct}
                addProductsToCart={addProductsToCart}
                setSelectedProduct={setSelectedProduct}
              />
            ) : (
              !loading && (
                <h5 className="d-block text-center mx-auto mt-5 mb-3">
                  No product Found. Try a diffrent search!
                </h5>
              )
            )}
          </div>
        </div>
        {selectedProduct && (
          <ProductModal
            selectedProduct={selectedProduct?.data}
            addProductsToCart={addProductsToCart}
            cartItems={cartItems}
            cartItemQuantity={cartItemQuantity}
            setCartItemQuantity={setCartItemQuantity}
            setSelectedProduct={setSelectedProduct}
            view={view}
            setView={setView}
          />
        )}
        <Pagination
          prevIcon={
            <a className="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">
                <i className="fa fa-angle-left" aria-hidden="true"></i>
              </span>
            </a>
          }
          nextIcon={
            <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </a>
          }
          pageSize={countPerPage}
          onChange={updateProduct}
          current={currentPage}
          total={searchResult?.length}
          className={"pagination my-5"}
          // showQuickJumper
          showPrevNextJumpers
          showQuickJumper
          locale={en}
        />
      </div>
    </>
  );
};
