// stepper steps on each iteration
/*

return Array<string>
*/
export const stepperDefaultData = [
  "Select_Application",
  "Select_Equipment",
  "Select_Model",
  "Select_Component",
];
