import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js"; // for initiating stripe instance
import { BrowserRouter, useHistory } from "react-router-dom";
import { Router } from "./routes"; // containing all nvigations to screens containing components
import { useDispatch, useSelector } from "react-redux";

import "./App.css";
import { Footer } from "./components/footer/footer";
import * as actionCreators from "./redux/actionsCreator";
import * as cartActionsCreator from "./redux/cart/actionsCreator";
import { TailLoader } from "./components/ui/loader"; //loader
import { routesPath } from "./constants/routesPath";
import storageService from "./services/storageService"; // service for handling local storage
import ScrollTop from "./utils/scrollTop";

export const App = () => {
  const dispatch = useDispatch();

  //check if tenant is verified user
  const tenantSuccessStatus = useSelector(
    (state) => state.apiReducer.tenantSuccessStatus
  );

  //getting tenant status from redux store
  const tenantLoadingStatus = useSelector(
    (state) => state.apiReducer.tenantLoadingStatus
  );

  //loading stripe instance
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  useEffect(() => {
    dispatch(actionCreators.checkStatus());
    //before listing products get tenant status from store
    if (storageService.getJWTToken())
      dispatch(cartActionsCreator.getCartProducts());
    //get cart products to keep them updated
  }, []);

  return (
    <div>
      {tenantLoadingStatus || !tenantSuccessStatus ? (
        <div className="text-center mt-5 pt-5">
          <TailLoader height={100} width={100} />
        </div>
      ) : (
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <Router />
            <Footer />
          </BrowserRouter>
        </Elements>
      )}
    </div>
  );
};
