import React, { useEffect, useState } from "react";
import { Col, Divider, Row, Table } from "antd";
import jwt from "jwt-decode";
import { asset } from "../../constants/baseUrl";
import quoteStatus from "../../constants/quoteStatus";
import { useHistory } from "react-router-dom";
import { routesPath } from "../../constants/routesPath";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import * as quotesActionsCreator from "../../redux/quotation/actionsCreator";
import { useDispatch, useSelector } from "react-redux";
import { TailLoader } from "../ui/loader";
import { decryptedPrice } from "../../utils/decode";
import storageService from "../../services/storageService";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";

import "antd/dist/antd.css";

export const QuoteDetail = (props) => {
  const downloadPdf = () => {
    setPdfLoading(true);
    htmlToImage
      .toPng(document.getElementById("myPage"), { quality: 0.95 })
      .then(function (dataUrl) {
        setPdfLoading(false);
        var link = document.createElement("a");
        link.download = "my-image-name.jpeg";
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("quoteInformation.pdf");
      });
  };

  const [pdfLoading, setPdfLoading] = useState(false);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const fetchedQuotes = useSelector(
    (state) => state.quotationReducer.QuoteById
  );
  const fetchedOrders = useSelector(
    (state) => state.quotationReducer.orderById
  );
  const loading = useSelector((state) => state.quotationReducer.loading);
  const [quote, setQuote] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const token = storageService.getJWTToken();
  const userData = jwt(token);

  const calculatePrice = () => {
    let sum = 0;
    if (quote) {
      quote.subquotes.map((subQuote) =>
        subQuote.quote_items.map(
          (item) => (sum += decryptedPrice(item.price) * +item.quantity)
        )
      );
      return sum;
    }
  };

  useEffect(() => {
    if (fetchedQuotes) setQuote(fetchedQuotes);
  }, [fetchedQuotes]);

  useEffect(() => {
    if (quote) {
      if (new Date(quote.expires_at).getTime() > new Date().getTime()) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [quote]);

  useEffect(() => {
    dispatch(quotesActionsCreator.getQouteById(match.params.id));
  }, []);

  const getTableDataSource = () => {
    const quotes = [];
    quote.subquotes.map((subQuote, index) => {
      subQuote.quote_items.map((item, index) => {
        quotes.push({
          item: (
            <img
              src={item.product.data.images[0].image.replace(
                "image/upload/",
                ""
              )}
              style={{
                height: "70px",
                width: "60px",
              }}
            />
          ),
          name: `${item.product.data.name}`,
          sku: `${item.product.data.sku}`,
          vendor: `${item.product.data.vendor.business_name}`,
          price: ` $${decryptedPrice(item.price) * parseInt(item.quantity)}`,
          quantity: `${item.quantity}`,
        });
      });
    });
    return quotes;
  };

  return (
    <>
      <div className="dashboard-head-wrap">
        <div
          className="dashboard-head-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <h1 className="pl-5 ml-5">Quote - {quote?.id}</h1>
          <div onClick={downloadPdf}>
            {pdfLoading && (
              <div className="d-inline px-3">
                <TailLoader height={20} width={20} />
              </div>
            )}
            <i className="fa fa-print" />
          </div>
        </div>
      </div>
      <div>
        <div className="dashboard-content-wrap">
          <div className="main-content-inner">
            <section className="bg-white pb-5">
              {quote ? (
                <>
                  <div id="myPage">
                    <div className="pt-3">
                      <Divider
                        className="px-2 justify-content-center"
                        style={{
                          fontWeight: "bold",
                          fontSize: 36,
                        }}
                      >
                        Quote Information
                      </Divider>

                      <div className="row mx-auto">
                        <div className="col-12 col-lg-6">
                          <div
                            style={{
                              fontSize: "13px",
                              paddingBottom: "4px",
                              color: "#4f4e4e",
                            }}
                          >
                            Quote Id:&nbsp;
                            <span
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              <b>{quote.id}</b>
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: "13px",
                              paddingBottom: "4px",
                              color: "#4f4e4e",
                            }}
                          >
                            Name:&nbsp;
                            <span
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {userData.username}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: "13px",
                              paddingBottom: "4px",
                              color: "#4f4e4e",
                            }}
                          >
                            Email:&nbsp;
                            <span
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {userData.email}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: "13px",
                              paddingBottom: "4px",
                              color: "#4f4e4e",
                            }}
                          >
                            Status:&nbsp;
                            <span
                              className={
                                quoteStatus[quote.status].label === "RECIEVED"
                                  ? "badge bg-success"
                                  : "badge bg-warning text-dark"
                              }
                              style={{ fontSize: "12px" }}
                            >
                              {quoteStatus[quote.status].label}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: "13px",
                              paddingBottom: "4px",
                              color: "#4f4e4e",
                            }}
                          >
                            Notes:&nbsp;
                            <span
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {quote.notes || "-"}
                            </span>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 text-end pt-3 pt-lg-0">
                          <div
                            style={{
                              fontSize: "13px",
                              paddingBottom: "4px",
                              color: "#4f4e4e",
                            }}
                          >
                            Created at:&nbsp;
                            <span
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {new Date(quote.created).toUTCString()}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: "13px",
                              paddingBottom: "4px",
                              color: "#4f4e4e",
                            }}
                          >
                            Expires at:&nbsp;
                            <span
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {new Date(quote.expires_at).toUTCString()}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: "13px",
                              paddingBottom: "4px",
                              color: "#4f4e4e",
                            }}
                          >
                            Down payment:&nbsp;
                            <span
                              style={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {quote.down_payment}%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-3 row mx-auto">
                      <div>
                        <Table
                          dataSource={getTableDataSource()}
                          style={{
                            width: "100%",
                            maxHeight: "600px",
                            overflow: "scroll",
                          }}
                          pagination={false}
                        >
                          <Table.Column title="ITEM" dataIndex="item" />
                          <Table.Column title="NAME" dataIndex="name" />
                          <Table.Column title="SKU" dataIndex="sku" />
                          <Table.Column title="VENDOR" dataIndex="vendor" />
                          <Table.Column title="QUANTITY" dataIndex="quantity" />
                          <Table.Column title="PRICE" dataIndex="price" />
                        </Table>
                        <div className="text-end pt-3 pt-lg-4">
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              paddingBottom: "4px",
                              color: "#4f4e4e",
                            }}
                          >
                            Gross Total:&nbsp;
                            <span
                              style={{
                                fontSize: "15px",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {`$${calculatePrice()}`}
                            </span>
                          </div>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              paddingBottom: "4px",
                              color: "#4f4e4e",
                            }}
                          >
                            Net Total:&nbsp;
                            <span
                              style={{
                                fontSize: "15px",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {`$${calculatePrice()}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {(quote.status === "SENT_TO_CUSTOMER" ||
                    quote.status === "VENDOR_TO_CUSTOMER") && (
                    <div className="pt-3 row mx-auto">
                      <div
                        className="checkout-links"
                        style={{
                          paddingTop: 38,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div className="return">
                          <button
                            onClick={(e) =>
                              props.quoteStatusHandler("", quote, "CLOSED")
                            }
                          >
                            Reject
                          </button>
                        </div>
                        <div
                          className="place-order"
                          style={{ paddingLeft: "50px" }}
                        >
                          <button
                            onClick={(e) =>
                              history.push({
                                pathname: routesPath.checkoutShipping,
                                state: { selectedQuote: quote },
                              })
                            }
                            disabled={!isValid}
                          >
                            {isValid ? "Proceed To Checkout" : "Quote Expired"}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                loading && (
                  <div className="pt-5 text-center">
                    <TailLoader height={100} width={100} />
                  </div>
                )
              )}
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
