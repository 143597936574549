import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  email_address: Yup.string().required("Required"),
  phone_number: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

export default validationSchema;
