import * as actions from "./actionTypes";

const initialState = {
  response: null,
  error: null,
  loading: false,
  cartItems: [],
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_CART_PRODUCTS:
      return {
        ...state,
        loading: true,
        error: null,
        response: null,
        cartItems: null,
      };
    case actions.GET_CART_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        response: action.payload.data,
        cartItems: action.payload.data,
      };
    case actions.GET_CART_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        response: null,
      };
    case actions.ADD_CART_PRODUCTS:
      return {
        ...state,
        loading: true,
        error: null,
        response: null,
      };
    case actions.ADD_CART_PRODUCTS_SUCCESS: {
      let tempCart = [];
      if (state.cartItems?.length) {
        tempCart = [...state.cartItems];
      }
      tempCart.push(action.payload.response.data);

      tempCart[tempCart.length - 1].product = {
        id: tempCart[tempCart.length - 1].product,
      };

      return {
        ...state,
        loading: false,
        response: action.payload.response.data,
        error: null,
        cartItems: tempCart,
      };
    }
    case actions.ADD_CART_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        response: null,
      };
    case actions.PATCH_CART_ITEM_QUANTITY:
      return {
        ...state,
        loading: true,
        error: null,
        response: null,
      };
    case actions.PATCH_CART_ITEM_QUANTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        response: action.payload.data,
      };
    case actions.PATCH_CART_ITEM_QUANTITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        response: null,
      };
    case actions.EMPTY_CART:
      return {
        ...state,
        loading: true,
        error: null,
        response: null,
      };
    case actions.EMPTY_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        response: action.payload,
        cartItems: null,
      };
    case actions.EMPTY_CART_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        response: null,
      };

    case actions.QUANTITY_HANDLER: {
      let tempResponse = [...state.cartItems];
      tempResponse[action.payload.index].quantity =
        action.payload.e.currentTarget.value;
      return {
        ...state,
        loading: false,
        cartItems: tempResponse,
      };
    }

    case actions.DELETE_CART_PRODUCTS:
      return {
        ...state,
        loading: true,
        error: null,
        response: null,
      };
    case actions.DELETE_CART_PRODUCTS_SUCCESS:
      let tempResponse = [...state.cartItems];
      tempResponse = tempResponse.filter(
        (product) => product.id !== action.payload
      );
      return {
        ...state,
        loading: false,
        error: null,
        response: null,
        cartItems: tempResponse,
      };
    case actions.DELETE_CART_PRODUCTS_ERROR:
      return {
        ...state,
        loading: true,
        error: action.payload,
        response: null,
      };
    case actions.RESET_CART:
      return {
        // ...state,
        cartReducer: [],
        response: null,
      };

    default:
      return {
        ...state,
      };
  }
};
