import * as actions from "./actionTypes";

//redux thunk middleware

/*

@params: string:actiontype,
@return : action:{
  dispatching , payload
} 

*/
export const calculateShipmentAmount = (data) => ({
  type: actions.SHIPMENT_AMOUNT,
  payload: data || {},
});

export const createPaymentIntent = (data) => ({
  type: actions.CREATE_PAYMENT_INTENT,
  payload: data || {},
});
