import React from "react";
import { Flexibility } from "../../components/flexibility/flexibility";
import { Banner } from "../../components/ui/banner";

export const EcommerceContainer = () => {
  const bannerHead = "eCommerce Not headless! Not CMS! We are truly No-Code";
  const bannerText =
    "Give your e-commerce store near instant pageloads with a frontend deployed to botics global network.";

  return (
    <div>
      <Banner bannerHead={bannerHead} bannerText={bannerText} />
      <Flexibility />
    </div>
  );
};
