import React, { useEffect, useState, useRef } from "react";
import validationSchema from "./validationSchemaForLogin";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as actionsCreator from "../../redux/actionsCreator";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { routesPath } from "../../constants/routesPath";
import { TailLoader } from "../../components/ui/loader";

// Core Components

export const LoginContainer = () => {
  const location = useLocation();
  const history = useHistory();
  let dispatch = useDispatch();
  let passwordRef = useRef(null);
  let usernameRef = useRef(null);
  const [activeStep, setActiveStep] = useState(1);
  const [otp_channel, set_otp_channel] = useState("EMAIL");

  const emailCheck = () => set_otp_channel("EMAIL");
  const phoneCheck = () => set_otp_channel("SMS");

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const goBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const loginResponse = useSelector((state) => state.authReducer.loginResponse);
  const loginError = useSelector((state) => state.authReducer.loginError);

  const loginOTPResponse = useSelector(
    (state) => state.authReducer.loginOTPResponse
  );
  const loginOTPError = useSelector((state) => state.authReducer.loginOTPError);
  const loading = useSelector((state) => state.authReducer.loading);
  const initialValues = {
    username: "",
    password: "",
    otp: "",
  };

  const loginFunction = (data) => {
    if (data.username && data.password) {
      data.otp_channel = otp_channel;
      dispatch(actionsCreator.loginUser(data));
    } else toast.error("Please Enter Credentials!");
  };
  const loginOTPFunction = (data) => {
    if (data) {
      data.password = data.otp;
      delete data.otp;
      dispatch(actionsCreator.loginOTP(data));
    } else toast.error("Please Enter Credentials!");
  };

  useEffect(() => {
    console.log("loginResponse", loginResponse);
  }, [loginResponse]);

  useEffect(() => {
    console.log("loginOTPResponse", loginOTPResponse);
  }, [loginOTPResponse]);

  useEffect(() => {
    if (loginResponse && !loginError) {
      handleNext();
    } else if (
      loginError?.response?.data?.detail ===
      "Username or Password is Incorrect!"
    ) {
      toast.error(loginError?.response?.data?.detail);
      goBack();
    } else if (
      loginError?.response?.data?.detail === "Account is not Verified"
    ) {
      toast.error(loginError?.response?.data?.detail);
      history.push(routesPath.emailVerification);
    }
  }, [loginResponse, loginError]);

  useEffect(() => {
    if (loginOTPResponse && !loginOTPError) {
      const state = location?.state;
      history.push(state ? state?.from?.pathname : "/");
    } else if (loginOTPError?.response?.data?.non_field_errors[0]) {
      toast.error(loginOTPError?.response?.data?.non_field_errors[0]);
    } else if (loginOTPError) {
      toast.error("Invalid User OTP");
    }
  }, [loginOTPResponse, loginOTPError]);

  return (
    <>
      <main>
        <section className="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Formik
                  validateOnChange={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    if (activeStep === 1) {
                      handleNext();
                    } else if (activeStep === 2) {
                      loginFunction(values);
                    } else {
                      loginOTPFunction(values);
                    }
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    touched,
                    isValid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="needs-validation">
                        <div>
                          {activeStep === 1 ? (
                            ""
                          ) : (
                            <div
                              onClick={goBack}
                              style={{ paddingTop: "5%", paddingLeft: "40px" }}
                            >
                              <i className="fa fa-arrow-left" />
                            </div>
                          )}
                          <div className="heading_txt_login">
                            {activeStep === 1 && <h1>Login</h1>}
                            {activeStep === 2 && <h1>Send OTP</h1>}
                            {activeStep === 3 && <h1>Enter OTP</h1>}
                          </div>
                          {activeStep === 1 && (
                            <div>
                              <div
                                className={
                                  errors.username
                                    ? "form-outline form-outline-danger"
                                    : "form-outline"
                                }
                              >
                                <input
                                  className="form-control"
                                  id="username"
                                  placeholder="User Name"
                                  type="text"
                                  onChange={handleChange}
                                  value={values.username}
                                  name="username"
                                  ref={usernameRef}
                                />
                              </div>
                              <div
                                className={
                                  errors.password
                                    ? "form-outline form-outline-danger"
                                    : "form-outline"
                                }
                              >
                                <input
                                  className="form-control"
                                  id="password"
                                  placeholder="Password"
                                  type="password"
                                  name="password"
                                  onChange={handleChange}
                                  value={values.password}
                                  ref={passwordRef}
                                />
                              </div>
                              {(errors.password && touched.password) ||
                                (loginError && (
                                  <small className="text-danger d-block px-4 mx-3 pb-2">
                                    {loginError
                                      ? "Invalid username or password"
                                      : errors.password}
                                  </small>
                                ))}
                              <div className="forget-pass">
                                <fieldset>
                                  <div className="checkbox-rember">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="remember"
                                      name="interest"
                                      value="coding"
                                    />
                                    <label for="remember">Remember me</label>
                                  </div>
                                </fieldset>
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={routesPath.reset}
                                >
                                  <p>
                                    <a href="javascript:void(0);">
                                      Forgot password
                                    </a>
                                  </p>
                                </Link>
                              </div>
                            </div>
                          )}
                          {activeStep === 2 && (
                            <div>
                              <button
                                className={
                                  otp_channel === "EMAIL"
                                    ? "btn btn-primary1"
                                    : "btn btn-primary2"
                                }
                                onClick={emailCheck}
                                type="button"
                              >
                                <span className="d-inline">Email</span>
                              </button>
                              <button
                                className={
                                  otp_channel === "SMS"
                                    ? "btn btn-primary1"
                                    : "btn btn-primary2"
                                }
                                onClick={phoneCheck}
                                type="button"
                              >
                                <span className="d-inline">Phone</span>
                              </button>
                            </div>
                          )}

                          {activeStep === 3 && (
                            <div>
                              <div
                                className={
                                  errors.password
                                    ? "form-outline form-outline-danger"
                                    : "form-outline"
                                }
                              >
                                <input
                                  className="form-control"
                                  id="otp"
                                  placeholder="Enter OTP"
                                  type="password"
                                  name="otp"
                                  onChange={handleChange}
                                  value={values.otp}
                                  required
                                />
                              </div>
                              <div className="resend-otp">
                                <p>
                                  <a onClick={goBack}>Resend OTP</a>
                                </p>
                              </div>
                            </div>
                          )}
                          {(activeStep === 1 || activeStep === 3) && (
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!isValid || loading}
                            >
                              {loading && (
                                <div className="d-inline px-3">
                                  <TailLoader height={20} width={20} />
                                </div>
                              )}
                              <span className="d-inline">Login</span>
                            </button>
                          )}
                          {activeStep === 2 && (
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!isValid || loading}
                            >
                              {loading && (
                                <div className="d-inline px-3">
                                  <TailLoader height={20} width={20} />
                                </div>
                              )}
                              <span className="d-inline">Send</span>
                            </button>
                          )}
                          <hr />
                          {activeStep === 1 && (
                            <Link
                              style={{ textDecoration: "none" }}
                              to={routesPath.register}
                            >
                              <div className="create-acc">
                                <p>
                                  Don’t you have an account?
                                  <a href="signup.html">Create New</a>
                                </p>
                              </div>
                            </Link>
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
