import React from "react";
import { Link, useHistory } from "react-router-dom";
import mainBanner from "../../assets/images/main-banner-img.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import { routesPath } from "../../constants/routesPath";
import storageService from "../../services/storageService";
import Fade from "react-reveal/Fade";

export const SasPlatForm = () => {
  const history = useHistory();
  return (
    <div style={{ background: "#FFFFFF" }}>
      <section className="main-banner-wrap article-padd">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-banner-inner">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="main-banner-text-wrap">
                      <div className="main-banner-text">
                        <h1>
                          Our SaaS platform makes adopting robotics & automation
                          faster and easier
                        </h1>
                        <p>
                          A platform that unlocks the power of connected
                          products by assigning unique digital IDs to everyday
                          items, providing unparalleled end-to-end transparency.
                        </p>
                        <div className="main-banner-links">
                          <div className="banner-btn">
                            <Link
                              to={
                                storageService.getJWTToken()
                                  ? routesPath.marketPlace
                                  : routesPath.login
                              }
                              className="green-btn"
                            >
                              Get Started{" "}
                            </Link>
                          </div>
                          <div className="external-link">
                            <Link to={routesPath.contactUs}>
                              Questions? Talk to an expert
                              <img src={rightArrow} alt="external link arrow" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <Fade right>
                      <div className="main-banner-img-wrap animate__animated animate__fadeInRight wow">
                        <div className="main-banner-img">
                          <img
                            src={mainBanner}
                            className="img-fluid"
                            alt="main banner image listing company's services"
                          />
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
