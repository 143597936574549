import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as quoteActionsCreator from "../../redux/quotation/actionsCreator";
import { Container, Row, Col } from "reactstrap";
import { DetailModal } from "../../components/detailModal/detailModal";
import { DataTable } from "../../components/dataTable/dataTable";
import { PubnubChat } from "../../components/chat/chat";
import InitializePubnub from "../../components/pubnub/initializePubnub";
import dataService from "../../services/dataService";
import Badge from "reactstrap/lib/Badge";
import Button from "reactstrap/lib/Button";
import { useHistory } from "react-router-dom";
import { routesPath } from "../../constants/routesPath";
import { toast } from "react-toastify";
import { TailLoader } from "../../components/ui/loader";
import { DownPaymentModal } from "../../components/downPaymentModal/downPaymentModal";

export const Orders = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const orders = useSelector((state) => state.quotationReducer.orders);
  const loading = useSelector((state) => state.quotationReducer.loading);
  const chargePaymentResponse = useSelector(
    (state) => state.quotationReducer.chargePaymentResponse
  );
  const chargePaymentError = useSelector(
    (state) => state.quotationReducer.chargePaymentError
  );
  const chargePaymentLoading = useSelector(
    (state) => state.quotationReducer.chargePaymentLoading
  );
  const [openChat, setOpenChat] = useState(false);
  const [currentChannel, setCurrentChannel] = React.useState(``);
  const [OrderDetails, setOrderDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [parsedOrders, setParsedOrders] = useState([]);
  const [paymentOrderQuote, setPaymentOrderQuote] = useState(null);

  useEffect(() => {
    dispatch(quoteActionsCreator.getOrders());
  }, [dispatch]);

  useEffect(() => {
    if (orders) {
      let result = dataService.parseOrdersTable(
        orders,
        getOrderItems,
        getOrdersActions
      );
      setParsedOrders(result);
    }
  }, [orders]);

  // useEffect(() => {
  //   if (chargePaymentResponse && paymentOrderQuote) {
  //     history.push({
  //       pathname: `${routesPath.checkoutOrder}`,
  //       state: {
  //         quote: paymentOrderQuote.quote,
  //         shipmentAmount: paymentOrderQuote.shipment_amount,
  //       },
  //     });
  //   }
  // }, [chargePaymentResponse]);
  useEffect(() => {
    if (chargePaymentError) {
      toast.error("Payment failed");
    }
  }, [chargePaymentError]);

  useEffect(() => {
    return () => {
      quoteActionsCreator.resetChargePayment();
    };
  }, []);

  const toggle = () => setOrderDetails(!OrderDetails);

  const handleChat = (id) => {
    setCurrentChannel(`order-${id}`);
    setOpenChat(true);
  };

  const getOrderItems = (order) => {
    return order.suborders.map((subOrder) => {
      return subOrder.sub_quote.quote_items.map((quoteItem, index) => {
        return (
          <Badge color="badge-dot bg-dark m-1" key={index}>
            {quoteItem.product.data.name}
          </Badge>
        );
      });
    });
  };

  const getOrdersActions = (quote) => {
    return (
      <div
        //  className="d-inline"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div
          className="quotes-action d-inline mx-2"
          onClick={(e) => {
            e.stopPropagation();
            handleChat(quote.id);
          }}
        >
          <button href="javascript:void(0);">Chat</button>
        </div>
        {quote.pending_amount > 0 && (
          <div
            disabled={chargePaymentLoading}
            className="quotes-action d-inline"
            onClick={(e) => {
              e.stopPropagation();
              setPaymentOrderQuote(quote);
            }}
          >
            <button disabled={chargePaymentLoading}>
              {chargePaymentLoading && <TailLoader height={10} width={10} />}
              <span className="px-1">Pay Now</span>
            </button>
          </div>
        )}
      </div>
    );
  };

  const getOrderDetail = (order) => {
    order.tableId = order.tableData.id;
    history.push(
      `${routesPath.dashboard}${routesPath.orderDetailPage(order["#"])}`
    );
    // orders.map((order_map) => {
    //   if (order_map.id === order["#"]) {
    //     setSelectedOrder(order_map.quote);
    //     setOrderDetails(true);
    //   }
    // });
  };

  return (
    <>
      {paymentOrderQuote && (
        <DownPaymentModal
          order={paymentOrderQuote}
          setPaymentOrderQuote={setPaymentOrderQuote}
        />
      )}
      <div>
        <div className="dashboard-head-wrap">
          <div className="dashboard-head-title">
            <h1>Orders</h1>
          </div>
        </div>
        <div className="dashboard-content-wrap">
          <div className="main-content-inner">
            <Row>
              <Col className="ml-auto" md="12">
                <DataTable
                  data={parsedOrders}
                  loading={loading}
                  clicked={getOrderDetail}
                />
                {OrderDetails && (
                  <DetailModal
                    quoteDetails={OrderDetails}
                    toggle={toggle}
                    selectedQuote={selectedOrder}
                    from={"orders"}
                  />
                )}
              </Col>
            </Row>

            {localStorage.getItem("email") && openChat && (
              <InitializePubnub>
                <PubnubChat
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                  currentChannel={currentChannel}
                />
              </InitializePubnub>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
