import React from "react";
import { Link } from "react-router-dom";
import exploreMoreIcon1 from "../../assets/images/explore-more-icon-1.svg";
import exploreMoreIcon2 from "../../assets/images/explore-more-icon-2.svg";
import exploreMoreIcon3 from "../../assets/images/explore-more-icon-3.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import { routesPath } from "../../constants/routesPath";

export const ExploreMoreSection = () => {
  return (
    <div style={{ background: "#FFFFFF" }}>
      <section className="explore-home-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="explore-home-inner">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="explore-home-single">
                      <img
                        src={exploreMoreIcon1}
                        className="img-fluid"
                        alt="subscription icon"
                      />
                      <h3>1. Subscribe</h3>
                      <p>
                        Subscribe and choose the account that best fits your
                        business size and needs.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="explore-home-single">
                      <img
                        src={exploreMoreIcon2}
                        className="img-fluid"
                        alt="deploy products icon"
                      />
                      <h3>2. Deploy</h3>
                      <p>
                        Deploy 3D products on the marketplace or in your own
                        store depending on your subscription.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="explore-home-single">
                      <img
                        src={exploreMoreIcon3}
                        className="img-fluid"
                        alt="sell products icon"
                      />
                      <h3>3. Sell</h3>
                      <p>
                        Use the Botics dashboard to manage your product
                        configurations, quotes, sales, and customer
                        relationships.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="explore-home-links">
                <div className="explore-home-btn">
                  <Link to={routesPath.ecommerce} className="green-btn">
                    Explore More about Ecommerce
                  </Link>
                </div>
                <div className="external-link">
                  <Link to={routesPath.contactUs}>
                    Questions? Talk to an expert{" "}
                    <img src={rightArrow} alt="external link arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
