import { Formik, useFormik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TailLoader } from "../../components/ui/loader";
import * as actions from "../../redux/actionsCreator";
import validationSchema from "./validationSchema";

export const ProfileContainer = (props) => {
  const dispatch = useDispatch();

  const getUserProfileResponse = useSelector(
    (state) => state.authReducer.getUserProfileResponse
  );
  const getUserProfileError = useSelector(
    (state) => state.authReducer.getUserProfileError
  );
  const loading = useSelector((state) => state.authReducer.loading);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let initialValues = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    business_name: "",
    password: "",
  };

  const updateUserInfo = (values) => {};

  useEffect(() => {
    dispatch(actions.getUserProfile());
  }, []);
  if (getUserProfileResponse) {
    initialValues = { ...getUserProfileResponse };
  }

  useEffect(() => {
    if (getUserProfileResponse) {
      initialValues = { ...getUserProfileResponse };
    }
  }, [getUserProfileResponse]);

  return (
    <>
      <div className="dashboard-head-wrap">
        <div className="dashboard-head-title">
          <h1 className="pl-5 ml-5">Profile</h1>
        </div>
      </div>
      <div className="dashboard-content-wrap">
        <div className="main-content-inner">
          <section className="contact-form">
            <div className="row">
              <div className="col-12 col-md-8">
                <Formik
                  enableReinitialize
                  validateOnChange={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    updateUserInfo(values);
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    touched,
                    isValid,
                  }) => (
                    <form onChange={() => setIsSubmitting(true)}>
                      {getUserProfileResponse ? (
                        <div className="profile-needs-validation">
                          <div className="needs-validation">
                            <div className="heading_txt_login">
                              <h1>User Information</h1>
                            </div>
                            <div className={"form-outline"}>
                              <input
                                className="form-control"
                                id="validationCustom01"
                                placeholder="First Name"
                                type="text"
                                onChange={handleChange}
                                value={values.first_name}
                                name="first_name"
                              />
                            </div>
                            <div className={"form-outline"}>
                              <input
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Last Name"
                                type="text"
                                onChange={handleChange}
                                value={values.last_name}
                                name="last_name"
                              />
                            </div>
                            <div className={"form-outline"}>
                              <input
                                className="form-control"
                                id="validationCustom01"
                                placeholder="User Name"
                                type="text"
                                onChange={handleChange}
                                value={values.username}
                                name="username"
                              />
                            </div>
                            <div className={"form-outline"}>
                              <input
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Email"
                                type="email"
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                              />
                            </div>

                            <div className={"form-outline"}>
                              <input
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Business Name"
                                type="text"
                                onChange={handleChange}
                                value={values.business_name}
                                name="business_name"
                              />
                            </div>

                            <button
                              style={{ float: "left" }}
                              className="btn btn-primary"
                              type="submit"
                              disabled={!isValid || loading}
                            >
                              {loading && (
                                <div className="d-inline px-3">
                                  <TailLoader height={20} width={20} />
                                </div>
                              )}
                              <span className="d-inline">Submit</span>
                            </button>
                          </div>
                        </div>
                      ) : loading ? (
                        <div className="py-5 my-5 text-center">
                          <TailLoader height={100} width={100} />
                        </div>
                      ) : (
                        getUserProfileError && (
                          <div className="text-center py-5 my-5">
                            Failed to get user Information
                          </div>
                        )
                      )}
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
