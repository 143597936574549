import * as actions from "./actionTypes";

/*

@params 
data: {payload,actionType}

@ return {
  type:string,
  payload:{data}
}

*/

export const getCategories = (data) => ({
  type: actions.CATEGORIES,
  payload: data || {},
});

export const getSubCategories = (data) => ({
  type: actions.SUB_CATEGORIES,
  payload: data || {},
});

export const getProducts = (data) => ({
  type: actions.PRODUCTS,
  payload: data || {},
});

export const registerUser = (data) => ({
  type: actions.REGISTER_USER,
  payload: data || {},
});

export const resetRegistration = () => ({
  type: actions.REGISTERATION_RESET,
});

export const signout = (data) => ({
  type: actions.SIGN_OUT,
  payload: data || {},
});

export const loginUser = (data) => ({
  type: actions.LOGIN_USER,
  payload: data,
});

export const resendOTP = (data) => ({
  type: actions.RESEND_OTP,
  payload: data,
});

export const loginOTP = (data) => ({
  type: actions.LOGIN_OTP,
  payload: data,
});

export const resendEmailVerification = (data) => ({
  type: actions.RESEND_EMAIL_VERIFICATION,
  payload: data,
});

export const emailVerify = (data) => ({
  type: actions.EMAIL_VERIFY,
  payload: data,
});

export const forgotPassword = (data) => ({
  type: actions.FORGOT_PASSWORD,
  payload: data || {},
});
export const forgotPasswordSuccess = (data) => ({
  type: actions.FORGOT_PASSWORD_RESPONSE,
  payload: data,
});

export const resetPassword = (data) => ({
  type: actions.RESET_PASSWORD,
  payload: data || {},
});

export const changePassword = (data) => ({
  type: actions.CHANGE_PASSWORD,
  payload: data,
});

export const getCategory = (data) => ({
  type: actions.SINGLE_CATEGORY,
  payload: data || {},
});

export const getApplication = (data) => ({
  type: actions.APPLICATIONS,
  payload: data || {},
});

export const getEquipment = (data) => ({
  type: actions.EQUIPMENTS,
  payload: data || {},
});

export const getModels = (data) => ({
  type: actions.MODELS,
  payload: data || {},
});

export const getComponent = (data) => ({
  type: actions.COMPONENT,
  payload: data || {},
});

export const getModelsFilterProducts = (data) => ({
  type: actions.MODELS_FILTERS,
  payload: data || {},
});

export const getComponentsFilterProducts = (data) => ({
  type: actions.COMPONENTS_FILTERS,
  payload: data || {},
});

export const handleProductType = (data) => ({
  type: actions.PRODUCT_TYPE,
  payload: data,
});

export const filtreHandler = (data) => ({
  type: actions.FILTER,
  payload: data,
});

export const filtreReset = (data) => ({
  type: actions.FILTER_RESET,
  payload: data || {},
});

export const resetRegisteration = (data) => {
  return {
    type: actions.REGISTERATION_RESET,
    payload: data || {},
  };
};

export const viewSelectedProduct = (data) => ({
  type: actions.SELECTED_PRODUCT,
  payload: data || {},
});

export const searchProduct = (data) => ({
  type: actions.SEARCH_PRODUCTS,
  payload: data,
});

export const verifyToken = (data) => ({
  type: actions.VERIFY_TOKEN,
  payload: data,
});

export const getMe = (data) => ({
  type: actions.ME,
  payload: data,
});

export const postConfiguratorBuilds = (data) => ({
  type: actions.POST_USER_BUILD,
  payload: data,
});

export const getConfiguratorBuilds = (data) => ({
  type: actions.GET_USER_BUILD,
  payload: data,
});

export const updateConfiguratorBuilds = (data) => ({
  type: actions.UPDATE_USER_BUILD,
  payload: data,
});

export const deleteConfiguratorBuilds = (data) => ({
  type: actions.DELETE_USER_BUILD,
  payload: data,
});

export const vendorSignUp = (data) => ({
  type: actions.VENDOR_SIGNUP,
  payload: data,
});

export const openDashBoardDrawer = () => {
  return {
    type: actions.DASHBOARD_DRAWER_HANDLE,
  };
};

export const getUserProfile = (data) => ({
  type: actions.USER_PROFILE,
  payload: data,
});

export const clearConfiguratorData = (data) => ({
  type: actions.CLEAR_CONFIGURATOR,
  payload: data || {},
});

export const contactUs = (data) => ({
  type: actions.CONTACT_US,
  payload: data || {},
});

export const resetContactUs = (data) => ({
  type: actions.RESET_CONTACT_US,
  payload: data || {},
});

export const checkStatus = (data) => ({
  type: actions.CHECK_STATUS,
  payload: data || {},
});

export const saveBuildReset = (data) => ({
  type: actions.POST_BUILD_RESET,
  payload: data || {},
});
