import React from "react";
import companyImage from "../../assets/images/company-img.png";

export const Team = () => {
  return (
    <div>
      <section className="company-outer-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="company-text-wrap">
                <div className="company-text">
                  <h2>A company is as good as its team</h2>
                  <p>
                    Botics is a diverse group of incredible talent from all over
                    the world. We’re ~44% woman or non-binary, and have about
                    half as many nationalities as we are team members!
                  </p>
                  <p>
                    Just over half of the company works remotely and the rest
                    out of our HQ. Even HQ team members work from home two days
                    every week to ensure we are not just remote friendly, but
                    truly have a distributed culture.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="company-img-wrap">
                <img
                  src={companyImage}
                  className="img-fluid"
                  alt="people working in company"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
