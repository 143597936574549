export const CART_TO_QUOTE = "CART_TO_QUOTE";
export const CART_TO_QUOTE_SUCCESS = "CART_TO_QUOTE_SUCCESS";
export const CART_TO_QUOTE_ERROR = "CART_TO_QUOTE_ERROR";

export const GET_QUOTES = "GET_QUOTES";
export const GET_QUOTES_SUCCESS = "GET_QUOTES_SUCCESS";
export const GET_QUOTES_ERROR = "GET_QUOTES_ERROR";

export const QUOTE_STATUS = "QUOTE_STATUS";
export const QUOTE_STATUS_SUCCESS = "QUOTE_STATUS_SUCCESS";
export const QUOTE_STATUS_ERROR = "QUOTE_STATUS_ERROR";

export const QUOTE_TO_ORDER = "QUOTE_TO_ORDER ";
export const QUOTE_TO_ORDER_SUCCESS = "QUOTE_TO_ORDER_SUCCESS";
export const QUOTE_TO_ORDER_ERROR = "QUOTE_TO_ORDER_ERROR";

export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";

export const CLOSE_QUOTE = "CLOSE_QUOTE";
export const CLOSE_QUOTE_SUCCESS = "CLOSE_QUOTE_SUCCESS";
export const CLOSE_QUOTE_ERROR = "CLOSE_QUOTE_ERROR";

export const GET_QUOTE_BY_ID = "GET_QUOTE_BY_ID";
export const GET_QUOTE_BY_ID_SUCCESS = "GET_QUOTE_BY_ID_SUCCESS";
export const GET_QUOTE_BY_ID_ERROR = "GET_QUOTE_BY_ID_ERROR";

export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";
export const GET_ORDER_BY_ID_SUCCESS = "GET_ORDER_BY_ID_SUCCESS";
export const GET_ORDER_BY_ID_ERROR = "GET_ORDER_BY_ID_ERROR";

export const CHARGE_PAYMENT = "CHARGE_PAYMENT";
export const CHARGE_PAYMENT_SUCCESS = "CHARGE_PAYMENT_SUCCESS";
export const CHARGE_PAYMENT_ERROR = "CHARGE_PAYMENT_ERROR";

export const RESET_CHARGE_PAYMENT = "RESET_CHARGE_PAYMENT";

export const DOWN_PAYMENT_CONFIRMED = "DOWN_PAYMENT_CONFIRMED";

export const RESET_QUOTATIONS = "RESET_QUOTATIONS";

export const RESET_CART_TO_QUOTE = "RESET_CART_TO_QUOTE";
