import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Drawer } from "@material-ui/core";
import * as cartActionsCreator from "../../redux/cart/actionsCreator";
import * as quoteActionsCreator from "../../redux/quotation/actionsCreator";
import noImg from "../../assets/img/noImg.jpg";
import { routesPath } from "../../constants/routesPath";
import cartIcon from "../../assets/images/cart.svg";
import { TailLoader } from "../ui/loader";
import "./cart.css";
import { toast } from "react-toastify";

export const Cart = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const cartProducts = useSelector((state) => state.cartReducer.cartItems);
  const cartToQuoteResponse = useSelector(
    (state) => state.quotationReducer.response
  );
  const cartToQuoteSuccess = useSelector(
    (state) => state.quotationReducer.cartToQuoteSuccess
  );
  const loading = useSelector((state) => state.cartReducer.loading);
  const [activeCartItemLoader, setActiveCartItemLoader] = useState(-1);

  useEffect(() => {
    dispatch(cartActionsCreator.getCartProducts());
  }, [dispatch, cartToQuoteResponse]);

  useEffect(() => {
    if (cartToQuoteResponse && cartToQuoteSuccess) {
      props.toggle();
      history.push(
        `${routesPath.dashboard}${routesPath.quoteDetailPage(
          cartToQuoteResponse.id
        )}`
      );
    }
  }, [cartToQuoteResponse, history, props]);

  const quantityHandler = (e, index, product) => {
    if (e.currentTarget.value >= product?.product?.quantity) {
      toast.error("Out Of Stock!");
      return;
    }

    if (e.currentTarget.value >= 1) {
      dispatch(
        cartActionsCreator.quantityHandler({
          e,
          index,
        })
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(quoteActionsCreator.resetCartToQuote());
    };
  }, []);

  const cartQuantityHandler = (e, id, quantity, productId) => {
    if (e.keyCode === 13 && quantity > 0) {
      dispatch(
        cartActionsCreator.patchCartProduct({
          quantity: parseInt(e.target.value),
          user: parseInt(localStorage.getItem("userId")),
          id: parseInt(id),
          product: parseInt(productId),
        })
      );
    }
  };

  const InitiateCartToQuote = () => {
    if (cartProducts) dispatch(quoteActionsCreator.InitiateCartToQuote());
  };

  return (
    <div>
      <Drawer
        open
        anchor="right"
        className="cart-drawer"
        onClose={props.toggle}
      >
        <div className="">
          <div>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="cart-img">
                    <img src={cartIcon} className="img-fluid" />
                    {cartProducts?.length > 0 && (
                      <span className="cartItems-count">
                        {cartProducts && cartProducts.length}
                      </span>
                    )}
                  </div>
                  <div className="model-heading-review">
                    <h1>Review your cart</h1>
                  </div>
                  <button
                    type="button"
                    className="btn-close cart-close-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={props.toggle}
                  ></button>
                </div>

                {cartProducts?.length ? (
                  <div className="modal-body">
                    <div className="pb-2  me-2" style={{ float: "right" }}>
                      <button
                        onClick={() => dispatch(cartActionsCreator.emptyCart())}
                        className="btn-red-bg"
                      >
                        Clear Cart
                      </button>
                    </div>
                    <hr style={{ clear: "both" }} />
                    <div className="modal-body-inner">
                      {cartProducts.map((product, index) => (
                        <>
                          <div className="modal-build-single product-details-cart">
                            <div className="modal-build-single-text model-left">
                              <div className="modal-build-single-img model-left-inner">
                                <img
                                  src={
                                    (product.product.data?.images.length &&
                                      product.product.data.images[0].image.replace(
                                        "image/upload/",
                                        ""
                                      )) ||
                                    noImg
                                  }
                                  alt="Product Image"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="modal-build-single-name model-left-product">
                                <h4>{product.product.data?.name}</h4>
                                <p>{product.product.sku}</p>
                              </div>
                              <div>
                                {loading &&
                                cartProducts.length > 0 &&
                                activeCartItemLoader === index ? (
                                  <div>
                                    <TailLoader width={50} height={50} />
                                  </div>
                                ) : (
                                  <div className="text-center">
                                    <div className="cart-controls">
                                      <button
                                        className="d-inline minus-button"
                                        value={
                                          parseInt(product.quantity) - 1 || 1
                                        }
                                        onClick={(e) => {
                                          quantityHandler(e, index);
                                          cartQuantityHandler(
                                            e,
                                            product.id,
                                            product.quantity,
                                            product.product.id
                                          );
                                          dispatch(
                                            cartActionsCreator.patchCartProduct(
                                              {
                                                quantity: product.quantity,
                                                user: parseInt(
                                                  localStorage.getItem("userId")
                                                ),
                                                id: product.id,
                                                product: product.product.id,
                                              }
                                            )
                                          );
                                          setActiveCartItemLoader(index);
                                        }}
                                      >
                                        <i className="fa fa-minus"></i>
                                      </button>
                                      <input
                                        className="d-inline"
                                        type="number"
                                        name="product-qty"
                                        min="1"
                                        max="1000"
                                        id={`input${index}`}
                                        value={product.quantity}
                                        onKeyDown={(e) => {
                                          cartQuantityHandler(
                                            e,
                                            product.id,
                                            product.quantity,
                                            product.product.id
                                          );
                                          setActiveCartItemLoader(index);
                                        }}
                                        onChange={(e) => {
                                          quantityHandler(e, index, product);
                                        }}
                                      />

                                      <button
                                        className="d-inline plus-button"
                                        value={parseInt(product.quantity) + 1}
                                        onClick={(e) => {
                                          quantityHandler(e, index, product);
                                          dispatch(
                                            cartActionsCreator.patchCartProduct(
                                              {
                                                quantity: product.quantity,
                                                user: parseInt(
                                                  localStorage.getItem("userId")
                                                ),
                                                id: product.id,
                                                product: product.product.id,
                                              }
                                            )
                                          );
                                          setActiveCartItemLoader(index);
                                        }}
                                      >
                                        <i className="fa fa-plus"></i>
                                      </button>
                                    </div>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        dispatch(
                                          cartActionsCreator.deleteCartItem(
                                            product.id
                                          )
                                        )
                                      }
                                    >
                                      <Badge className="badge bg-danger">
                                        Remove
                                      </Badge>
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      ))}
                    </div>
                  </div>
                ) : !loading ? (
                  <div className="pt-5 mt-5 text-center">
                    <p>Cart is empty</p>
                  </div>
                ) : (
                  <div className="pt-5 mt-5 text-center">
                    <TailLoader height="100" width="100" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {cartProducts?.length > 0 && (
          <div className="quote-btn" onClick={InitiateCartToQuote}>
            <a>Request a quote</a>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Cart;
