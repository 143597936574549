import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import * as checkoutActionsCreator from "../../redux/checkout/actionsCreator";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { TailLoader } from "../ui/loader";

//styling stripe card
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const Checkout = (props) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const shipmentAmount = props.location.state.shipmentAmount; // shippment amount getting from parent component by routeer state
  const paymentIntentResponse = useSelector(
    (state) => state.checkoutReducer.createPaymentIntentResponse
  ); // paytment repsonse from redux
  const PaymentIntentError = useSelector(
    (state) => state.checkoutReducer.createPaymentIntentError
  );
  const loading = useSelector((state) => state.checkoutReducer.loading);
  const [clientSecret, setClientSecret] = useState(""); // used for managing user with stripe to create and keep their ids for payment through stripe
  const [paymentLoader, setPaymentLoader] = useState(false);

  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    //as soon as user land on this page frpom parent we need to create payment intent before starting stripe initialization
    dispatch(
      checkoutActionsCreator.createPaymentIntent({
        quote_id: props.location.state.quote.id,
        shipment_amount: shipmentAmount,
        address: {
          quote_id: props.location.state.quote.id,
          address: props.location.state.adressDetails.shipping_streetAddress,
          postal_code: props.location.state.adressDetails.shipping_postalCode,
          state: props.location.state.adressDetails.shipping_region,
          country_code: props.location.state.adressDetails.shipping_country,
          city: props.location.state.adressDetails.shipping_city,
        },
      })
    );
  }, []);

  useEffect(() => {
    if (paymentIntentResponse?.client_secret) {
      setClientSecret(paymentIntentResponse.client_secret);
    }
  }, [paymentIntentResponse]);
  useEffect(() => {
    if (PaymentIntentError) {
      toast.error("payment initialization failed");
    }
  }, [PaymentIntentError]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPaymentLoader(true);
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      setPaymentLoader(false);
      setPaymentSuccess(false);
      toast.error(result.error);
    } else {
      setPaymentLoader(false);
      setPaymentSuccess(true);
      toast.success("Order Created Successfully!");
    }
  };

  return (
    <div className="container py-5 text-center">
      {loading && (
        <div
          className="spinner-border text-primary"
          style={{ width: "100px", height: "100px" }}
        ></div>
      )}
      {!loading && (
        <div className="row">
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                {props.location.state.quote && (
                  <div className="mt-5 pt-3">
                    <h3 className="my-3">Shipping Details</h3>
                    <div>
                      <div>
                        <span className="text-dark my-1">Street : </span>
                        {
                          props.location.state.adressDetails
                            ?.shipping_streetAddress
                        }
                      </div>
                      <div>
                        <span className="text-dark my-1">Postal Code : </span>
                        {
                          props.location.state.adressDetails
                            ?.shipping_postalCode
                        }
                      </div>
                      <div>
                        <span className="text-dark my-1">Region : </span>
                        {props.location.state.adressDetails?.shipping_region}
                      </div>
                      <div>
                        <span className="text-dark my-1">City : </span>
                        {props.location.state.adressDetails?.shipping_city}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {props.location.state.quote && (
                  <div className=" mt-5 pt-3">
                    <h3 className="my-3">Amount Details</h3>
                    <div>
                      <div>
                        <span className="text-dark my-1">Net Amount : </span>
                        {props.location.state?.netAmount}
                      </div>
                      <div>
                        <span className="text-dark my-1">Down Payment : </span>
                        {props.location.state?.downPayment}
                      </div>
                      <div>
                        <span className="text-dark my-1">
                          Shipment Amount :
                        </span>
                        {` $${props.location.state?.shipmentAmount}`}
                      </div>
                      <div>
                        <span className="text-dark my-1">Total Amount : </span>
                        {props.location.state?.totalAmount}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {paymentLoader && (
              <div className="text-center mt-5 pt-5">
                <TailLoader height={100} width={100} />{" "}
              </div>
            )}
            {!paymentSuccess && !PaymentIntentError ? (
              <form
                onSubmit={handleSubmit}
                className={paymentLoader ? "d-none" : " d-block mt-3"}
              >
                <div className="col-4 d-flex flex-column mx-auto">
                  <label>
                    <div className="text-muted">
                      <h3 className="my-5">
                        <strong>CARD DETAILS</strong>
                      </h3>
                    </div>
                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                  </label>
                  <button
                    type="submit"
                    className="btn btn-primary mt-5 text-center"
                    disabled={!stripe}
                    style={{ width: "100px" }}
                  >
                    Pay
                  </button>
                </div>
              </form>
            ) : (
              paymentSuccess &&
              !paymentLoader && (
                <div className="success-payment text-center mt-5 pt-5">
                  <h2>Order Created Successfully!</h2>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;
