import React from "react";
import { Modal } from "reactstrap";
export const ConfiguratorModal = (props) => {
  return (
    <>
      <Modal
        isOpen={props.modalOpen}
        toggle={() => props.setModalOpen(props.modalOpen)}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {props.product.name}
          </h6>
          <button
            style={{
              background: "transparent",
              border: "none",
              fontSize: "21px",
            }}
            aria-label="Close"
            className="close"
            onClick={() => props.setModalOpen(!props.modalOpen)}
            type="button"
          >
            <span aria-hidden={false}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{props.product.description}</p>
        </div>
      </Modal>
    </>
  );
};
