/*
@return string 
*/

//contains all the actiontypes to help reducer to identify actions

export const CATEGORIES = "CATEGORIES";
export const CATEGORIES_ERROR = "CATEGORIES_ERROR";
export const CATEGORIES_RESPONSE = "CATEGORIES_RESPONSE";

export const SUB_CATEGORIES = "SUB_CATEGORIES";
export const SUB_CATEGORIES_ERROR = "SUB_CATEGORIES_ERROR";
export const SUB_CATEGORIES_RESPONSE = "SUB_CATEGORIES_RESPONSE";

export const PRODUCTS = "PRODUCTS";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";
export const PRODUCTS_RESPONSE = "PRODUCTS_RESPONSE";

export const MODELS_FILTERS = "MODELS_FILTERS";
export const MODELS_FILTERS_ERROR = "MODELS_FILTERS_ERROR";
export const MODELS_FILTERS_RESPONSE = "MODELS_FILTERS_RESPONSE";

export const SELECTED_PRODUCT = "SELECTED_PRODUCT";
export const SELECTED_PRODUCT_RESPONSE = "SELECTED_PRODUCT_RESPONSE";
export const SELECTED_PRODUCT_ERROR = "SELECTED_PRODUCT_ERROR";

export const COMPONENTS_FILTERS = "COMPONENTS_FILTERS";
export const COMPONENTS_FILTERS_ERROR = "COMPONENTS_FILTERS_ERROR";
export const COMPONENTS_FILTERS_RESPONSE = "COMPONENTS_FILTERS_RESPONSE";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const REGISTER_USER_RESPONSE = "REGISTER_USER_RESPONSE";

export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_ERROR = "SIGN_OUT_ERROR";
export const SIGN_OUT_RESPONSE = "SIGN_OUT_RESPONSE";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGIN_USER_RESPONSE = "LOGIN_USER_RESPONSE";

export const RESEND_OTP = "RESEND_OTP";
export const RESEND_OTP_ERROR = "RESEND_OTP_ERROR";
export const RESEND_OTP_RESPONSE = "RESEND_OTP_RESPONSE";

export const LOGIN_OTP = "LOGIN_OTP";
export const LOGIN_OTP_ERROR = "LOGIN_OTP_ERROR";
export const LOGIN_OTP_RESPONSE = "LOGIN_OTP_RESPONSE";

export const RESEND_EMAIL_VERIFICATION = "RESEND_EMAIL_VERIFICATION";
export const RESEND_EMAIL_VERIFICATION_ERROR =
  "RESEND_EMAIL_VERIFICATION_ERROR";
export const RESEND_EMAIL_VERIFICATION_RESPONSE =
  "RESEND_EMAIL_VERIFICATION_RESPONSE";

export const EMAIL_VERIFY = "EMAIL_VERIFY";
export const EMAIL_VERIFY_ERROR = "EMAIL_VERIFY_ERROR";
export const EMAIL_VERIFY_RESPONSE = "EMAIL_VERIFY_RESPONSE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_RESPONSE = "FORGOT_PASSWORD_RESPONSE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_RESPONSE = "RESET_PASSWORD_RESPONSE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CHANGE_PASSWORD_RESPONSE = "CHANGE_PASSWORD_RESPONSE";

export const RESET_STATE = "RESET_STATE";

export const SINGLE_CATEGORY = "SINGLE_CATEGORY";
export const SINGLE_CATEGORY_ERROR = "SINGLE_CATEGORY_ERROR";
export const SINGLE_CATEGORY_RESPONSE = "SINGLE_CATEGORY_RESPONSE";

export const APPLICATIONS = "APPLICATIONS";
export const APPLICATIONS_ERROR = "APPLICATIONS_ERROR";
export const APPLICATIONS_RESPONSE = "APPLICATIONS_RESPONSE";

export const EQUIPMENTS = "EQUIPMENTS";
export const EQUIPMENTS_ERROR = "EQUIPMENTS_ERROR";
export const EQUIPMENTS_RESPONSE = "EQUIPMENTS_RESPONSE";

export const MODELS = "MODELS";
export const MODELS_ERROR = "MODELS_ERROR";
export const MODELS_RESPONSE = "MODELS_RESPONSE";

export const COMPONENT = "COMPONENT";
export const COMPONENT_ERROR = "COMPONENT_ERROR";
export const COMPONENT_RESPONSE = "COMPONENT_RESPONSE";

export const PRODUCT_TYPE = "PRODUCT_TYPE";

export const FILTER = "FILTER";
export const FILTER_RESET = "FILTER_RESET";

export const REGISTERATION_RESET = "REGISTERATION_RESET";

export const DELETE_CART_ITEMS = "DELETE_CART_ITEMS";
export const DELETE_CART_ITEMS_SUCCESS = "DELETE_CART_ITEMS_SUCCESS";
export const DELETE_CART_ITEMS_ERROR = "DELETE_CART_ITEMS_ERROR";

export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS";
export const SEARCH_PRODUCTS_ERROR = "SEARCH_PRODUCTS_ERROR";

export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const VERIFY_TOKEN_ERROR = "VERIFY_TOKEN_ERROR";

export const ME = "ME";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_ERROR = "ME_ERROR";

export const POST_USER_BUILD = "POST_USER_BUILD";
export const POST_USER_BUILD_SUCCESS = "POST_USER_BUILD_SUCCESS";
export const POST_USER_BUILD_FAIL = "POST_USER_BUILD_FAIL";

export const GET_USER_BUILD = "GET_USER_BUILD";
export const GET_USER_BUILD_SUCCESS = "GET_USER_BUILD_SUCCESS";
export const GET_USER_BUILD_FAIL = "GET_USER_BUILD_FAIL";

export const UPDATE_USER_BUILD = "UPDATE_USER_BUILD";
export const UPDATE_USER_BUILD_SUCCESS = "UPDATE_USER_BUILD_SUCCESS";
export const UPDATE_USER_BUILD_FAIL = "UPDATE_USER_BUILD_FAIL";

export const DELETE_USER_BUILD = "DELETE_USER_BUILD";
export const DELETE_USER_BUILD_SUCCESS = "DELETE_USER_BUILD_SUCCESS";
export const DELETE_USER_BUILD_FAIL = "DELETE_USER_BUILD_FAIL";

export const VENDOR_SIGNUP = "VENDOR_SIGNUP";
export const VENDOR_SIGNUP_SUCCESS = "VENDOR_SIGNUP_SUCCESS";
export const VENDOR_SIGNUP_FAIL = "VENDOR_SIGNUP_FAIL";

export const USER_PROFILE = "USER_PROFILE";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

export const CONTACT_US = "CONTACT_US";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_ERROR = "CONTACT_US_Error";

export const CHECK_STATUS = "CHECK_STATUS";
export const CHECK_STATUS_SUCCESS = "CHECK_STATUS_SUCCESS";
export const CHECK_STATUS_ERROR = "CHECK_STATUS_Error";

export const POST_BUILD_RESET = "POST_BUILD_RESET";

export const DASHBOARD_DRAWER_HANDLE = "DASHBOARD_DRAWER_HANDLE";

export const CLEAR_CONFIGURATOR = "CLEAR_CONFIGURATOR";

export const RESET_CONTACT_US = "RESET_CONTACT_US";
