import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

const TextArea = (props) => {
  const { name, label, type, placeHolder } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const originalValue = useWatch({ name: name });

  return (
    <div className="form-group" style={{ clear: "both" }}>
      <label className="col-form-label float-start" htmlFor={name}>
        {label}
      </label>
      <div className="input-group">
        <textarea
          type={type}
          value={originalValue}
          {...register(name)}
          className="form-control"
          placeholder={placeHolder}
          cols="30"
          rows="5"
        />
      </div>
      <small className="text-danger mb-5">{errors[name]?.message}</small>
    </div>
  );
};

export default TextArea;
