import React from "react";
export const Banner = (props) => {
  return (
    <div>
      <div className="banner-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner-txt">
                <h1>{props.bannerHead}</h1>
                <p>{props.bannerText}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
