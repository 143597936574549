import React from "react";
import { Link } from "react-router-dom";
import marketPlaceProduct from "../../assets/images/marketplace-product-img-1.svg";
import marketPlaceProduct2 from "../../assets/images/marketplace-product-img-2.svg";
import marketPlaceProduct3 from "../../assets/images/marketplace-product-img-3.svg";
import marketPlaceProduct4 from "../../assets/images/marketplace-product-img-4.svg";
import marketPlaceProduct5 from "../../assets/images/marketplace-product-img-5.svg";
import marketPlaceProduct6 from "../../assets/images/marketplace-product-img-6.svg";
import marketPlaceProduct7 from "../../assets/images/marketplace-product-img-7.png";
import { routesPath } from "../../constants/routesPath";
import Fade from "react-reveal/Fade";

export const MarketPlaceSection = () => {
  return (
    <div>
      <section className="marketplace-wrap article-padd light-bg-color">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="marketplace-sec-head-wrap">
                <div className="marketplace-sec-head-text">
                  <p className="sec-title">MARKETPLACE</p>
                  <h2>Shop to find automation, components, and machines</h2>
                </div>
                <div className="marketplace-sec-head-btn">
                  <Link to={routesPath.marketPlace} className="green-btn">
                    Explore all products
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Fade left>
                <div className="row">
                  <div className="row">
                    <div className="col-lg-4">
                      <a
                        href=""
                        className="marketplace-product-single-wrap shadded-bg"
                      >
                        <div className="marketplace-product-single-wrap-img">
                          <img
                            src={marketPlaceProduct}
                            className="img-fluid"
                            alt="Pedestals & Workstations image"
                          />
                        </div>
                        <div className="marketplace-product-single-wrap-text">
                          <p>Pedestals & Workstations</p>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4">
                      <a
                        href=""
                        className="marketplace-product-single-wrap shadded-bg"
                      >
                        <div className="marketplace-product-single-wrap-img">
                          <img
                            src={marketPlaceProduct2}
                            className="img-fluid"
                            alt="Range Extender & 7th Axis image"
                          />
                        </div>
                        <div className="marketplace-product-single-wrap-text">
                          <p>Range Extender & 7th Axis</p>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4">
                      <a
                        href=""
                        className="marketplace-product-single-wrap shadded-bg"
                      >
                        <div className="marketplace-product-single-wrap-img">
                          <img
                            src={marketPlaceProduct3}
                            className="img-fluid"
                            alt="Milling & Drilling image"
                          />
                        </div>
                        <div className="marketplace-product-single-wrap-text">
                          <p>Milling & Drilling</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <a
                        href=""
                        className="marketplace-product-single-wrap shadded-bg"
                      >
                        <div className="marketplace-product-single-wrap-img">
                          <img
                            src={marketPlaceProduct4}
                            className="img-fluid"
                            alt="Machine Tending image"
                          />
                        </div>
                        <div className="marketplace-product-single-wrap-text">
                          <p>Machine Tending</p>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4">
                      <a
                        href=""
                        className="marketplace-product-single-wrap shadded-bg"
                      >
                        <div className="marketplace-product-single-wrap-img">
                          <img
                            src={marketPlaceProduct5}
                            className="img-fluid"
                            alt="Conveying image"
                          />
                        </div>
                        <div className="marketplace-product-single-wrap-text">
                          <p>Conveying</p>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4">
                      <a
                        href=""
                        className="marketplace-product-single-wrap shadded-bg"
                      >
                        <div className="marketplace-product-single-wrap-img">
                          <img
                            src={marketPlaceProduct6}
                            className="img-fluid"
                            alt="Jigs & Fixtures image"
                          />
                        </div>
                        <div className="marketplace-product-single-wrap-text">
                          <p>Jigs & Fixtures</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-lg-6">
              <Fade right>
                <div>
                  <a
                    href=""
                    className="marketplace-product-single-wrap single-img shadded-bg"
                  >
                    <div className="marketplace-product-single-wrap-img">
                      <img
                        src={marketPlaceProduct7}
                        className="img-fluid"
                        alt="Cartesian Palletizer image"
                      />
                    </div>
                    <div className="marketplace-product-single-wrap-text">
                      <p>Cartesian Palletizer</p>
                    </div>
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
