import React, { useEffect, useState } from "react";
import { Col, Divider, Row, Table } from "antd";
import jwt from "jwt-decode";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import * as quotesActionsCreator from "../../redux/quotation/actionsCreator";
import { useDispatch, useSelector } from "react-redux";
import { TailLoader } from "../ui/loader";
import { decryptedPrice } from "../../utils/decode";
import storageService from "../../services/storageService";
import "antd/dist/antd.css";

export const OrderDetail = (props) => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const fetchedOrders = useSelector(
    (state) => state.quotationReducer.orderById
  );
  const loading = useSelector((state) => state.quotationReducer.loading);
  const [quote, setQuote] = useState(null);
  const token = storageService.getJWTToken();
  const userData = jwt(token);

  function round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  }

  useEffect(() => {
    if (fetchedOrders) setQuote(fetchedOrders.quote);
  }, [fetchedOrders]);

  useEffect(() => {
    dispatch(quotesActionsCreator.getOrderById(match.params.id));
  }, []);

  const getTableDataSource = () => {
    const quotes = [];
    quote.subquotes.map((subQuote, index) => {
      subQuote.quote_items.map((item, index) => {
        quotes.push({
          item: (
            <img
              src={item.product.data.images[0].image.replace(
                "image/upload/",
                ""
              )}
              style={{
                height: "70px",
                width: "60px",
              }}
            />
          ),
          name: `${item.product.data.name}`,
          sku: `${item.product.data.sku}`,
          vendor: `${item.product.data.vendor.business_name}`,
          price: ` $${decryptedPrice(item.price) * parseInt(item.quantity)}`,
          quantity: `${item.quantity}`,
        });
      });
    });
    return quotes;
  };

  return (
    <>
      <div className="dashboard-head-wrap">
        <div className="dashboard-head-title">
          <h1 className="pl-5 ml-5">Order - {fetchedOrders?.id}</h1>
        </div>
      </div>
      <div className="dashboard-content-wrap">
        <div className="main-content-inner">
          <section className="bg-white pb-5">
            {quote ? (
              <>
                <div className="pt-3">
                  <Divider
                    className="px-2 justify-content-center"
                    style={{ fontWeight: "bold", fontSize: 36 }}
                  >
                    Order Information
                  </Divider>

                  <div className="row mx-auto">
                    <div className="col-12 col-lg-6">
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Order Id:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {fetchedOrders?.id}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Name:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {userData.username}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Email:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {userData.email}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Notes:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {fetchedOrders?.notes || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 text-end pt-3 pt-lg-0">
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Created at:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {new Date(fetchedOrders?.created).toUTCString()}
                        </span>
                      </div>

                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Down payment:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {fetchedOrders?.quote?.down_payment}%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-3 row mx-auto">
                  <>
                    <div>
                      <Table
                        dataSource={getTableDataSource()}
                        style={{
                          width: "100%",
                          maxHeight: "600px",
                          overflow: "scroll",
                        }}
                        pagination={false}
                      >
                        <Table.Column title="ITEM" dataIndex="item" />
                        <Table.Column title="NAME" dataIndex="name" />
                        <Table.Column title="SKU" dataIndex="sku" />
                        <Table.Column title="VENDOR" dataIndex="vendor" />
                        <Table.Column title="QUANTITY" dataIndex="quantity" />
                        <Table.Column title="PRICE" dataIndex="price" />
                      </Table>

                      <div>
                        {match.path.includes("order") && fetchedOrders && (
                          <div className="text-end pt-3 pt-lg-4">
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                paddingBottom: "4px",
                                color: "#4f4e4e",
                              }}
                            >
                              Total Amount:&nbsp;
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                ${round(fetchedOrders.total_amount)}
                              </span>
                            </div>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                paddingBottom: "4px",
                                color: "#4f4e4e",
                              }}
                            >
                              Paid Amount:&nbsp;
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                ${round(fetchedOrders.paid_amount)}
                              </span>
                            </div>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                paddingBottom: "4px",
                                color: "#4f4e4e",
                              }}
                            >
                              Pending Amount:&nbsp;
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                ${round(fetchedOrders.pending_amount)}
                              </span>
                            </div>
                          </div>
                        )}
                        {fetchedOrders && match.path.includes("order") && (
                          <div className="order-tracking">
                            <div className="track-linkes-head d-block">
                              <h5 className="my-3">Tracking</h5>
                            </div>

                            {fetchedOrders.suborders.map((subOrder) => {
                              return (
                                <div className="tracking-hyper-link my-2">
                                  <a
                                    href={subOrder.tracking_url}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    {subOrder.tracking_url}
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                </div>
              </>
            ) : (
              loading && (
                <div className="pt-5 text-center">
                  <TailLoader height={100} width={100} />
                </div>
              )
            )}
          </section>
        </div>
      </div>
    </>
  );
};
