import React from "react";
import threeDProductIcon from "../../assets/images/3d-product-icon.svg";
import threeDProductImg from "../../assets/images/3d-product-img.svg";
import featuresHome from "../../assets/images/features-home-main-image.svg";
import arIcon from "../../assets/images/ar-icon.svg";
import arImage from "../../assets/images/ar-image.svg";
import Fade from "react-reveal/Fade";

export const CapabilitiesSection = () => {
  return (
    <div style={{ background: "#FFFFFF" }}>
      <section className="features-home-wrap article-padd">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="features-home-head">
                <p className="sec-title">PLATFORM FEATURES</p>
                <h2>SaaS e-Commerce capabilities</h2>
                <p>
                  The industry’s first-ever no-code 3D eCommerce platform built
                  specifically for managing and processing products used to
                  build automated systems and machines.
                </p>
              </div>
            </div>
          </div>
          <Fade bottom>
            <div className="features-home-inner animate__animated animate__fadeInUp wow">
              <div className="row">
                <div className="col-lg-4">
                  <div className="features-home-configurator">
                    <div className="features-home-configurator-icon">
                      <img
                        src={threeDProductIcon}
                        className="img-fluid"
                        alt="3d product configurator icon"
                      />
                    </div>
                    <div className="features-home-configurator-text">
                      <p className="sec-title">Real-time 3D rendering</p>
                      <h3>3D Product Configurator</h3>
                    </div>
                    <div className="features-home-configurator-img">
                      <img
                        src={threeDProductImg}
                        className="img-fluid"
                        alt="3d product configurator image"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="features-home-main-image">
                    <img
                      src={featuresHome}
                      className="img-fluid"
                      alt="3d e-commerce platform featured image"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="features-home-ar">
                    <div className="features-home-ar-icon">
                      <img
                        src={arIcon}
                        className="img-fluid"
                        alt="augmented reality icon"
                      />
                    </div>
                    <div className="features-home-ar-text">
                      <p className="sec-title">Real physical world</p>
                      <h3>Augmented Reality</h3>
                    </div>
                    <div className="features-home-ar-img">
                      <img
                        src={arImage}
                        className="img-fluid"
                        alt="augmented reality image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
    </div>
  );
};
