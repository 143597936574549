import axios from "axios";
const cloudinaryPath = "https://res.cloudinary.com/botics-ai/";
/*

return base url
*/

//create axios instance to interact over network
let BaseApi = axios.create({
  // baseURL: "https://api.botics.ai",
  // baseURL: "https://boticsapp.herokuapp.com",
  baseURL: "https://boticsapp-stg.herokuapp.com/",
  withCredentials: false,
});

export const asset = (name) => cloudinaryPath + name;

// export const baseUrl = "https://api.botics.ai";
// export const baseUrl = "https://boticsapp.herokuapp.com";
export const baseUrl = "https://boticsapp-stg.herokuapp.com/";

export const Api = () => {
  return BaseApi;
};
