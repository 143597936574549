import _ from "lodash";
import quoteStatus from "../constants/quoteStatus";
import { decryptedPrice } from "../utils/decode";

// import  from 'html-react-parser'

//quotes parser

/*

Service file acting as DTO for traansorming responses required in accordance with ui components..

@ params: apipyload,
@ return : table , dropdown




*/
export const parseQuotesTable = (
  tableData,
  getStatus,
  getQuoteItems,
  getChat
  // getTransitionsDropdown,
) => {
  let parsedTable = {};

  const calculatePrice = (subQuotes) => {
    let price = 0;
    subQuotes.map((subQuote) => {
      price += decryptedPrice(subQuote.total_price);
    });
    return price;
  };

  parsedTable.tableHeader = [
    "#",
    "PRICE",
    "STATUS",
    "ITEMS",
    "EXPIRY",
    "ACTIONS",
  ];
  parsedTable.tableData = [];
  tableData.map((quote) => {
    if (!quoteStatus.hideQuotes.includes(quote.status)) {
      parsedTable.tableData.push({
        "#": quote.id,
        STATUS: getStatus(quote),
        PRICE:
          quote.status === "SENT_TO_CUSTOMER" ||
          quote.status === "VENDOR_TO_CUSTOMER"
            ? "$" + calculatePrice(quote.subquotes)
            : "-",
        ITEMS: getQuoteItems(quote),
        EXPIRY: new Date(quote.expires_at).toUTCString(),
        ACTIONS: getChat(quote),
      });
    }
  });

  return parsedTable;
};

//parse orders table

export const parseOrdersTable = (
  tableData,
  getOrderItems,
  getOrdersActions
) => {
  let parsedTable = {};

  parsedTable.tableHeader = [
    "#",
    "ITEMS",
    "Total_Amount",
    "Paid_Amount",
    "Pending_Amount",
    "ACTIONS",
  ];
  parsedTable.tableData = [];
  tableData.map((order) => {
    parsedTable.tableData.push({
      "#": order.id,
      ITEMS: getOrderItems(order),
      Total_Amount: "$" + order.total_amount,
      Paid_Amount: "$" + parseFloat(order.paid_amount).toFixed(2),
      Pending_Amount: "$" + parseFloat(order.pending_amount).toFixed(2),
      ACTIONS: getOrdersActions(order),
    });
  });

  return parsedTable;
};

const parseDataForConfigurator = (data) => {
  let componentSubcategory = [];
  componentSubcategory = data?.map((dat) => dat.component_subcategory);
  componentSubcategory = _.uniqBy(componentSubcategory, "id");

  let componentCategory = {};
  if (componentSubcategory?.length) {
    for (let subCategory of componentSubcategory) {
      componentCategory[subCategory?.component_category.id] = {
        id: subCategory?.component_category?.id,
        name: subCategory?.component_category?.name,
        sub_categories:
          componentCategory[subCategory?.component_category.id]
            ?.sub_categories || [],
      };
      componentCategory[subCategory?.component_category.id].sub_categories.push(
        {
          id: subCategory.id,
          name: subCategory.name,
          components: [],
        }
      );
    }
    Object.keys(componentCategory).map((key) => {
      componentCategory[key].sub_categories = componentCategory[
        key
      ].sub_categories.map((obj) => {
        obj.components = data
          ?.map((comp) => {
            if (comp.component_subcategory.id === obj.id) return comp;
          })
          .filter(Boolean);
        return obj;
      });
    });
  }
  return componentCategory;
};

export default {
  parseQuotesTable,
  parseOrdersTable,
  parseDataForConfigurator,
};
