import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionsCreator from "../../redux/actionsCreator";
import { VerticleStepper } from "../../components/ui/stepper";
import { ConfiguratorSidebar } from "../../components/ui/configuratorSidebar";
import { ProductCanvas } from "../../components/productCanvas/productCanvas";
import { ConfiguratorModal } from "../../components/ui/configuratorModal";
import { SideDrawer } from "../../components/ui/sideDrawer";
import { SaveBuildModal } from "../../components/ui/saveBuildModal";
import { Divider } from "@material-ui/core";
import generatorPlaceHolderImage from "../../assets/images/generator-placeholder-img.svg";
import { toast } from "react-toastify";
import dataService from "../../services/dataService";
import { ProductModal } from "../../components/productModal/productModal";

export const Configurator = () => {
  let dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [cardsData, setCardsData] = useState(null);
  const [selectedProductDetail, setSelectedProductDetal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [removedComponent, setRemovedComponent] = useState([]);
  const [initiateSaveBuildModal, setInitiateSaveBuildModal] = useState(false);
  const [buildName, setBuildName] = useState("");
  const [build, setBuild] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [view, setView] = useState("2D");
  const [collapsedState, setCollapsedState] = useState({
    categories: [],
    subCategories: [],
    components: [],
  });

  const [parsedData, setParsedData] = useState(null);

  const [verticleSteps, setVerticleSteps] = useState({
    Select_Application: null,
    Select_Equipment: null,
    Select_Model: null,
    Select_Component: null,
  });

  const applicationsResponse = useSelector(
    (state) => state.apiReducer.applications
  );
  const equipmentsResponse = useSelector(
    (state) => state.apiReducer.equipments
  );
  const loading = useSelector((state) => state.apiReducer.loading);
  const postBuildloading = useSelector(
    (state) => state.apiReducer.postBuildLoading
  );
  const loadBuildLoading = useSelector(
    (state) => state.apiReducer.loadBuildLoading
  );
  const model = useSelector((state) => state.apiReducer.response);
  const buildSaveResponse = useSelector(
    (state) => state.apiReducer.buildSaveResponse
  );
  const buildSaveError = useSelector(
    (state) => state.apiReducer.buildSaveError
  );

  const loadedResponseError = useSelector(
    (state) => state.apiReducer.loadedResponseError
  );

  useEffect(() => {
    if (buildSaveResponse) {
      toast.success("Build Posted!");
      setInitiateSaveBuildModal(false);
    }
  }, [buildSaveResponse]);

  useEffect(() => {
    if (buildSaveError) {
      toast.error("Failed To Save Build!");
    }
  }, [buildSaveError]);

  useEffect(() => {
    if (model) {
      setInitiateSaveBuildModal(false);
    }
  }, [model]);

  useEffect(() => {
    dispatch(actionsCreator.getApplication());
  }, [dispatch]);

  useEffect(() => {
    if (activeStep === 0) {
      setCardsData(applicationsResponse);
    }
    if (activeStep === 1) {
      setCardsData(equipmentsResponse);
    }
    if (activeStep === 2) {
      setCardsData(model);
    }
    if (activeStep === 3) {
      setCardsData(model);
      let result = dataService.parseDataForConfigurator(model);
      setParsedData(result);
    }
  }, [applicationsResponse, equipmentsResponse, model]);

  useEffect(() => {
    if (activeStep < 3) {
      if (activeStep < 3)
        setCollapsedState({
          categories: [],
          subCategories: [],
          components: [],
        });
      setAttachments([]);
      let tempVSteps = { ...verticleSteps };
      tempVSteps["Select_Component"] = [];
      setVerticleSteps(tempVSteps);
    }
    if (activeStep === 0) {
      getApplication();
    }
    if (activeStep === 1) {
      getEquipment();
    }
    if (activeStep === 2) {
      getModel();
    }
    if (activeStep === 3) {
      let queryString = createQueryString();
      dispatch(
        actionsCreator.getComponentsFilterProducts({ queryString: queryString })
      );
    }
  }, [activeStep, dispatch]);

  useEffect(() => {
    if (buildSaveError) {
      toast.error("Failed To Post Build!");
    }
  }, [buildSaveError]);

  useEffect(() => {
    return () => {
      dispatch(actionsCreator.clearConfiguratorData());
    };
  }, []);

  const createQueryString = useCallback(() => {
    var query = new URLSearchParams();
    if (verticleSteps["Select_Application"]) {
      query.append("application__id", verticleSteps["Select_Application"].id);
    }
    if (verticleSteps["Select_Equipment"]) {
      query.append("equipment__id", verticleSteps["Select_Equipment"].id);
    }
    if (verticleSteps["Select_Model"]) {
      query.delete("equipment__id");

      query.append("model__id", verticleSteps["Select_Model"].id);
    }
    return query.toString();
  }, [verticleSteps]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getApplication = useCallback(() => {
    dispatch(actionsCreator.getApplication());
  }, [dispatch]);

  const getEquipment = useCallback(() => {
    dispatch(actionsCreator.getEquipment());
  }, [dispatch]);

  const getModel = useCallback(() => {
    const queryString = createQueryString();
    dispatch(
      actionsCreator.getModelsFilterProducts({ queryString: queryString })
    );
  }, [dispatch, createQueryString]);

  const updateStepper = (key, label) => {
    verticleSteps[label]?.name && setActiveStep(key);
    let tempSteps = { ...verticleSteps };

    Object.keys(verticleSteps).map((step, index) => {
      if (index >= key) {
        tempSteps[step] = null;
      }
    });

    setVerticleSteps(tempSteps);
  };

  const updateVerticalSteps = (data) => {
    let tempSteps = { ...verticleSteps };

    Object.keys(verticleSteps).map((step, index) => {
      if (index > activeStep) {
        tempSteps[step] = null;
      } else if (index === activeStep) {
        tempSteps[step] = data;
      }
    });

    handleNext();

    setVerticleSteps(tempSteps);
  };

  const toggleInitiateSaveBuildModal = () => {
    setInitiateSaveBuildModal(!initiateSaveBuildModal);
  };

  const getProductDetail = (product) => {
    if (product["type"] === "MODEL" || product["type"] === " COMPONENT") {
    } else {
    }
    setModalOpen(true);
    setSelectedProductDetal(product);
  };

  const handleComponent = (productId) => {
    let currentCompoentns = [...attachments];
    let tempSteps = { ...verticleSteps };
    let tempDltComponents = [...removedComponent];

    let index = attachments.indexOf(productId.toString());

    if (index >= 0) {
      currentCompoentns.splice(index, 1);
      tempDltComponents.push(productId.toString());
      tempDltComponents = [...new Set(tempDltComponents)];
    } else {
      if (removedComponent.indexOf(productId.toString()) >= 0) {
        tempDltComponents.splice(
          removedComponent.indexOf(productId.toString()),
          1
        );
      }
      setRemovedComponent([]);
      currentCompoentns.push(productId.toString());
      currentCompoentns = [...new Set(currentCompoentns)];
    }

    tempSteps["Select_Component"] = currentCompoentns;
    setRemovedComponent(tempDltComponents);
    setVerticleSteps(tempSteps);
    setAttachments(currentCompoentns);
  };

  const saveBuild = () => {
    const userBuild = {
      title: buildName,
      application: verticleSteps["Select_Application"].id,
      equipment: verticleSteps["Select_Equipment"].id,
      model: verticleSteps["Select_Model"].id,
      component: verticleSteps["Select_Component"] || [],
      id: build?.id,
    };
    build
      ? dispatch(actionsCreator.updateConfiguratorBuilds(userBuild))
      : dispatch(actionsCreator.postConfiguratorBuilds(userBuild));
  };

  const loadBuild = (build) => {
    setAttachments(build.component);
    setBuildsFilter(build);
    setOpenDrawer(false);
  };

  const setBuildsFilter = (build) => {
    let tempSteps = { ...verticleSteps };
    setBuild(build);
    Object.keys(build).map((key) => {
      if (
        key === "application" ||
        key === "equipment" ||
        key === "model" ||
        key === "component"
      ) {
        if (key === "component") {
          let tempAttachments = [...attachments];

          let tempState = { ...collapsedState };
          tempSteps["Select_Component"] = [];
          build[key].map((comp) => {
            tempSteps["Select_Component"].push(comp.id);
            tempAttachments.push(comp.id.toString());
            tempState.subCategories.push(comp["component_subcategory"].id);
            tempState.categories.push(
              comp["component_subcategory"].component_category
            );
          });

          setCollapsedState(tempState);
          setAttachments(tempAttachments);
        } else {
          tempSteps[
            `Select_${key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}`
          ] = { type: key + "Id", id: build[key]?.id, name: build[key]?.name };
        }
      }
    });
    setActiveStep(3);
    setVerticleSteps(tempSteps);
  };

  const resetAll = () => {
    setActiveStep(0);
    setBuild(null);
    setVerticleSteps({
      Select_Application: null,
      Select_Equipment: null,
      Select_Model: null,
      Select_Component: null,
    });
    setCollapsedState({
      categories: [],
      subCategories: [],
      components: [],
    });
  };

  const deleteBuild = (id) => {
    dispatch(actionsCreator.deleteConfiguratorBuilds(id));
  };

  const handleCollapsedStates = (collapseData) => {
    let tempState = { ...collapsedState };

    if (collapseData.type === "category") {
      if (tempState.categories.includes(collapseData.id)) {
        let found = tempState.categories.findIndex(
          (cat) => cat.id === collapseData.id
        );
        tempState.categories.splice(found, 1);
      } else {
        tempState.categories.push(collapseData.id);
      }
    }

    if (collapseData.type === "subCategory") {
      if (tempState.subCategories.includes(collapseData.id)) {
        let found = tempState.subCategories.findIndex(
          (cat) => cat.id === collapseData.id
        );
        tempState.subCategories.splice(found, 1);
      } else {
        tempState.subCategories.push(collapseData.id);
      }
    }

    if (collapseData.type === "component") {
      let tempAttachments = [...attachments];
      if (tempAttachments.includes(collapseData.id.toString())) {
        let attachIndex = tempAttachments.findIndex(
          (att) => att.id === collapseData.id
        );
        tempAttachments.splice(attachIndex, 1);
        setAttachments(tempAttachments);
      } else {
        tempAttachments.push(collapseData.id.toString());
        setAttachments(tempAttachments);
      }
      if (tempState.components.includes(collapseData.id)) {
        let found = tempState.components.findIndex(
          (cat) => cat.id === collapseData.id
        );
        tempState.components.splice(found, 1);
      } else {
        tempState.components.push(collapseData.id);
      }
    }
    setCollapsedState(tempState);
  };

  const handleModalOpening = () => {
    setSelectedProductDetal(null);
    setModalOpen(null);
  };

  return (
    <>
      <div className="dashboard-head-wrap-build-page">
        <div className="dashboard-head-title">
          <h1> Build Your Robot</h1>
        </div>
        <div className="main-content-head-btns">
          <button
            className="btn-blue-bg"
            aria-label="open drawer"
            onClick={() => setOpenDrawer(true)}
          >
            Load
          </button>
          <button
            className="btn-green-bg"
            style={
              !verticleSteps["Select_Model"]
                ? { opacity: "0.6" }
                : { opacity: "1" }
            }
            disabled={!verticleSteps["Select_Model"]}
            onClick={() => setInitiateSaveBuildModal(true)}
          >
            {build ? "Update" : "Save"}
          </button>
          <button className="bordered-btn" onClick={resetAll}>
            New
          </button>
        </div>
      </div>
      <div className="main-content-body-wrap">
        <div className="container-fluid config-screen">
          <div className="row">
            <div className="col pt-5 mt-5">
              {initiateSaveBuildModal && (
                <SaveBuildModal
                  toggle={toggleInitiateSaveBuildModal}
                  modal={initiateSaveBuildModal}
                  saveBuild={saveBuild}
                  loading={postBuildloading}
                  setBuildName={setBuildName}
                  buildName={buildName}
                />
              )}
              <VerticleStepper
                activeStep={activeStep}
                updateStepper={updateStepper}
                steps={verticleSteps}
              />
            </div>
          </div>
          <div className="row" style={{ height: "70vh" }}>
            <div className="col-12 col-md-3 pt-3">
              <div className="text-center">
                {build && (
                  <div>
                    <h5
                      style={{ fontWeight: "bold", fontSize: "18px" }}
                      className="py-2"
                    >
                      {build.title}
                    </h5>
                    <Divider />
                  </div>
                )}
                {openDrawer && (
                  <SideDrawer
                    activeFiltres={verticleSteps}
                    setInitiateSaveBuild={setInitiateSaveBuildModal}
                    loading={loadBuildLoading}
                    openDrawer={openDrawer}
                    loadBuild={loadBuild}
                    setOpenDrawer={setOpenDrawer}
                    deleteBuild={deleteBuild}
                    loadedResponseError={loadedResponseError}
                  />
                )}
              </div>

              <ConfiguratorSidebar
                equipments={equipmentsResponse}
                activeStep={activeStep}
                getProductDetail={getProductDetail}
                handleComponent={handleComponent}
                attachments={attachments}
                loading={loading}
                updateVerticalSteps={updateVerticalSteps}
                products={cardsData}
                verticleSteps={verticleSteps}
                parsedData={parsedData}
                collapsedState={collapsedState}
                handleCollapsedStates={handleCollapsedStates}
              />

              {selectedProductDetail?.type === "MODEL" ||
              selectedProductDetail?.type === "COMPONENT" ? (
                <ProductModal
                  selectedProduct={selectedProductDetail}
                  isNotCartFunctionality={true}
                  view={view}
                  setView={setView}
                  setSelectedProduct={handleModalOpening}
                  // view={view}
                  // setView={setView}
                />
              ) : (
                selectedProductDetail && (
                  <ConfiguratorModal
                    product={selectedProductDetail}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                  />
                )
              )}
            </div>
            <div className="col-12 col-md-9 pt-3">
              {activeStep === 3 ? (
                <ProductCanvas
                  removedComponent={removedComponent}
                  components={attachments}
                />
              ) : (
                <div className="main-content-apps-view">
                  <div className="main-content-apps-view-inner">
                    <div className="main-content-apps-view-inner-img">
                      <img
                        src={generatorPlaceHolderImage}
                        className="img-fluid"
                        alt="generator app view placeholder image"
                      />
                    </div>
                    <div className="main-content-apps-view-inner-text">
                      <p>
                        To Start Building Your Robot, Please Select an
                        Application!
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
