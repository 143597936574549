import React, { useEffect } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

export const AddressForm = (props) => {
  const selectCountry = (val) => {
    props.setValues(
      {
        ...props.values,
        [`${props.type}_country`]: val,
        [`${props.type}_region`]: "",
      },
      true
    );
  };
  const selectRegion = (val) => {
    props.setValues({ ...props.values, [`${props.type}_region`]: val }, true);
  };

  useEffect(() => {
    if (props.sameAddress) {
      props.setValues({
        ...props.values,
        shipping_city: props.values.billing_city,
        shipping_region: props.values.billing_region,
        shipping_country: props.values.billing_country,
        shipping_postalCode: props.values.billing_postalCode,
        shipping_streetAddress: props.values.billing_streetAddress,
      });
    } else {
      props.setValues({
        ...props.values,
        shipping_city: "",
        shipping_region: "",
        shipping_country: "",
        shipping_postalCode: "",
        shipping_streetAddress: "",
      });
    }
  }, [props.sameAddress]);
  return (
    <>
      <div className="shipping-form">
        <div
          className={
            props.touched[`${props.type}_streetAddress`] &&
            props.errors[`${props.type}_streetAddress`]
              ? "form-outline-danger"
              : "form-outline"
          }
        >
          <input
            type="text"
            className="form-control"
            id="validationCustom03"
            placeholder="Street Address"
            name={
              props.sameAddress
                ? "billing_streetAddress"
                : `${props.type}_streetAddress`
            }
            value={
              props.sameAddress
                ? props.values.billing_streetAddress
                : props.values[`${props.type}_streetAddress`]
            }
            onChange={props.handleChange}
          />
        </div>
        <div className="contact_details">
          <div
            className={
              props.touched[`${props.type}_postalCode`] &&
              props.errors[`${props.type}_postalCode`]
                ? "form-outline-danger form-outline"
                : "form-outline"
            }
            style={{ paddingRight: "5px" }}
          >
            <input
              type="text"
              className="form-control"
              id="validationCustom04"
              placeholder="Postcode/Zip"
              name={
                props.sameAddress
                  ? "billing_postalCode"
                  : `${props.type}_postalCode`
              }
              value={
                props.sameAddress
                  ? props.values.billing_postalCode
                  : props.values[`${props.type}_postalCode`]
              }
              onChange={props.handleChange}
            />
          </div>
          <div className="form-outline">
            <CountryDropdown
              className={
                props.touched[`${props.type}_country`] &&
                props.errors[`${props.type}_country`]
                  ? "border border-danger form-control"
                  : "form-control"
              }
              style={{ width: "100%" }}
              name={
                props.sameAddress ? "billing_country" : `${props.type}_country`
              }
              value={
                props.sameAddress
                  ? props.values.billing_country
                  : props.values[`${props.type}_country`]
              }
              onChange={(val) => selectCountry(val)}
            />
          </div>
        </div>

        <div className="contact_details">
          <div
            className={
              props.touched[`${props.type}_region`] &&
              props.errors[`${props.type}_region`]
                ? "form-outline-danger form-outline"
                : "form-outline"
            }
          >
            <RegionDropdown
              defaultOptionLabel="Select region"
              className="form-control"
              disableWhenEmpty={true}
              style={{ width: "98%" }}
              name={
                props.sameAddress ? "billing_region" : `${props.type}_region`
              }
              country={
                props.sameAddress
                  ? props.values.billing_country
                  : props.values[`${props.type}_country`]
              }
              value={
                props.sameAddress
                  ? props.values.billing_region
                  : props.values[`${props.type}_region`]
              }
              onChange={(val) => selectRegion(val)}
            />
          </div>
          <div
            className={
              props.touched[`${props.type}_city`] &&
              props.errors[`${props.type}_city`]
                ? "form-outline-danger form-outline"
                : "form-outline"
            }
          >
            <input
              type="text"
              className="form-control"
              id="validationCustom05"
              placeholder="City"
              name={props.sameAddress ? "billing_city" : `${props.type}_city`}
              value={
                props.sameAddress
                  ? props.values.billing_city
                  : props.values[`${props.type}_city`]
              }
              onChange={props.handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};
