import * as Yup from "yup";

// yup schema for form validation
export const billingAddressValidationSchema = Yup.object({
  billing_streetAddress: Yup.string().required("Required"),
  billing_postalCode: Yup.string().required("Required"),
  billing_region: Yup.string().required("Required"),
  billing_country: Yup.string().required("Required"),
  billing_city: Yup.string().required("Required"),
  shipping_streetAddress: Yup.string().required("Required"),
  shipping_postalCode: Yup.string().required("Required"),
  shipping_region: Yup.string().required("Required"),
  shipping_country: Yup.string().required("Required"),
  shipping_city: Yup.string().required("Required"),
});
