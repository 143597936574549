import React, { useEffect, useRef, useState } from "react";
import * as actionCreators from "../../redux/actionsCreator";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userValidationSchema from "./userValidationSchema";
import { toast } from "react-toastify";
import { routesPath } from "../../constants/routesPath";
import { Link } from "react-router-dom";
import { apiUrls } from "../../constants/apiUrls";

export const SignupContainer = () => {
  const passRef = useRef(null);
  const dispatch = useDispatch();

  const registerResponse = useSelector(
    (state) => state.authReducer.registerResponse
  );
  const error = useSelector((state) => state.authReducer.error);

  const userInitialValues = {
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    business_name: "",
    phone_number: "+1",
    tax_id: "",
    password: "",
    confirm_password: "",
  };
  const history = useHistory();
  const registerData = (data) => {
    dispatch(actionCreators.registerUser(data));
  };

  useEffect(() => {
    if (registerResponse) {
      toast.success("Verfication Email Sent Successfully!");
      history.push(routesPath.login);
    } else if (error) {
      toast.error(JSON.stringify(error.response?.data));
    }
  }, [registerResponse, error, dispatch, history]);

  useEffect(() => {
    return () => {
      dispatch(actionCreators.resetRegisteration());
    };
  }, []);

  return (
    <>
      <main>
        <section className="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Formik
                  validateOnChange={true}
                  initialValues={userInitialValues}
                  validationSchema={userValidationSchema}
                  onSubmit={(values) => {
                    registerData(values);
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    isValid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="needs-validation signup">
                        <div className="heading_txt_login">
                          <h1>Sign Up</h1>
                        </div>
                        <div
                          className={
                            errors.username && touched.username
                              ? "form-outline-danger form-outline"
                              : "form-outline"
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="User Name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            name="username"
                            required
                          />
                          {errors.username && touched.username && (
                            <small className="text-danger">
                              {errors.username}
                            </small>
                          )}
                        </div>
                        <div
                          className={
                            errors.first_name && touched.first_name
                              ? "form-outline form-outline-danger"
                              : "form-outline"
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="First Name"
                            onChange={handleChange}
                            value={values.first_name}
                            onBlur={handleBlur}
                            name="first_name"
                            required
                          />
                          {errors.first_name && touched.first_name && (
                            <small className="text-danger">
                              {errors.first_name}
                            </small>
                          )}
                        </div>

                        <div
                          className={
                            errors.last_name && touched.last_name
                              ? "form-outline form-outline-danger"
                              : "form-outline"
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Last Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.last_name}
                            name="last_name"
                            required
                          />
                          {errors.last_name && touched.last_name && (
                            <small className="text-danger">
                              {errors.last_name}
                            </small>
                          )}
                        </div>

                        <div
                          className={
                            errors.email && touched.email
                              ? "form-outline form-outline-danger"
                              : "form-outline"
                          }
                        >
                          <input
                            className="form-control"
                            id="validationCustom04"
                            placeholder="Email Address"
                            type="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            name="email"
                            required
                          />
                          {errors.email && touched.email && (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          )}
                        </div>

                        <div
                          className={
                            errors.business_name && touched.business_name
                              ? "form-outline form-outline-danger"
                              : "form-outline"
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Business Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.business_name}
                            name="business_name"
                            required
                          />
                          {errors.business_name && touched.business_name && (
                            <small className="text-danger">
                              {errors.business_name}
                            </small>
                          )}
                        </div>

                        <div
                          className={
                            errors.phone_number && touched.phone_number
                              ? "form-outline form-outline-danger"
                              : "form-outline"
                          }
                        >
                          <input
                            className="form-control"
                            id="validationCustom04"
                            placeholder="Phone Number"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone_number}
                            name="phone_number"
                            required
                          />
                          {errors.phone_number && touched.phone_number && (
                            <small className="text-danger">
                              {errors.phone_number}
                            </small>
                          )}
                        </div>
                        <div
                          className={
                            errors.tax_id && touched.tax_id
                              ? "form-outline form-outline-danger"
                              : "form-outline"
                          }
                        >
                          <input
                            className="form-control"
                            id="validationCustom04"
                            placeholder="Tax Id"
                            type="number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tax_id}
                            name="tax_id"
                            required
                          />
                          {errors.tax_id && touched.tax_id && (
                            <small className="text-danger">
                              {errors.tax_id}
                            </small>
                          )}
                        </div>
                        <div
                          className={
                            errors.password && touched.password
                              ? "form-outline form-outline-danger"
                              : "form-outline"
                          }
                        >
                          <input
                            className="form-control"
                            id="validationCustom02"
                            placeholder="Password (min 8 characters)"
                            type="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            name="password"
                            ref={passRef}
                            required
                          />
                          {errors.password && touched.password && (
                            <small className="text-danger">
                              {errors.password}
                            </small>
                          )}
                        </div>
                        <div
                          className={
                            errors.confirm_password && touched.confirm_password
                              ? "form-outline form-outline-danger"
                              : "form-outline"
                          }
                        >
                          <input
                            className="form-control"
                            id="validationCustom03"
                            placeholder="Confirm Password"
                            type="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirm_password}
                            name="confirm_password"
                            required
                          />
                          {errors.confirm_password &&
                            touched.confirm_password && (
                              <small className="text-danger">
                                {errors.confirm_password}
                              </small>
                            )}
                        </div>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={!isValid}
                        >
                          Signup
                        </button>
                        <hr />
                        <Link
                          style={{ textDecoration: "none" }}
                          to={routesPath.login}
                        >
                          <div className="create-acc">
                            <p>
                              Already have an account?
                              <a href="login.html"> Login</a>
                            </p>
                          </div>
                        </Link>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
