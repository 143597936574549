import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import store from "./redux/store"; // store
import { Provider } from "react-redux";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./assets/fonts/MarkPro-Medium.woff2";
import "./assets/fonts/MarkPro-Medium.woff";
import "./assets/fonts/MarkPro.woff";
import "./assets/fonts/MarkPro.woff2";
import "./assets/fonts/CarosBold.woff";
import "./assets/fonts/MarkPro-Book.woff";
import "./assets/fonts/MarkPro-Bold.woff";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/style.css";
import "antd/dist/antd.css";

//bootstraped entry for project with redux store as provider
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
