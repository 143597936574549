import React from "react";
import memberImageOne from "../../assets/images/member-img-1.png";
import memberImageTwo from "../../assets/images/member-img-2.png";
import memberImageThree from "../../assets/images/member-img-3.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
export const RecentArticles = () => {
  return (
    <div>
      <section className="recent-articles-outer-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="recent-articles-head">
                <h2>Nice to meet you</h2>
                <p>
                  Quite a few of us like to talk and write about what we do.
                  Check out some recent articles, presentations and videos to
                  get a feel for what we’re all about.
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="recent-article-inner-wrap owl-carousel">
                <Carousel
                  centerMode
                  centerSlidePercentage={33}
                  infiniteLoop
                  showArrows={false}
                  showIndicators={false}
                  showThumbs={false}
                  showStatus={false}
                  className="bg-light border-0"
                >
                  <div className="recent-article-single-wrap p-3">
                    <div className="recent-article-single">
                      <div className="recent-article-single-head">
                        <div className="recent-article-single-name">
                          <h3>Mike Tomasz</h3>
                          <p>Director of Support</p>
                        </div>
                        <div className="recent-article-single-img">
                          <img
                            src={memberImageOne}
                            className="img-fluid"
                            alt="company member image"
                          />
                        </div>
                      </div>
                      <div className="recent-article-single-body">
                        <p>
                          Botics will provide every product right the first
                          time. This makes them good listeners and open to
                          client feedback. Talks and cares deeply about making
                          tech more accessible and open.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="recent-article-single-wrap p-3">
                    <div className="recent-article-single">
                      <div className="recent-article-single-head">
                        <div className="recent-article-single-name">
                          <h3>Zhenya Rynzhuk</h3>
                          <p>Support Community Manager</p>
                        </div>
                        <div className="recent-article-single-img">
                          <img
                            src={memberImageTwo}
                            className="img-fluid"
                            alt="company member image"
                          />
                        </div>
                      </div>
                      <div className="recent-article-single-body">
                        <p>
                          Botics helps some of the largest manufacturers develop
                          and run reliable production systems. This takes
                          industrial-grade hardware that is designed to last.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="recent-article-single-wrap p-3">
                    <div className="recent-article-single">
                      <div className="recent-article-single-head">
                        <div className="recent-article-single-name">
                          <h3>Jason Reynolds</h3>
                          <p>VP of Developer Experience</p>
                        </div>
                        <div className="recent-article-single-img">
                          <img
                            src={memberImageThree}
                            className="img-fluid"
                            alt="company member image"
                          />
                        </div>
                      </div>
                      <div className="recent-article-single-body">
                        <p>
                          From creating plug & play industrial automation
                          components, to enabling users to check out with their
                          credit card for next day delivery, Botics believe in
                          consumer-grade simplicity.
                        </p>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
