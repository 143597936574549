import * as actions from "./actionTypes";

export const InitiateCartToQuote = (data) => ({
  type: actions.CART_TO_QUOTE,
  payload: data || {},
});

export const getQoutes = (data) => ({
  type: actions.GET_QUOTES,
  payload: data || {},
});

export const getQouteById = (data) => ({
  type: actions.GET_QUOTE_BY_ID,
  payload: data || {},
});

export const getOrderById = (data) => ({
  type: actions.GET_ORDER_BY_ID,
  payload: data || {},
});

export const setQuoteStatus = (data) => ({
  type: actions.QUOTE_STATUS,
  payload: data,
});

export const closeQuote = (data) => ({
  type: actions.CLOSE_QUOTE,
  payload: data,
});

export const quoteToOrder = (data) => ({
  type: actions.QUOTE_TO_ORDER,
  payload: data,
});

export const getOrders = (data) => ({
  type: actions.GET_ORDERS,
  payload: data,
});

export const resetQuotes = (data) => ({
  type: actions.RESET_QUOTATIONS,
  payload: data,
});

export const chargePayment = (data) => ({
  type: actions.CHARGE_PAYMENT,
  payload: data,
});

export const resetChargePayment = (data) => ({
  type: actions.RESET_CHARGE_PAYMENT,
  payload: data,
});

export const downPaymentConfirmed = (data) => ({
  type: actions.DOWN_PAYMENT_CONFIRMED,
  payload: data,
});

export const resetCartToQuote = (data) => ({
  type: actions.RESET_CART_TO_QUOTE,
  payload: data,
});
