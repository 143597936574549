import React, { useEffect } from "react";
import { Row } from "reactstrap";
import noImg from "../../assets/img/noImg.jpg";

export const ProductShowCase = React.memo((props) => {
  useEffect(() => {
    if (props.selectedProduct && props.selectedProduct.data.images) {
      props.selectedProduct.images = props.selectedProduct.data.images;
    }
  }, [props.selectedProduct]);

  return (
    <Row>
      {props.products?.length &&
        props.products?.map((product) => (
          <div
            className="col-md-6 col-lg-3"
            key={product.id}
            onClick={() => props.setSelectedProduct(product)}
          >
            <div className="items">
              <a>
                <div
                  className="product-img"
                  style={{
                    background: `url(${
                      product.images?.length
                        ? product.images
                            .find((image) => image.is_primary === false)
                            ?.image.replace("image/upload/", "")
                        : product.data?.images?.length
                        ? product.data.images
                            .find((image) => image.is_primary === false)
                            ?.image.replace("image/upload/", "")
                        : noImg
                    }) no-repeat center`,
                    backgroundSize: "contain",
                  }}
                ></div>
                <hr />
                <div className="product-details pb-2 ">
                  <h3>{product.name || product?.data?.name}</h3>
                  <p>{product.sku || product?.data?.sku}</p>
                </div>
              </a>
            </div>
          </div>
        ))}
    </Row>
  );
});
