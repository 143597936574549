import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as quoteActionsCreator from "../../redux/quotation/actionsCreator";
import { Container, Row, Col } from "reactstrap";
import { DataTable } from "../../components/dataTable/dataTable";
import { DetailModal } from "../../components/detailModal/detailModal";
import { useHistory } from "react-router-dom";
import { PubnubChat } from "../../components/chat/chat";
import * as cartActionsCreator from "../../redux/cart/actionsCreator";
import InitializePubnub from "../../components/pubnub/initializePubnub";
import dataService from "../../services/dataService";
import Badge from "reactstrap/lib/Badge";
import Button from "reactstrap/lib/Button";
import quoteStatus from "../../constants/quoteStatus";
import { QuoteDetail } from "../../components/quoteDetail/quoteDetail";
import { routesPath } from "../../constants/routesPath";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

export const Quotation = () => {
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  let history = useHistory();
  const quotes = useSelector((state) => state.quotationReducer.quotes);
  const loading = useSelector((state) => state.quotationReducer.loading);

  const [quoteDetails, setQuoteDetails] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [currentChannel, setCurrentChannel] = React.useState(``);
  const [openChat, setOpenChat] = useState(false);
  const [parsedQuotes, setParsedQuotes] = useState([]);
  useEffect(() => {
    dispatch(quoteActionsCreator.getQoutes());
    dispatch(cartActionsCreator.resetCart());
    return () => {
      dispatch(quoteActionsCreator.resetQuotes());
    };
  }, [dispatch]);

  useEffect(() => {
    if (quotes) {
      let result = dataService.parseQuotesTable(
        quotes,
        getStatus,
        getQuoteItems,
        getChat
      );
      setParsedQuotes(result);
    }
  }, [quotes]);

  const quoteStatusHandler = (tableId, quote, action) => {
    dispatch(
      quoteActionsCreator.closeQuote({
        status: action,
        notes: action,
        id: quote.id,
        tableId: tableId,
      })
    );
    setQuoteDetails(false);
  };

  const getQuoteDetail = (quote) => {
    quote.tableId = quote.tableData.id;
    history.push(
      `${routesPath.dashboard}${routesPath.quoteDetailPage(quote["#"])}`
    );
    // setSelectedQuote(quote);
    // if (quotes) {
    //   quotes.map((quote_map) => {
    //     if (quote_map.id === quote["#"]) {
    //       setSelectedQuote(quote_map);
    //       setQuoteDetails(true);

    //     }
    //   });
    // }
  };

  const quoteToOrder = (tableId, quoteId) => {
    dispatch(
      quoteActionsCreator.quoteToOrder({ quote_id: quoteId, tableId: tableId })
    );
    setQuoteDetails(false);
  };
  const toggle = () => setQuoteDetails(!quoteDetails);

  const routeToChat = (quoteId) => {
    history.push({
      quoteId,
      pathname: "/inbox",
    });
  };

  const handleChat = (id) => {
    setCurrentChannel(`Quote-${id}`);
    setOpenChat(true);
  };

  const getStatus = (quote) => {
    return (
      <Badge className="badge-dot mr-4 bg-dark">
        <span className="status">{quoteStatus[quote.status]?.label}</span>
      </Badge>
    );
  };

  const getQuoteItems = (quote) => {
    return quote.subquotes.map((subQuotes) => {
      return subQuotes.quote_items.map((quoteItem, index) => {
        return (
          <Badge color="badge-dot bg-dark m-1" key={index}>
            {quoteItem.product.data.name}
          </Badge>
        );
      });
    });
  };

  const getChat = (quote) => {
    return (
      <div
        className="quotes-action"
        onClick={(e) => {
          e.stopPropagation();
          handleChat(quote.id);
        }}
      >
        <a href="javascript:void(0);">Chat</a>
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="dashboard-head-wrap">
          <div className="dashboard-head-title">
            <h1>Quotes</h1>
          </div>
        </div>
        <div className="dashboard-content-wrap">
          <div className="main-content-inner">
            <Row>
              <Col className="ml-auto" md="12">
                {!selectedQuote ? (
                  <DataTable
                    data={parsedQuotes}
                    loading={loading}
                    clicked={getQuoteDetail}
                  />
                ) : (
                  quoteDetails && (
                    <QuoteDetail
                      quoteDetails={quoteDetails}
                      toggle={toggle}
                      products={selectedQuote}
                      quoteStatusHandler={quoteStatusHandler}
                      quoteToOrder={quoteToOrder}
                      routeToChat={routeToChat}
                      handleChat={handleChat}
                    />
                  )
                )}
              </Col>
            </Row>
            {openChat && currentChannel && (
              <InitializePubnub>
                <PubnubChat
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                  currentChannel={currentChannel}
                />
              </InitializePubnub>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
