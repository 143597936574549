import React from "react";
import quoteIcon from "../../assets/images/quote-icon.svg";
import connectedAppIcon from "../../assets/images/connected-apps-icon.svg";
import noCodeIcon from "../../assets/images/no-code-icon.svg";
import orderTrackingIcon from "../../assets/images/order-tracking-icon.svg";
import greenTick from "../../assets/images/green-tick.svg";
import hubSpot from "../../assets/images/hubspot-icon.svg";
import salesForce from "../../assets/images/salesforce-icon.svg";
import quickBooks from "../../assets/images/quickbooks-icon.svg";
import { Link } from "react-router-dom";
import storageService from "../../services/storageService";
import { routesPath } from "../../constants/routesPath";

export const AutomatedProcessing = () => {
  return (
    <div>
      <section className="automated-home-wrap light-bg-color article-padd">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="automated-home-head">
                <div className="automated-home-text">
                  <p className="sec-title">AUTOMATED PROCESSING</p>
                  <h2>Our Easy to use Platform </h2>
                </div>
                <div className="automated-home-btn">
                  <Link
                    to={
                      storageService.getJWTToken()
                        ? routesPath.marketPlace
                        : routesPath.login
                    }
                    className="green-btn"
                  >
                    Get Started for free
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-6">
                  <div className="automated-process-single shadded-bg">
                    <div className="automated-process-single-img">
                      <img
                        src={quoteIcon}
                        className="img-fluid"
                        alt="quote image"
                      />
                    </div>
                    <div className="automated-process-single-text">
                      <p>Cart to quote</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="automated-process-single shadded-bg">
                    <div className="automated-process-single-img">
                      <img
                        src={connectedAppIcon}
                        className="img-fluid"
                        alt="connected apps image"
                      />
                    </div>
                    <div className="automated-process-single-text">
                      <p>Connected Apps</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="automated-process-single shadded-bg">
                    <div className="automated-process-single-img">
                      <img
                        src={noCodeIcon}
                        className="img-fluid"
                        alt="easy to use platform no code image"
                      />
                    </div>
                    <div className="automated-process-single-text">
                      <p>No code</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="automated-process-single shadded-bg">
                    <div className="automated-process-single-img">
                      <img
                        src={orderTrackingIcon}
                        className="img-fluid"
                        alt="easy to use platform order tracking image"
                      />
                    </div>
                    <div className="automated-process-single-text">
                      <p>Order Tracking</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="automated-home-features-wrap">
                <div className="automated-home-feature-single shadded-bg">
                  <p>
                    <img
                      src={greenTick}
                      className="img-fluid"
                      alt="green tick mark"
                    />
                    Customer specific pricing
                  </p>
                </div>
              </div>
              <div className="automated-home-features-wrap">
                <div className="automated-home-feature-single shadded-bg">
                  <p>
                    <img
                      src={greenTick}
                      className="img-fluid"
                      alt="green tick mark"
                    />
                    Flexible payment terms
                  </p>
                </div>
              </div>
              <div className="automated-home-features-wrap">
                <div className="automated-home-feature-single shadded-bg">
                  <p>
                    <img
                      src={greenTick}
                      className="img-fluid"
                      alt="green tick mark"
                    />
                    Inventory tracking
                  </p>
                </div>
              </div>
              <div className="automated-home-features-wrap">
                <div className="automated-home-feature-single shadded-bg">
                  <p>
                    <img
                      src={greenTick}
                      className="img-fluid"
                      alt="green tick mark"
                    />
                    LTL Shipping
                  </p>
                </div>
              </div>
              <div className="automated-home-features-wrap">
                <div className="automated-home-feature-single shadded-bg">
                  <img src={hubSpot} className="img-fluid" alt="hubspot icon" />
                  <img
                    src={salesForce}
                    className="img-fluid"
                    alt="salesforce icon"
                  />
                  <img
                    src={quickBooks}
                    className="img-fluid"
                    alt="quickbooks icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
