import * as actions from "../actionTypes";

const initialState = {
  loading: false,
  response: null,
  error: null,
  applications: null,
  equipments: null,
  categories: null,
  subCategories: null,
  productType: "model",
  filters: {},
  selectedProductResponse: null,
  selectedProductError: null,
  buildSaveResponse: null,
  buildSaveError: null,
  loadBuildLoading: false,
  loadedResponse: [],
  postBuildLoading: false,
  loadedResponseError: null,
  drawerOpen: window.innerWidth <= 768 ? false : true,
  contactUsResponse: null,
  contactUsError: null,
  contactUsLoading: false,
  tenantSuccessStatus: null,
  tenantErrorStatus: null,
  tenantLoadingStatus: false,
};

/*
state:  {
  loading: false,
  response: null,
  error: null,
  applications: null,
  equipments: null,
  categories: null,
  subCategories: null,
  productType: "model",
  filters: {},
  selectedProductResponse: null,
  selectedProductError: null,
  buildSaveResponse: null,
  buildSaveError: null,
  loadBuildLoading: false,
  loadedResponse: [],
  postBuildLoading: false,
  loadedResponseError: null,
  drawerOpen: window.innerWidth <= 768 ? false : true,
  contactUsResponse: null,
  contactUsError: null,
  contactUsLoading: false,
  tenantSuccessStatus: null,
  tenantErrorStatus: null,
  tenantLoadingStatus: false,
};

action: {
  type:actionTye:string , payload:data}
}

*/
export const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CATEGORIES: {
      return {
        ...state,
        loading: true,
        categories: null,
        error: null,
      };
    }
    case actions.CATEGORIES_RESPONSE: {
      return {
        ...state,
        loading: false,
        categories: action.payload.data,
        error: null,
      };
    }
    case actions.CATEGORIES_ERROR: {
      return {
        ...state,
        loading: false,
        categories: null,
        error: action.payload.data,
      };
    }
    case actions.SUB_CATEGORIES: {
      return {
        ...state,
        loading: true,
        subCategories: null,
        error: null,
      };
    }
    case actions.SUB_CATEGORIES_RESPONSE: {
      return {
        ...state,
        loading: false,
        subCategories: action.payload.data,
        error: null,
      };
    }
    case actions.SUB_CATEGORIES_ERROR: {
      return {
        ...state,
        loading: false,
        subCategories: null,
        error: action.payload.data,
      };
    }

    case actions.PRODUCTS: {
      return {
        ...state,
        loading: true,
        response: null,
        error: null,
      };
    }
    case actions.PRODUCTS_RESPONSE: {
      return {
        ...state,
        loading: false,
        response: action.payload.data,
        error: null,
      };
    }
    case actions.PRODUCTS_ERROR: {
      return {
        ...state,
        loading: false,
        response: null,
        error: action.payload.data,
      };
    }
    case actions.MODELS_FILTERS: {
      return {
        ...state,
        loading: true,
        response: null,
        error: null,
      };
    }
    case actions.MODELS_FILTERS_RESPONSE: {
      return {
        ...state,
        loading: false,
        response: action.payload.data,
        error: null,
      };
    }
    case actions.MODELS_FILTERS_ERROR: {
      return {
        ...state,
        loading: false,
        response: null,
        error: action.payload.data,
      };
    }
    case actions.COMPONENTS_FILTERS: {
      return {
        ...state,
        loading: true,
        response: null,
        error: null,
      };
    }
    case actions.COMPONENTS_FILTERS_RESPONSE: {
      return {
        ...state,
        loading: false,
        response: action.payload.data,
        error: null,
      };
    }
    case actions.COMPONENTS_FILTERS_ERROR: {
      return {
        ...state,
        loading: false,
        response: null,
        error: action.payload.data,
      };
    }
    case actions.SINGLE_CATEGORY: {
      return {
        ...state,
        loading: true,
        response: null,
        error: null,
      };
    }
    case actions.SINGLE_CATEGORY_RESPONSE: {
      return {
        ...state,
        loading: false,
        response: action.payload.data,
        error: null,
      };
    }
    case actions.SINGLE_CATEGORY_ERROR: {
      return {
        ...state,
        loading: false,
        response: null,
        error: action.payload.data,
      };
    }
    case actions.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case actions.APPLICATIONS: {
      return {
        ...state,
        loading: true,
        applications: null,
        error: null,
      };
    }
    case actions.APPLICATIONS_RESPONSE: {
      return {
        ...state,
        loading: false,
        applications: action.payload.data,
        error: null,
      };
    }
    case actions.APPLICATIONS_ERROR: {
      return {
        ...state,
        loading: false,
        applications: null,
        error: action.payload.data,
      };
    }
    case actions.EQUIPMENTS: {
      return {
        ...state,
        loading: true,
        equipments: null,
        error: null,
      };
    }
    case actions.EQUIPMENTS_RESPONSE: {
      return {
        ...state,
        loading: false,
        equipments: action.payload.data,
        error: null,
      };
    }
    case actions.EQUIPMENTS_ERROR: {
      return {
        ...state,
        loading: false,
        equipments: null,
        error: action.payload.data,
      };
    }
    case actions.MODELS: {
      return {
        ...state,
        loading: true,
        response: null,
        error: null,
      };
    }
    case actions.MODELS_RESPONSE: {
      return {
        ...state,
        loading: false,
        response: action.payload.data,
        error: null,
      };
    }
    case actions.MODELS_ERROR: {
      return {
        ...state,
        loading: false,
        response: null,
        error: action.payload.data,
      };
    }
    case actions.COMPONENT: {
      return {
        ...state,
        loading: true,
        response: null,
        error: null,
      };
    }
    case actions.COMPONENT_RESPONSE: {
      return {
        ...state,
        loading: false,
        response: action.payload.data,
        error: null,
      };
    }
    case actions.COMPONENT_ERROR: {
      return {
        ...state,
        loading: false,
        response: null,
        error: action.payload.data,
      };
    }
    case actions.PRODUCT_TYPE: {
      return {
        ...state,
        productType: action.payload,
      };
    }

    case actions.FILTER: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case actions.SELECTED_PRODUCT:
      return {
        ...state,
        loading: true,
        selectedProductError: null,
        selectedProductResponse: null,
      };

    case actions.SELECTED_PRODUCT_RESPONSE:
      return {
        ...state,
        loading: false,
        selectedProductError: null,
        selectedProductResponse: action.payload,
      };

    case actions.SELECTED_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        selectedProductError: action.payload,
        selectedProductResponse: null,
      };

    case actions.FILTER_RESET: {
      return {
        ...state,
        filters: {},
        subCategories: null,
      };
    }
    case actions.SEARCH_PRODUCTS: {
      return {
        ...state,
        response: null,
        loading: true,
        error: null,
      };
    }
    case actions.SEARCH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        response: action.payload.data,
        loading: false,
        error: null,
      };
    }
    case actions.SEARCH_PRODUCTS_ERROR: {
      return {
        ...state,
        response: null,
        loading: false,
        error: action.payload,
      };
    }
    case actions.POST_USER_BUILD:
      return {
        ...state,
        postBuildLoading: true,
        buildSaveResponse: null,
        buildSaveError: null,
      };
    case actions.POST_USER_BUILD_SUCCESS:
      return {
        ...state,
        postBuildLoading: false,
        buildSaveError: null,
        buildSaveResponse: action.payload.data,
      };
    case actions.POST_USER_BUILD_FAIL:
      return {
        ...state,
        postBuildLoading: false,
        buildSaveResponse: null,
        buildSaveError: action.payload,
      };

    case actions.POST_BUILD_RESET:
      return {
        ...state,
        postBuildLoading: false,
        buildSaveResponse: null,
        buildSaveError: null,
      };
    case actions.GET_USER_BUILD:
      return {
        ...state,
        loadBuildLoading: true,
        loadedResponse: null,
      };
    case actions.GET_USER_BUILD_SUCCESS:
      return {
        ...state,
        loadBuildLoading: false,
        loadedResponse: action.payload.data,
      };
    case actions.GET_USER_BUILD_FAIL:
      return {
        ...state,
        loadBuildLoading: false,
        loadedResponse: action.payload.data,
      };
    case actions.UPDATE_USER_BUILD:
      return {
        ...state,
        postBuildLoading: true,
        buildSaveResponse: null,
      };
    case actions.UPDATE_USER_BUILD_SUCCESS:
      return {
        ...state,
        postBuildLoading: false,
        buildSaveResponse: action.payload.data,
      };
    case actions.UPDATE_USER_BUILD_FAIL:
      return {
        ...state,
        postBuildLoading: false,
        buildSaveResponse: action.payload.data,
      };
    case actions.DELETE_USER_BUILD:
      return {
        ...state,
        // loading: true,
        buildSaveResponse: null,
        loadedResponse: null,
        loadedResponseError: null,
      };
    case actions.DELETE_USER_BUILD_SUCCESS:
      let buildsList = state.loadedResponse.filter(
        (build) => build.id !== action.payload.id
      );
      return {
        ...state,
        loading: false,
        loadedResponse: buildsList,
        loadedResponseError: null,
      };
    case actions.DELETE_USER_BUILD_FAIL:
      return {
        ...state,
        loading: false,
        loadedResponseError: action.payload,
      };
    case actions.CONTACT_US:
      return {
        ...state,
        contactUsError: null,
        contactUsResponse: null,
        contactUsLoading: true,
      };
    case actions.CONTACT_US_SUCCESS:
      return {
        ...state,
        contactUsError: null,
        contactUsResponse: action.payload,
        contactUsLoading: false,
      };
    case actions.CONTACT_US_ERROR:
      return {
        ...state,
        contactUsError: action.payload,
        contactUsResponse: null,
        contactUsLoading: false,
      };

    case actions.DASHBOARD_DRAWER_HANDLE:
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };
    case actions.CLEAR_CONFIGURATOR:
      return {
        ...state,
        buildSaveResponse: null,
        buildSaveError: null,
        loadBuildLoading: false,
        loadedResponse: [],
        postBuildLoading: false,
        loadedResponseError: null,
      };
    case actions.RESET_CONTACT_US:
      return {
        ...state,
        contactUsResponse: null,
        contactUsError: null,
        contactUsLoading: false,
      };
    case actions.CHECK_STATUS:
      return {
        ...state,
        tenantErrorStatus: null,
        tenantSuccessStatus: null,

        tenantLoadingStatus: true,
      };
    case actions.CHECK_STATUS_SUCCESS:
      return {
        ...state,
        tenantErrorStatus: null,
        tenantSuccessStatus: action.payload?.status,
        tenantLoadingStatus: false,
      };
    case actions.CHECK_STATUS_ERROR:
      return {
        ...state,
        tenantErrorStatus: action.payload,
        tenantSuccessStatus: null,
        tenantLoadingStatus: false,
      };
    default:
      return state;
  }
};
