import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { stepperDefaultData } from "../../constants/stepper";
export const VerticleStepper = (props) => {
  return (
    <div>
      <Stepper
        activeStep={props.activeStep}
        style={{ borderRadius: "5px" }}
        className={
          window.innerWidth <= "769"
            ? "px-0 config-stepper ps-3"
            : "px-0 config-stepper"
        }
        orientation={window.innerWidth <= "769" ? "vertical" : "horizontal"}
      >
        {stepperDefaultData.map((label, index) => (
          <Step key={label}>
            <StepLabel onClick={() => props.updateStepper(index, label)}>
              {props.steps[label]?.name || label.replace("_", " ")}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
