import React from "react";
import { ContactForm } from "../../components/contactForm/contactForm";
import { Banner } from "../../components/ui/banner";

export const ContactUsContainer = () => {
  const bannerHead = "Contact Us";
  const bannerText =
    "Tell us everything about your project, we will be glad to help.";

  return (
    <div className="pt-5 mt-3">
      <Banner bannerHead={bannerHead} bannerText={bannerText} />
      <ContactForm />
    </div>
  );
};
