import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  //HOC for protection of private routes from unautheticated user

  /*
@params
{
<Component/>,
path,
state
}
  */
  const authed =
    useSelector((state) => state.authReducer.authed) ||
    Boolean(localStorage.getItem("token"));
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authed) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};
