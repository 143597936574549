import React from "react";
import { AboutCta } from "../../components/aboutCta/aboutCta";
import { Career } from "../../components/career/career";
import { Flexibility } from "../../components/flexibility/flexibility";
import { OurCulture } from "../../components/ourCulture/ourCulture";
import { RecentArticles } from "../../components/recentArticles/recentArticles";
import { Team } from "../../components/team/team";
import { Banner } from "../../components/ui/banner";

export const AboutContainer = () => {
  const bannerHead = "About Us";
  const bannerText =
    "Make industrial automation easier and more accessible for all manufacturers.";

  return (
    <div>
      <Banner bannerHead={bannerHead} bannerText={bannerText} />
      <Team />
      <RecentArticles />
      <OurCulture />
      <Career />
      <AboutCta />
    </div>
  );
};
