import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "../../redux/actionsCreator";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./filtersBar.scss";
import { Checkbox } from "@material-ui/core";

export const FiltersBar = (props) => {
  let dispatch = useDispatch();

  const applications = useSelector((state) => state.apiReducer.applications);
  const equipments = useSelector((state) => state.apiReducer.equipments);
  const categories = useSelector((state) => state.apiReducer.categories);
  const subCategories = useSelector((state) => state.apiReducer.subCategories);
  const productType = useSelector((state) => state.apiReducer.productType);
  const filters = useSelector((state) => state.apiReducer.filters);

  const filterHandler = (filter) => {
    let applyFilters = {};

    if (filter.type === "applicationId") {
      applyFilters = { ...filters };
      if (applyFilters["applicationId"]) {
        delete applyFilters["applicationId"];
      } else {
        applyFilters["applicationId"] = filter.id;
      }
    } else if (filter.type === "equipmentId") {
      applyFilters = { ...filters };
      if (applyFilters["equipmentId"]) {
        delete applyFilters["equipmentId"];
      } else {
        applyFilters["equipmentId"] = filter.id;
      }
    } else if (filter.type === "categoryId") {
      applyFilters = { ...filters };

      if (applyFilters["categoryId"]) {
        delete applyFilters["categoryId"];
      } else {
        applyFilters["categoryId"] = filter.id;
      }
    }

    dispatch(actionCreators.filtreHandler(applyFilters));
  };

  const subCategoryFilter = (id) => {
    const arr = filters.subCategoryIds || [];
    let resultArr = arr.filter((arrObj) => arrObj !== id);
    if (resultArr.length === arr.length) resultArr.push(id);
    let newfilters = { ...filters, subCategoryIds: [...resultArr] };
    dispatch(actionCreators.filtreHandler(newfilters));
  };

  const createQueryString = () => {
    const { applicationId, equipmentId, categoryId, subCategoryIds } = filters;
    var query = new URLSearchParams();
    if (applicationId) {
      query.append("application__id", applicationId);
    }
    if (equipmentId) {
      query.append("equipment__id", equipmentId);
    }
    if (categoryId) {
      query.append("component_subcategory__component_category__id", categoryId);
    }
    if (subCategoryIds) {
      query.append("component_subcategory__id__in", subCategoryIds.toString());
    }
    return query.toString();
  };

  const applyFilters = () => {
    const queryString = createQueryString();

    if (productType === "model") {
      dispatch(
        actionCreators.getModelsFilterProducts({ queryString: queryString })
      );
    } else {
      dispatch(
        actionCreators.getComponentsFilterProducts({
          queryString: queryString,
        })
      );
    }
    if (filters.categoryId) {
      dispatch(
        actionCreators.getSubCategories({
          queryString: `component_category__id=${filters.categoryId}`,
        })
      );
    }
  };

  const clearFilters = (productType) => {
    props.handleProductType(productType);
  };

  return (
    <>
      <div className="app-equip-accord">
        <Accordion className="pt-3" expanded>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <h2 className="accordion-heads">Product Type</h2>
          </AccordionSummary>
          <hr />

          <AccordionDetails className="px-0 pt-4">
            <div>
              <div className="mb-3">
                <label
                  style={{ fontSize: "16px" }}
                  htmlFor={`model`}
                  onClick={() => props.handleProductType("model")}
                >
                  <input
                    className="form-check-input"
                    name={`model`}
                    id={`model`}
                    type="checkbox"
                    checked={props.productType === "model"}
                  />
                  <span className="p-3 pb-1 pt-0 subhead-components">
                    Model
                  </span>
                </label>
              </div>
              <div className="mb-3">
                <label
                  style={{ fontSize: "16px" }}
                  htmlFor={`component`}
                  onClick={() => props.handleProductType("component")}
                >
                  <input
                    className="form-check-input"
                    name={`component`}
                    id={`component`}
                    type="checkbox"
                    checked={props.productType === "component"}
                  />
                  <span className="p-3 pb-1 pt-0 subhead-components">
                    Component
                  </span>
                </label>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion className="pt-3">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h2 className="accordion-heads">Applications</h2>
          </AccordionSummary>
          <hr />

          <AccordionDetails Details className="px-0 pt-4">
            <div>
              {applications &&
                applications.map((application) => (
                  <div className="mb-3" key={application.id}>
                    <label
                      style={{ fontSize: "16px" }}
                      htmlFor={`application${application.id}`}
                      onClick={() =>
                        filterHandler({
                          type: "applicationId",
                          id: application.id,
                        })
                      }
                    >
                      <input
                        className="form-check-input"
                        name={`application${application.id}`}
                        id={`application${application.id}`}
                        type="checkbox"
                        checked={
                          filters.applicationId &&
                          filters.applicationId === application.id
                        }
                      />
                      <span className="cutom-check"></span>
                      <span className="p-3 pb-1 pt-0 subhead-components">
                        {application.name[0].toUpperCase() +
                          application.name.substring(1)}
                      </span>
                    </label>
                  </div>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
        {productType === "model" && (
          <Accordion className="pt-3">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h2 className="accordion-heads">Equipment</h2>
            </AccordionSummary>
            <hr />
            <AccordionDetails className="px-0 pt-4">
              {productType === "model" ? (
                <div className="mb-3">
                  {equipments &&
                    equipments.map((equipment) => (
                      <div key={equipment.id}>
                        <label
                          className="px-0 pt-2"
                          style={{ fontSize: "16px" }}
                          htmlFor={`equipment${equipment.id}`}
                          onClick={() =>
                            filterHandler({
                              type: "equipmentId",
                              id: equipment.id,
                            })
                          }
                        >
                          <input
                            name={`equipment${equipment.id}`}
                            className="px-2 form-check-input"
                            id={`equipment${equipment.id}`}
                            type="checkbox"
                            checked={
                              filters.equipmentId &&
                              filters.equipmentId === equipment.id
                            }
                          />

                          <span className="p-3 pb-1 pt-0 subhead-components">
                            {equipment.name[0].toUpperCase() +
                              equipment.name.substring(1)}
                          </span>
                        </label>
                      </div>
                    ))}
                </div>
              ) : null}
            </AccordionDetails>
          </Accordion>
        )}

        {productType === "component" && (
          <Accordion className="pt-4">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <h2 className="accordion-heads"> Category</h2>
            </AccordionSummary>
            <hr />
            <AccordionDetails className="px-0 pt-4">
              {productType === "component" && !subCategories && categories ? (
                <div className="mb-3">
                  {categories &&
                    categories.map((category) => (
                      <div
                        className="custom-control custom-radio mb-3"
                        key={category.id}
                      >
                        <label
                          style={{ fontSize: "16px" }}
                          className="p-2"
                          htmlFor={`category${category.id}`}
                          onClick={() =>
                            filterHandler({
                              type: "categoryId",
                              id: category.id,
                            })
                          }
                        >
                          <input
                            name={`category${category.id}`}
                            className=" px-2 form-check-input"
                            id={`category${category.id}`}
                            type="checkbox"
                            checked={
                              filters.categoryId &&
                              filters.categoryId === category.id
                            }
                          />
                          <span className="p-3 pb-1 pt-0 subhead-components">
                            {category.name[0].toUpperCase() +
                              category.name.substring(1)}
                          </span>
                        </label>
                      </div>
                    ))}
                </div>
              ) : null}
              {subCategories ? (
                <div>
                  <div className="mb-2">
                    <label className="">
                      <input
                        type="checkbox"
                        checked={true}
                        className="px-2 form-check-input"
                      />
                      <span className="p-3 pb-1 pt-0 subhead-components">
                        {filters.categoryId &&
                          categories?.find((c) => c.id === filters.categoryId)
                            ?.name}
                      </span>
                    </label>
                  </div>

                  {subCategories.map((subCategory) => (
                    <div className="ml-3 mb-3" key={subCategory.id}>
                      <label
                        className="p-2"
                        htmlFor={`subcategory${subCategory.id}`}
                        onClick={() => {
                          subCategoryFilter(subCategory.id);
                        }}
                      >
                        <input
                          id={`subcategory${subCategory.id}`}
                          type="checkbox"
                          checked={
                            filters.subCategoryIds &&
                            filters.subCategoryIds.includes(subCategory.id)
                          }
                          className="form-check-input"
                        />
                        <span className="p-3 pb-1 pt-0 subhead-components">
                          {subCategory.name[0].toUpperCase() +
                            subCategory.name.substring(1)}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              ) : null}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <div className="mt-5 mb-5 ">
        <button
          onClick={() => applyFilters()}
          className="btn-green-bg d-inline"
        >
          Apply
        </button>

        <button onClick={() => clearFilters()} className="btn-red-bg d-inline ">
          Clear Filters
        </button>
      </div>
    </>
  );
};
