import React from "react";
import { useSelector } from "react-redux";
import jwt from "jwt-decode";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import quoteStatus from "../../constants/quoteStatus";
import { decryptedPrice } from "../../utils/decode";
import { Col, Divider, Row, Table } from "antd";
import { TailLoader } from "../ui/loader";
import storageService from "../../services/storageService";

export const CheckoutProducts = (props) => {
  const { path, url } = useRouteMatch();
  const quote = props.products;
  const token = storageService.getJWTToken();
  const userData = jwt(token);
  const loading = useSelector((state) => state.quotationReducer.loading);

  const getTableDataSource = () => {
    const quotes = [];
    quote.subquotes.map((subQuote, index) => {
      subQuote.quote_items.map((item, index) => {
        quotes.push({
          item: (
            <img
              src={item.product.data.images[0].image.replace(
                "image/upload/",
                ""
              )}
              style={{
                height: "70px",
                width: "60px",
              }}
            />
          ),
          name: `${item.product.data.name}`,
          sku: `${item.product.data.sku}`,
          vendor: `${item.product.data.vendor.business_name}`,
          price: ` $${decryptedPrice(item.price) * parseInt(item.quantity)}`,
          quantity: `${item.quantity}`,
        });
      });
    });
    return quotes;
  };

  return (
    <>
      <div className="dashboard-content-wrap">
        <div className="main-content-inner">
          <section className="bg-white">
            {quote ? (
              <>
                <div>
                  <Divider
                    className="px-2 justify-content-center"
                    style={{ fontWeight: "bold", fontSize: 36 }}
                  >
                    Order Information
                  </Divider>

                  <div className="row mx-auto">
                    <div className="col-12 col-lg-6">
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Id:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {quote.id}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Name:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {userData.username}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Email:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {userData.email}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Status:&nbsp;
                        <span
                          className={
                            quoteStatus[quote.status].label === "RECIEVED"
                              ? "badge bg-success"
                              : "badge bg-warning text-dark"
                          }
                          style={{ fontSize: "12px" }}
                        >
                          {quoteStatus[quote.status].label}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Notes:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {quote.notes || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 text-end pt-3 pt-lg-0">
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Created at:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {new Date(quote.created).toUTCString()}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Expires at:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {new Date(quote.expires_at).toUTCString()}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: "13px",
                          paddingBottom: "4px",
                          color: "#4f4e4e",
                        }}
                      >
                        Down payment:&nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {quote.down_payment}%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-3 row mx-auto">
                  <div>
                    <Table
                      dataSource={getTableDataSource()}
                      style={{
                        width: "100%",
                        maxHeight: "600px",
                        overflow: "scroll",
                      }}
                      pagination={false}
                    >
                      <Table.Column title="ITEM" dataIndex="item" />
                      <Table.Column title="NAME" dataIndex="name" />
                      <Table.Column title="SKU" dataIndex="sku" />
                      <Table.Column title="VENDOR" dataIndex="vendor" />
                      <Table.Column title="QUANTITY" dataIndex="quantity" />
                      <Table.Column title="PRICE" dataIndex="price" />
                    </Table>
                  </div>
                </div>
              </>
            ) : (
              loading && (
                <div className="mt-5 text-center">
                  <TailLoader height={100} width={100} />
                </div>
              )
            )}
          </section>
        </div>
      </div>
    </>
  );
};
