import React from "react";
import rightArrow from "../../assets/images/right-arrow.svg";
import dataAnalysisImage from "../../assets/images/data-analysis-img-1.svg";
import dataAnalysisImage2 from "../../assets/images/data-analysis-img-2.svg";
import { Link } from "react-router-dom";
import { routesPath } from "../../constants/routesPath";
import Fade from "react-reveal/Fade";

export const DataAnalyticsSection = () => {
  return (
    <div className="data-analysis-bg">
      <section className="data-analysis-wrap article-padd">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="data-analysis-text-wrap">
                <div className="data-analysis-text">
                  <h2>Actionable data analytics and deep learning</h2>
                  <p>
                    A platform that unlocks the power of connected products by
                    assigning unique digital IDs to everyday items, providing
                    unparalleled end-to-end transparency.
                  </p>
                  <p>
                    By tracking, storing and managing all the events associated
                    with each individual product — from source to consumer and
                    beyond to enable circularity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Fade right>
                <div>
                  <div className="data-analysis-img-wrap animate__animated animate__fadeInRight wow">
                    <img
                      src={dataAnalysisImage}
                      className="img-fluid"
                      alt="data analysis image"
                    />
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-6">
              <Fade left>
                <div>
                  <div className="data-analysis-img-wrap  animate__animated animate__fadeInLeft wow">
                    <img
                      src={dataAnalysisImage2}
                      className="img-fluid"
                      alt="no-code e-commerce image"
                    />
                  </div>
                </div>
              </Fade>
            </div>
            <div className="col-lg-6">
              <div className="data-analysis-text-wrap">
                <div className="data-analysis-text">
                  <h2>No-Code eCommerce for vendors</h2>
                  <p>
                    Selling custom products online using an e-commerce store has
                    never been easier, faster, or more scalable.
                  </p>
                  <p>
                    We can bring your products to life with immersive 3D content
                    that can be configured and viewed in real life.
                  </p>
                  <div className="external-link">
                    <Link to={routesPath.ecommerce}>
                      Learn more{" "}
                      <img src={rightArrow} alt="external link arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
