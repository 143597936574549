import React from "react";
import careerImage from "../../assets/images/careers-img.png";

export const Career = () => {
  return (
    <div>
      <section className="careers-outer-wrap" id="careers">
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-6">
              <div className="careers-img-wrap">
                <img
                  src={careerImage}
                  className="img-fluid"
                  alt="careers section image"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="careers-text-wrap">
                <div className="careers-text">
                  <h2>Careers</h2>
                  <p>
                    We’re a tightly knit team of driven, mission-focused people.
                    We’re growing rapidly while still nurturing our culture by
                    carefully adding passionate and empathetic people.
                  </p>
                  <p>
                    We have lots of open positions. That said, we hire people,
                    not roles. Together we will carve out the right fit that
                    gives you maximum joy and sets you up for success, while
                    making sure it’s what we need to keep taking strides in
                    creating a better web. For all of us.
                  </p>
                  <p>Can you find yourself in the above? Join us.</p>
                  <div className="careers-btn">
                    <a href="" className="green-btn">
                      Check open positions
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
