import * as Yup from "yup";

const validationSchema = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  username: Yup.string().required("Invalid username"),
  email: Yup.string().required("required"),
});

export default validationSchema;
